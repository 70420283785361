import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Alert, Typography } from "@mui/material";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";

import CatCreateModal from "./../catUtills/CatCreateModal";
import CatsTable from "./../../components/Table/CatsTable/CatsTable";
import axiosInstance from "./../../api/axios/axiosInstance";

const MachineCats = ({ machineId }) => {
  const [newCatModalIsOpen, setNewCatModalIsOpen] = useState(false);
  const [newData, setNewData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateTable, setIsUpdateTable] = useState(1);

  const handleUpdateTable = () => {
    setIsUpdateTable((prev) => prev + 1);
  };

  useEffect(() => {
    axiosInstance
      .post(
        "/machine-cats",
        { machine_id: machineId },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [machineId, newCatModalIsOpen, isUpdateTable]);

  const machineSerialNumber = newData?.sn;
  const catsDataTransformedForTable =
    newData?.map((cat) => ({
      ...cat,
    })) ?? [];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" fontWeight="bold">Cats</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setNewCatModalIsOpen(true)}
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "50px",
            padding: "5px 15px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            "&:hover": {
              backgroundColor: "#333",
            },
            "& .MuiButton-startIcon": {
              marginRight: "5px",
            },
          }}
        >
          Add Cat
        </Button>
      </Box>
      <Alert severity="info" sx={{ mb: 2 }}>
        This table displays all cats that are associated with the machine's household.
      </Alert>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <CatsTable
          data={catsDataTransformedForTable}
          loading={isLoading}
          pagination={false}
          data-testid={"machineSingleView.catsTable"}
          updateParentState={handleUpdateTable}
          isupdateTable={isUpdateTable}
        />
      )}
      {newCatModalIsOpen && (
        <CatCreateModal
          isModalOpen={newCatModalIsOpen}
          machineSerialNumber={machineSerialNumber}
          closeModal={() => setNewCatModalIsOpen(false)}
          queryVariables={{
            machineId,
          }}
        />
      )}
    </>
  );
};

export default MachineCats;
