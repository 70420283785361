import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Button,
  Grid
} from "@mui/material";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import { NumberParam, useQueryParams, withDefault } from "use-query-params"
import { useSearchParams } from "../../../utils/useSearchParams";
import CatEventTable from "../../Table/CatEventsTable/CatEventsTable";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import moment from "moment-timezone";
import { useMassUnits } from "../../../utils/context/mass";
import { useMassFormatter } from "../../../utils/context/mass";

const CatEvents = () => {
  const { catId } = useParams();
  const [newData, setNewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const { massFormatter } = useMassFormatter()
  const massUnits = useMassUnits()

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let toDate = new Date();
  let fromDate = moment(new Date(new Date().setDate(toDate.getDate() - 30))).format('YYYY-MM-DD');
  toDate = moment(new Date()).format('YYYY-MM-DD');


  // const [dateRange] = useCatSingleViewDateRange()
  // console.log("dateRangedateRange", dateRange)

const [searchParams, setSearchParams] = useSearchParams({
  page: 1,
  pageSize: 50,
});



  useEffect(() => {
    fetchData(catId, fromDate, toDate);
    
  }, [setNewData, searchParams]);

  const fetchData = async (catId, fromDate, toDate) => {
    setIsLoading(true)
    axiosInstance
            .post(
                "/cat-events?page="+searchParams.get('page'),
                {   cat_id: catId,
                    page: searchParams.get('page'),
                    perPage: searchParams.get('pageSize'),
                    from: fromDate,
                    to: toDate
                  }, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
            .then((response, error) => {
                if (error) {
                  setError(error)
                    if (newData === null) {
                        throw new Error("No data object")
                    }
                }
                setIsLoading(false)
                setNewData(response.data)
            })
  }

  const eventPagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
        // totalCount
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
    
  }

  const dateFormat = "YYYY-MM-DD"
  const timeFormat = "HH:mm:ss"
  const format = `${dateFormat} ${timeFormat}`
  // TOTO: Remove Z addition after explicitly UTC times are provided on the API
  // const date = moment(event.startTime + "Z").format(format)

  const dataTransformedForTable = newData?.data.map((data) => {
    return {
      ...data,
        // startTime: data.startTime,
        startTime: moment(data.startTime + "Z").format(format),
        weight: massFormatter(data.weight) + massUnits,
        monitor_sn: data.machine.sn,
        session_id: data.session_id,
    }
  }) ?? []

  const [value, setValue] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    if (date!= null && date.length>0) {
      
      const start = moment(date[0]).format('YYYY-MM-DD');
      setStartDate(start);
        const end = moment(date[1]).format('YYYY-MM-DD');
      setEndDate(end);
      setValue(date)
    }
    else{
      setValue(new Date());
      setStartDate(null);
      setEndDate(null);

    }
  };

  const refreshEventList = (page=1) => {
    fetchData(catId, startDate, endDate)
    setIsLoading(true);
  };

  // const tableData = responseMappedForTable(newData);

  return (
    <Box sx={{ padding: 2 }}>
     
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs>
        <DateRangePicker 
        // value={value}
            onChange={handleStartDateChange} 
            defaultValue={[new Date(fromDate), new Date(toDate)]}/>
          <Button
            key="filter"
            onClick={() => refreshEventList()}
          >
            Filter
          </Button>
        <Grid container>
            <CatEventTable
              data={dataTransformedForTable}
              loading={false}
              columnsFn={null}
              pagination={eventPagination}
              // updateParentState={updateTable}
              // isupdateTable={isupdateTable}
            />
          </Grid>
          </Grid>
      )}
      
    </Box>
  );
};

export default CatEvents;
