import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios/axiosInstance";
import DogCollarMachinesTable from "../../Table/DogCollarMachinesTable/DogCollarMachinesTable";

const DogCollarMachines = ({ fetchData }) => {
    const { householdId } = useParams();
    const [newData, setNewData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdateTable, setIsUpdateTable] = useState(2);

    const url = `/get-dog-collar-household?household_id=` + householdId;

    const updateTable = (newState) => {
        setIsUpdateTable(newState);
    };

    useEffect(() => {
        
        setNewData(fetchData.data)
        setIsLoading(false);
    }, [setNewData, isUpdateTable]);

    if (newData === null) {
        throw new Error("No data object");
    }

    const disableAllSortAndFiltering = (columns) => {
        return columns.map((col) => {
            col.sorter = undefined;
            col.defaultSortOrder = undefined;
            col.filters = undefined;
            col.filterMode = undefined;
            col.defaultFilteredValue = undefined;
            return col;
        });
    };

    return (
        <DogCollarMachinesTable
            data={newData ?? []}
            loading={isLoading}
            pagination={false}
            columnsFn={disableAllSortAndFiltering}
            updateParentState={updateTable}
            isupdateTable={isUpdateTable}
        />
    );
};

export default DogCollarMachines;
