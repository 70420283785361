import React from "react"
import { useLocation, useNavigate  } from "react-router-dom"


export function useSearchParams(defaultInit) {
    let defaultSearchParamsRef = React.useRef(createSearchParams(defaultInit))

    let location = useLocation()
    let searchParams = React.useMemo(() => {
        let searchParams = createSearchParams(location.search)

        for (let key of defaultSearchParamsRef.current.keys()) {
            if (!searchParams.has(key)) {
                defaultSearchParamsRef.current.getAll(key).forEach((value) => {
                    searchParams.append(key, value)
                })
            }
        }

        return searchParams
    }, [location.search])

    let navigate = useNavigate()
    let setSearchParams = React.useCallback(
        (nextInit, navigateOptions) => {
            navigate("?" + createSearchParams(nextInit), navigateOptions)
        },
        [navigate],
    )

    return [searchParams, setSearchParams]
}


export function createSearchParams(init = "") {
    return new URLSearchParams(
        typeof init === "string" ||
        Array.isArray(init) ||
        init instanceof URLSearchParams
            ? init
            : Object.keys(init).reduce((memo, key) => {
                  let value = init[key]
                  return memo.concat(
                      Array.isArray(value)
                          ? value.map((v) => [key, v])
                          : [[key, value]],
                  )
              }, []),
    )
}