import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import MachinesGroupTable from "../../Table/MachineGroupTable/MachinesGroupTable";
import { Box, CircularProgress } from "@mui/material";

const HouseholdMachinesGroup = ({ fetchData }) => {
    const { householdId } = useParams();
    const [newData, setNewData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdateTable, setIsUpdateTable] = useState(2);

    const url = `/household-machines-groups?household_id=` + householdId;

    const updateTable = (newState) => {
        setIsUpdateTable(newState);
    };

    useEffect(() => {
        axiosInstance.get(url)
            .then((response) => {
                setIsLoading(false);
                setNewData(response.data.data);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [fetchData, isUpdateTable]);

    if (newData === null) {
        throw new Error("No data object");
    }

    const disableAllSortAndFiltering = (columns) => {
        return columns.map((col) => {
            col.sorter = undefined;
            col.defaultSortOrder = undefined;
            col.filters = undefined;
            col.filterMode = undefined;
            col.defaultFilteredValue = undefined;
            return col;
        });
    };

    return (
        <Box sx={{ padding: 2 }}>
        {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
        ):(
        <MachinesGroupTable
            data={newData ?? []}
            loading={isLoading}
            pagination={false}
            columnsFn={disableAllSortAndFiltering}
            updateParentState={updateTable}
            isupdateTable={isUpdateTable}
        />
        )}
        </Box>
    );
};

export default HouseholdMachinesGroup;
