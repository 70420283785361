import React from "react";
import { Box, Divider, Typography, Link } from "@mui/material";

const PageContentBlock = ({
    title = false,
    disableDivider = false,
    children,
}) => {
    const titleText = title && typeof title === "string" ? title : title.text;

    return (
        <Box>
            {!disableDivider && <Divider />}
            {title && (
                <Link href={`#${titleText}`} underline="none">
                    <Typography variant="h3" id={titleText} gutterBottom>
                        {title?.icon}
                        {titleText}
                    </Typography>
                </Link>
            )}
            {children}
        </Box>
    );
};

export default PageContentBlock;
