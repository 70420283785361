import React from "react"
import moment from "moment-timezone"


export const timezones = [
    ...new Set([
        ...moment.tz.zonesForCountry("US"),
        "UTC",
        "Europe/London",
        "Europe/Amsterdam",
        moment.tz.guess(),
    ]),
]

export const Timezone = React.createContext(undefined)
export const TimezoneDispatch = React.createContext()

function timezoneReducer(_a, action) {
    if (timezones.includes(action)) {
        return action
    } else {
        throw new Error(`Unhandled action type: ${action}`)
    }
}

export const useTimezone = () => {
    const timezone = React.useContext(Timezone)

    // const timezone = "Asia/Calcutta"
    if (timezone === undefined) {
        throw new Error("useTimezone must be used within a TimeProvider")
    }else{
        moment.tz.setDefault(timezone)
    }

    // React.useEffect(() => {
    //     console.log("useEffect",timezone);

    //     moment.tz.setDefault(timezone)
    // }, [timezone])

    return timezone
}

export const useTimezoneDispatch = () => {
    const context = React.useContext(TimezoneDispatch)
    if (context === undefined) {
        throw new Error(
            "useTimezoneDispatch must be used within a TimeProvider",
        )
    }
    return context
}

export const TimeFormat = React.createContext()
export const TimeFormatDispatch = React.createContext()
function timeFormatReducer(_, action) {
    if (typeof action !== "string") {
        throw new Error(`Unhandled action type: ${action}`)
    } else {
        // This is where we could add predefined time formats
        return action
    }
}

export const useTimeFormat = () => {
    const context = React.useContext(TimeFormat)
    if (context === undefined) {
        throw new Error("useTimeFormat must be used within a TimeProvider")
    }
    return context
}

export const useTimeFormatDispatch = () => {
    const context = React.useContext(TimeFormatDispatch)
    if (context === undefined) {
        throw new Error(
            "useTimeFormatDispatch must be used within a TimeProvider",
        )
    }
    return context
}

export const Time = ({ children, debug }) => {
    const format = useTimeFormat()
    const timezone = useTimezone()

    // regression check for #83
    if ([null, undefined, ""].includes(children)) {
        // moment(null) => 'Invalid date', moment(undefined) => "now" datetime
        // we want more explicit string 'undefined','null'
        return `${children}`
    }

    return (
        <span data-children={children}>
            {`${debug ? "VAL: " : ""}${formatTime(children, timezone, format)}`}

            {debug ? (
                <>
                    <br />
                    {`UTC: ${timeToUTC(children).format(format)}`}
                    <br />
                    {`RAW: ${children}`}
                </>
            ) : null}
        </span>
    )
}
const timeToUTC = (time) => moment.utc(time)

const formatTime = (time, timezone, format) =>
    format === "ROAR"
        ? time
        : timeToUTC(time)
              .tz(timezone)
              .format(format)
export const TimeProvider = ({ children }) => {
    const [timezone, dispatchTimezone] = React.useReducer(
        timezoneReducer,
        moment.tz.guess(),
    )

    const [format, dispatchFormat] = React.useReducer(
        timeFormatReducer,
        "YYYY-MM-DD HH:mm:ss",
    )

    return (
        <Timezone.Provider value={timezone}>
            <TimezoneDispatch.Provider value={dispatchTimezone}>
                <TimeFormat.Provider value={format}>
                    <TimeFormatDispatch.Provider value={dispatchFormat}>
                        {children}
                    </TimeFormatDispatch.Provider>
                </TimeFormat.Provider>
            </TimezoneDispatch.Provider>
        </Timezone.Provider>
    )
}
