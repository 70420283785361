import React, { useEffect } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Slider,
    TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
import axiosInstance from "../../api/axios/axiosInstance";

const CatCreateModal = ({
    machineSerialNumber,
    isModalOpen,
    closeModal,
    queryVariables: { machineId } = {
        machineId: "",
    },
    existingCat,
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isAlteredAtBirthShown, setIsAlteredAtBirthShown] = React.useState(false);

    const marks = [{
        value: "0",
        label: 'Unknown'
      },
      {
        value: '1',
        label: '1'
      },
      {
        value: '2',
        label: '2'
      },
      {
        value: '3',
        label: '3'
      },
      {
        value: '4',
        label: '4'
      },
      {
        value: '5',
        label: '5'
      },
      {
        value: '6',
        label: '6'
      },
      {
        value: '7',
        label: '7'
      },
      {
        value: '8',
        label: '8'
      },
      {
        value: '9',
        label: '9'
      }]

    const [form, setForm] = React.useState({
        dob: null,
    });

    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            name: "",
            gender: undefined,
            bodyConditionScore: undefined,
            dob: null,
            reproductiveStatus: undefined,
            reproductiveStatusAlteredAtBirth: undefined,
        }
    });

    const addCatAPI = (data) => {
        axiosInstance.post('/add-cat', data)
            .then((response) => {
                setIsLoading(false);
                if (response.data.message === 'Success') {
                    closeModal();
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const updateCatAPI = (data) => {
        axiosInstance.post('/update-cat', data)
            .then((response) => {
                setIsLoading(false);
                if (response.data.message === 'Success') {
                    closeModal();
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        
        if (!isModalOpen) {
            reset();
            setIsAlteredAtBirthShown(false);
        } else if (existingCat) {
            const {
                name,
                gender,
                bodyConditionScore,
                dob,
                reproductiveStatus,
                reproductiveStatusAlteredAtBirth
            } = existingCat;

            setForm({
                dob: dob ? moment(dob).format('YYYY-MM-DD') : null,
            });

            setValue("name", name);
            setValue("gender", gender);
            setValue("bodyConditionScore", bodyConditionScore || 0);
            setValue("dob", dob ? moment(dob).format('MM/DD/YYYY') : null);
            setValue("reproductiveStatus", reproductiveStatus);
            setValue("reproductiveStatusAlteredAtBirth", reproductiveStatusAlteredAtBirth);

            if (["spayed", "neutered"].includes(reproductiveStatus)) {
                setIsAlteredAtBirthShown(true);
            }
        }
    }, [isModalOpen, existingCat, setValue, reset]);

    const handleFormChange = (field) => (event) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: event.target.value,
        }));
    };

    const onReproductiveStatusChange = (event) => {
        const value = event.target.value;
        if (["spayed", "neutered"].includes(value)) {
            setIsAlteredAtBirthShown(true);
        } else {
            setIsAlteredAtBirthShown(false);
        }
    };

    const onSubmit = async (values) => {
        setIsLoading(true);
        setIsSaving(true);
        try {
            const data = {
                ...values,
                birth_date: form.dob ? moment(form.dob).format('YYYY-MM-DD') : null,
                bodyConditionScore: values.bodyConditionScore?.toString() || '0',
                reproductiveStatusAlteredAtBirth: values.reproductiveStatusAlteredAtBirth?.toString() || '0',
                // reproductiveStatus: values.reproductiveStatus === "" ? null : values.reproductiveStatus : null,
                reproductiveStatus: values.reproductiveStatus === "" || values.reproductiveStatus === undefined ? null : values.reproductiveStatus,
                active_ind: existingCat ? !existingCat.inactiveAt : true,
            };
            

            if (existingCat) {
                data.household_id = existingCat.household_id || existingCat.id;
                data.cat_id = existingCat.cat_id || existingCat.id;
                updateCatAPI(data);
            } else {
                data.device_id = machineId;
                addCatAPI(data);
            }
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        } finally {
            setIsSaving(false);
        }
    };


    return (
        <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>
                {existingCat
                    ? `Edit ${existingCat.name}`
                    : `Add New Cat${machineSerialNumber ? ` to ${machineSerialNumber}` : ""}`}
            </DialogTitle>
            <DialogContent>
                {isLoading && <CircularProgress />}
                {!isLoading && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: "Please name this cat" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />
                        <Controller
                            name="gender"
                            control={control}
                            render={({ field }) => (
                                <FormControl component="fieldset" margin="normal">
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value={undefined} control={<Radio />} label="Unknown" />
                                        <FormControlLabel value="m" control={<Radio />} label="Male" />
                                        <FormControlLabel value="f" control={<Radio />} label="Female" />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="bodyConditionScore"
                            control={control}
                            render={({ field }) => (
                                <FormControl component="fieldset" margin="normal">
                                    <FormLabel component="legend">Body Condition Score</FormLabel>
                                    <Slider
                                        {...field}
                                        min={0}
                                        max={9}
                                        marks={marks}
                                        
                                    />
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="dob"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="DOB"
                                    type={"date"}
                                    format="YYYY-MM-DD"
                                    fullWidth
                                    margin="normal"
                                    value={form.dob}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleFormChange("dob")}
                                />
                            )}
                        />
                        <Controller
                            name="reproductiveStatus"
                            control={control}
                            render={({ field }) => (
                                <FormControl component="fieldset" margin="normal">
                                    <FormLabel component="legend">Reproductive Status</FormLabel>
                                    <RadioGroup {...field} row onChange={onReproductiveStatusChange}>
                                        <FormControlLabel value={null} control={<Radio />} label="Unknown" />
                                        <FormControlLabel value="intact" control={<Radio />} label="Intact" />
                                        <FormControlLabel value="spayed" control={<Radio />} label="Spayed" />
                                        <FormControlLabel value="neutered" control={<Radio />} label="Neutered" />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                        {isAlteredAtBirthShown && (
                            <Controller
                                name="reproductiveStatusAlteredAtBirth"
                                control={control}
                                render={({ field }) => (
                                    <FormControl component="fieldset" margin="normal">
                                        <FormLabel component="legend">When was reproductive status altered?</FormLabel>
                                        <RadioGroup {...field}>
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label="Altered as a kitten"
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label="Altered as an adult"
                                            />
                                            <FormControlLabel
                                                value={undefined}
                                                control={<Radio />}
                                                label="Unknown when altered"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                        )}
                        <DialogActions>
                            <Button onClick={closeModal} color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSaving}
                                // data-testid={tids.addCat.saveButton}
                            >
                                {existingCat ? "Save cat" : "Add cat"}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CatCreateModal;
