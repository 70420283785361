export const getCatWeightChartSpec = ({
    weightsPEDT = [],
    dateRange,
    massUnit = "lb",
    pedtRulePriorityAndDescPairs,
}) => {
    
    const dateDomain = dateRange
        ? [
              dateRange.fromDate.format("YYYY-MM-DD"),
              dateRange.toDate
                  .clone()
                  .add(1, "day")
                  .format("YYYY-MM-DD"),
          ]
        : null
    const lengthOfDateRangeInDays = dateRange.toDate.diff(
        dateRange.fromDate,
        "days",
    )
    const tickCountInterval = lengthOfDateRangeInDays > 60 ? "week" : "day"

    const aggregatedWeightsLine = {
        mark: {
            type: "line",
            color: "#000",
            strokeWidth: 1,
            point: {
                color: "black",
                filled: true,
                size: 30,
            },
            clip: true,
        },
        data: {
            name: "weights",
        },
        encoding: {
            x: {
                field: "date",
                timeUnit: "utcyearmonthdate",
                axis: {
                    title: "Date",
                    titleFontSize: 16,
                    labelExpr:
                        "[timeFormat(datum.value, '%e %b'), timeFormat(datum.value, '%e%m') == 101 ? timeFormat(datum.value, '%Y') : '', ]", // not great to only show year on 1st jan, but better than nothing
                    tickCount: { interval: tickCountInterval, step: 1 }, // change to month dateRange has length: days > 60 (2 months)
                    labelFlush: false,
                    labelFontSize: 12,
                },
            },
            y: {
                field: "mean",
                type: "quantitative",
                scale: { zero: false, padding: 1 },
                axis: {
                    title: `Weight (${massUnit})`,
                    titleFontSize: 16,
                },
            },
            tooltip: [
                {
                    field: "date",
                    type: "temporal",
                    title: "Date",
                },
                {
                    field: "max",
                    title: "Max Weight",
                },
                {
                    field: "mean",
                    title: "Mean Weight",
                },
                {
                    field: "min",
                    title: "Min Weight",
                },
                {
                    field: "unfilteredCounts.total",
                    title: "Events on this day",
                },
            ],
        },
    }
    if (dateDomain) {
        aggregatedWeightsLine.encoding.x.scale = { domain: dateDomain }
    }

    const getPEDTDomain = () =>
        pedtRulePriorityAndDescPairs.map((pair) => pair[0]) // all possible priority numbers

    const getPEDTLegendLabelExpr = () =>
        JSON.stringify(Object.fromEntries(pedtRulePriorityAndDescPairs)) +
        `[datum.label]`

    const getUniquePriorities = () => {
        const uniquePrioritiesFromData = [
            ...new Set(weightsPEDT.map((p) => p.priority)),
        ].sort()
        const intersectionWithDomain = getPEDTDomain().filter((p) =>
            uniquePrioritiesFromData.includes(p),
        ) // do this to maintain the nice sorting from the pedtRules query result
        return intersectionWithDomain
    }

    const pedtResultsLines = {
        mark: {
            type: "rule",
            strokeWidth: 4,
            clip: true,
        },
        data: {
            name: "weightsPEDT",
        },
        encoding: {
            x: {
                field: "startDate",
                timeUnit: "utcyearmonthdate",
                type: "temporal",
            },
            x2: {
                field: "endDate",
                timeUnit: "utcyearmonthdate",
                type: "temporal",
            },
            y: {
                field: "startWeight",
                type: "quantitative",
                scale: { zero: false },
            },
            y2: {
                field: "endWeight",
                type: "quantitative",
                scale: { zero: false },
            },
            tooltip: [
                {
                    field: "description",
                    title: "Description",
                },
                {
                    field: "startWeight",
                    title: "Start Weight",
                },
                {
                    field: "endWeight",
                    title: "End Weight",
                },
                {
                    field: "percentageChange",
                    title: "% Change",
                },
                {
                    field: "startDate",
                    title: "Start Date",
                    type: "temporal",
                },
                {
                    field: "endDate",
                    title: "End Date",
                    type: "temporal",
                },
                {
                    field: "period",
                    title: "Period",
                },
                {
                    field: "priority",
                    title: "Priority",
                },
            ],
            color: {
                field: "priority",
                scale: {
                    scheme: "rainbow",
                    domain: getPEDTDomain(),
                },
                legend: {
                    title: [
                        "PEDT Rules",
                        // "(sorted by direction, then priority)",
                    ],
                    titleFontSize: 14,
                    titleLimit: 240,
                    labelFontSize: 14,
                    labelLimit: 200,
                    symbolStrokeWidth: 3,
                    labelExpr: getPEDTLegendLabelExpr(),
                    values: getUniquePriorities(),
                    sort: { field: "priority", order: "ascending" },
                },
            },
        },
    }

    return { aggregatedWeightsLine, pedtResultsLines }
}
