import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Typography,
  Paper,
  TablePagination,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TrainedStateIcon from "../../../utils/TrainedStateIcon";
import { findChunksAtEndOfString } from "../../../utils/findChunksAtEndOfString";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const NoData = styled("div")({
  opacity: 0.4,
  fontStyle: "italic",
});

const Ul = styled("ul")({
  margin: 0,
  paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
  padding: 0,
});

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  backgroundColor: "#f5f5f5",
});

const FullWidthPaper = styled(Paper)({
  width: "100%",
  overflowX: "auto",
});

const FullWidthTableContainer = styled(TableContainer)({
  width: "100%",
});

const CustomPagination = styled(TablePagination)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  borderTop: "1px solid #e0e0e0",
  backgroundColor: "#fafafa",
  "& .MuiTablePagination-toolbar": {
    width: "100%",
    justifyContent: "space-between",
  },
  "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
    margin: 0,
    fontSize: "0.875rem",
    color: "#000",
  },
  "& .MuiTablePagination-selectRoot": {
    margin: "0 10px",
  },
  "& .MuiTablePagination-actions": {
    display: "flex",
    alignItems: "center",
  },
});

const SORT_FIELDS = {
  onboardingCompletedAt: "ONBOARDING_COMPLETED_AT",
};

const SORT_DIRS = {
  ascend: "asc",
  descend: "desc",
};

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
};

const HouseholdsTable = ({
  data = [],
  loading = false,
  columnsFn = (val) => val,
  pagination,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getDirectionFromSortString = (sortString) => {
    const sortSuffixesRegex = sortString.match(
      /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
    );
    if (sortSuffixesRegex.groups?.ascend) return "asc";
    if (sortSuffixesRegex.groups?.descend) return "desc";
    return null;
  };

  const getDefaultSortOrderForColumn = (colIndex) => {
    const appliedSort = searchParams.get("sort");
    if (!appliedSort) return null;

    const columnSortField = SORT_FIELDS[colIndex];
    if (!appliedSort.includes(columnSortField)) return null;

    const direction = getDirectionFromSortString(appliedSort);
    if (!direction) return null;

    return direction;
  };

  const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
    const getTrainedStateFilters = () => {
      return searchParams.getAll("trainedState");
    };
    const getOnboardedAtFilters = () => {
      const isHouseholdOnboardedFilters = searchParams
        .getAll("isHouseholdOnboarded")
        .map((filter) => "isHouseholdOnboarded=" + filter);

      return isHouseholdOnboardedFilters;
    };

    return {
      trainedState: getTrainedStateFilters(),
      onboardingCompletedAt: getOnboardedAtFilters(),
    };
  };
  const defaultFilteredValuesByColumn =
    translateSearchParamsToDefaultFilteredValues(searchParams);
  const getDefaultFilteredValuesForColumn = (colIndex) =>
    defaultFilteredValuesByColumn[colIndex];

  const forwardTableChangeToSearchParams = (pagination, filters, sorter) => {
    const getSort = (sorter) => {
      if (!(sorter.field && sorter.order)) {
        return undefined;
      }
      return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
    };
    const getTrainedState = (filters) => {
      const trainedStates = filters;
      return trainedStates;
    };
    const getIsHouseholdOnboarded = (filters) => {
      if (filters.includes("isHouseholdOnboarded=false")) return false;
      return undefined;
    };
    const getPage = () => {
      if (Number(searchParams.get("page")) !== pagination.current) {
        return pagination.current;
      }
      return 1;
    };

    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: getPage(),
      pageSize: pagination.pageSize,
      sort: getSort(sorter),
      trainedState: getTrainedState(filters.trainedState ?? []),
      isHouseholdOnboarded: getIsHouseholdOnboarded(
        filters.onboardingCompletedAt ?? []
      ),
    };
    newSearchParams = removeUndefinedValues(newSearchParams);
    function removeUndefinedValues(object) {
      const objectClone = { ...object };
      Object.entries(objectClone).forEach(([key, value]) => {
        if (value === undefined) delete objectClone[key];
      });
      return objectClone;
    }

    setSearchParams(newSearchParams);
  };

  let columns = [
    {
      title: "Monitors",
      dataIndex: "machines",
      key: "machines",
      render: function renderMachinesList(machines) {
        const activeMachines =
          machines?.filter(
            (machine) =>
              machine.isFrozen !== true && machine.isOrphaned !== true
          ) ?? [];
        if (!activeMachines || activeMachines?.length === 0)
          return <NoData>No active machines</NoData>;

        return (
          <Ul>
            {activeMachines
              ? activeMachines.map((machine) => {
                  const renderMachineName = (machine) => {
                    if (machine.name === undefined) return null;
                    if (machine.name === machine.sn) return null;
                    return ` (${machine.name})`;
                  };
                  return (
                    <li key={machine.id}>
                      <Highlighter
                        searchWords={[searchParams.get("snSuffix")]}
                        textToHighlight={machine?.sn ?? ""}
                        findChunks={findChunksAtEndOfString}
                        highlightStyle={{backgroundColor: "#ffe58f"}}
                      />
                      {renderMachineName(machine)}
                    </li>
                  );
                })
              : "-"}
          </Ul>
        );
      },
      width: 400,
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: function renderUsersList(users) {
        if (!users || users?.length === 0) return <NoData>No users</NoData>;
        return (
          <Ul>
            {users.map((user) => {
              if (user.givenName && user.familyName) {
                return (
                  <li key={user.id}>
                    {user.givenName + " " + user.familyName}
                  </li>
                );
              }
              return <li key={user.id}>{user.name ?? "-"}</li>;
            })}
          </Ul>
        );
      },
      width: 300,
    },
    {
      title: "Pets",
      dataIndex: "cats",
      key: "cats",
      render: function renderCatsList(cats) {
        if (!cats || cats?.length === 0) return <NoData>No pets</NoData>;
        return (
          <Ul>
            {cats.map((cat) => (
              <li key={cat.id}>{cat.name ?? "-"}</li>
            ))}
          </Ul>
        );
      },
      width: 300,
    },
    {
      title: "Trained State",
      dataIndex: "trainedState",
      width: 180,
      render: function renderHouseholdTrainedState(trainedState, record) {
        return (
          <Tooltip
            title={`${record.trainedCats} / ${
              record?.activeCats ?? 0
            } pets trained`}
          >
            <Box>
              <span>{`${record.trainedCats} / ${
                record?.activeCats ?? 0
              }`}</span>
              <TrainedStateIcon trainedState={trainedState} disableTooltip />
            </Box>
          </Tooltip>
        );
      },
      filters: [
        {
          text: "Trained",
          value: "trained",
        },
        {
          text: "Training",
          value: "training",
        },
        {
          text: "Untrained",
          value: "untrained",
        },
        {
          text: "Retraining",
          value: "retraining",
        },
      ],
      defaultFilteredValue: getDefaultFilteredValuesForColumn("trainedState"),
    },
    {
      title: "Onboarding",
      dataIndex: "onboardingCompletedAt",
      key: "onboardingCompletedAt",
      render: function renderOnboardingCompletedAt(onboardingCompletedAt) {
        return onboardingCompletedAt ? (
          <span>{formatDate(onboardingCompletedAt)}</span>
        ) : (
          "-"
        );
      },
      sorter: true,
      defaultSortOrder: getDefaultSortOrderForColumn("onboardingCompletedAt"),
      filters: [
        {
          text: "Never",
          value: "isHouseholdOnboarded=false",
        },
      ],
      defaultFilteredValue: getDefaultFilteredValuesForColumn(
        "onboardingCompletedAt"
      ),
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 150,
      render: function renderActionButton(id) {
        return (
          <ActionIconButton component={Link} to={`/cat-litter-box/households/${id}`}>
            <VisibilityIcon />
          </ActionIconButton>
        );
      },
    },
  ];

  if (columnsFn) {
    columns = columnsFn(columns);
  }

  const handleChangePage = (event, newPage) => {
    forwardTableChangeToSearchParams(
      { current: newPage + 1, pageSize: pagination.pageSize },
      {},
      {}
    );
  };

  const handleChangeRowsPerPage = (event) => {
    forwardTableChangeToSearchParams(
      { current: 1, pageSize: parseInt(event.target.value, 10) },
      {},
      {}
    );
  };

  return (
    <FullWidthPaper>
      <FullWidthTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.key}>
                  {column.sorter ? (
                    <TableSortLabel
                      active={column.key === "onboardingCompletedAt"}
                      direction={
                        getDefaultSortOrderForColumn(column.key) || "asc"
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  ) : (
                    column.title
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.key}>
                    {column.render
                      ? column.render(row[column.dataIndex], row)
                      : row[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
      </FullWidthTableContainer>
      {pagination !== false && (
        <CustomPagination
          component="div"
          count={data.length}
          rowsPerPage={pagination.pageSize}
          page={pagination.current - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
          rowsPerPageOptions={[10, 25, 50]}
          labelRowsPerPage="View"
          labelDisplayedRows={({ from, to, count }) => (
            <Box display="flex" alignItems="center">
              <Box>{`Found ${pagination.total} Records`}</Box>
              <Box display="flex" alignItems="center" mx={2}>
                <WatchLaterIcon fontSize="small" />
                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
              </Box>
            </Box>
          )}
          SelectProps={{
            inputProps: { "aria-label": "Rows per page" },
            native: true,
          }}
          ActionsComponent={(subProps) => (
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={(event) =>
                  subProps.onPageChange(event, subProps.page - 1)
                }
                disabled={subProps.page === 0}
                aria-label="previous page"
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                onClick={(event) =>
                  subProps.onPageChange(event, subProps.page + 1)
                }
                disabled={
                  subProps.page >=
                  Math.ceil(pagination.total / subProps.rowsPerPage) - 1
                }
                aria-label="next page"
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          )}
        />
      )}
    </FullWidthPaper>
  );
};

export default HouseholdsTable;
