import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { CircularProgress, Checkbox, FormControlLabel, Typography, Box, useTheme } from "@mui/material";
import {
    Brush,
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import axiosInstance from "./../../api/axios/axiosInstance";
import { useTimezone } from "./../../utils/context/Timezone";

const defaultTareValues = [0, 0, 0, 0];

const createLoadCellData = (
    data,
    startTime,
    sampleRate,
    timezone,
    tare = defaultTareValues,
) => {
    const time = moment(startTime + "Z").tz(timezone);
    return data.map((datum, i) => {
        const timestamp = time
            .clone()
            .add(i / sampleRate, "seconds")
            .format("HH:mm:ss");
        const d0 = datum[0] - tare[0];
        const d1 = datum[1] - tare[1];
        const d2 = datum[2] - tare[2];
        const d3 = datum[3] - tare[3];
        return {
            time: time.valueOf(),
            a: d0,
            b: d1,
            c: d2,
            d: d3,
            total: d0 + d1 + d2 + d3,
            timestamp,
        };
    });
};

const DogCollarLoadCellChart = ({ eventId, startTime, apiUrl, source }) => {
    const theme = useTheme();
    const timezone = useTimezone();
    const [applyTareWeight, setApplyTareWeight] = useState(true);
    const [gyroApplyTareWeight, setgyroApplyTareWeight] = useState(true);
    const [newData, setNewData] = useState(null);
    const [loadCellData, setLoadCellData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const sDate = startTime ? moment(startTime).format("YYYY-MM-DD HH:mm:ss") : null;

    const url = `/${apiUrl}?page=1&event_id=${eventId}&source=${source}`;

    useEffect(() => {
        axiosInstance.get(url)
            .then((response) => {
                setNewData(response.data.data);
                setLoadCellData(response.data.data.loadCellData);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [eventId]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (!loadCellData || !loadCellData?.accelero_data) {
        return (
            <Typography variant="body2" color="textSecondary">
                No load cell data available.
            </Typography>
        );
    }

    return (
        <div>
            <Box sx={{ height: 400 }}>

                <ResponsiveContainer>
                    <LineChart
                        margin={{ left: 10, right: 10 }}
                        data={createLoadCellData(
                            loadCellData.accelero_data,
                            loadCellData.startTime,
                            loadCellData.sampleRate,
                            timezone,
                            applyTareWeight && loadCellData.accelero_tare
                                ? loadCellData.accelero_tare
                                : defaultTareValues,
                            "accelero"
                        )}
                    >
                        <XAxis dataKey="timestamp" />
                        <YAxis yAxisId="left">
                            <Label
                                angle={270}
                                position="left"
                                style={{ textAnchor: "middle" }}
                            >
                                Accelerometer
                            </Label>
                        </YAxis>
                        <YAxis dataKey="total" yAxisId="right" orientation="right">
                            <Label
                                angle={270}
                                position="right"
                                style={{ textAnchor: "middle" }}
                            >
                                Total
                            </Label>
                        </YAxis>
                        <CartesianGrid stroke={theme.palette.divider} />
                        <Tooltip />
                        <Brush />
                        <Line
                            type="monotone"
                            key="a"
                            dataKey="a"
                            stroke={theme.palette.primary.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            key="b"
                            dataKey="b"
                            stroke={theme.palette.secondary.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            key="c"
                            dataKey="c"
                            stroke={theme.palette.warning.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            key="d"
                            dataKey="d"
                            stroke={theme.palette.success.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />

                    </LineChart>
                </ResponsiveContainer>
            </Box>

            <Box sx={{ height: 400, marginTop: '20px' }}>

                <ResponsiveContainer>
                    <LineChart
                        margin={{ left: 10, right: 10 }}
                        data={createLoadCellData(
                            loadCellData.gyro_data,
                            loadCellData.startTime,
                            loadCellData.sampleRate,
                            timezone,
                            gyroApplyTareWeight && loadCellData.gyro_tare
                                ? loadCellData.gyro_tare
                                : defaultTareValues,
                            "gyro"
                        )}
                    >
                        <XAxis dataKey="timestamp" />
                        <YAxis yAxisId="left">
                            <Label
                                angle={270}
                                position="left"
                                style={{ textAnchor: "middle" }}
                            >
                                Gyroscope
                            </Label>
                        </YAxis>
                        <YAxis dataKey="total" yAxisId="right" orientation="right">
                            <Label
                                angle={270}
                                position="right"
                                style={{ textAnchor: "middle" }}
                            >
                                Total
                            </Label>
                        </YAxis>
                        <CartesianGrid stroke={theme.palette.divider} />
                        <Tooltip />
                        <Brush />
                        <Line
                            type="monotone"
                            key="a"
                            dataKey="a"
                            stroke={theme.palette.primary.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            key="b"
                            dataKey="b"
                            stroke={theme.palette.secondary.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            key="c"
                            dataKey="c"
                            stroke={theme.palette.warning.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            key="d"
                            dataKey="d"
                            stroke={theme.palette.success.main}
                            strokeWidth={1}
                            yAxisId="left"
                            dot={false}
                        />

                    </LineChart>
                </ResponsiveContainer>
            </Box>
           
        </div>
    );
};

export default DogCollarLoadCellChart;
