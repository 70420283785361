import React from "react";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import axiosInstance from "../../api/axios/axiosInstance";

import LocationCreateModal from "./LocationCreateModal";

const EditLocationButton = ({ machine, updateParentState, isupdateTable, facilitypanelData, ptcCats }) => {
    const [isUpdateCatModalOpen, setIsUpdateCatModalOpen] = React.useState(false);

    const deleteLocation = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('device_id', machine.device_id);
        axiosInstance.post("/delete-locations", bodyFormData).then((response, error) => {
            if (response.data.message == 'success') {
                // setisLoading(false);
                updateParentState(isupdateTable + 1)
            }
        })
    }

    return (
        <>
            <Button
                onClick={() => setIsUpdateCatModalOpen(true)}
                startIcon={<EditOutlined />}
            >
            </Button>

            <Button
                onClick={() => deleteLocation()}
                startIcon={<DeleteIcon />}
            >
            </Button>

            <LocationCreateModal
                isModalOpen={isUpdateCatModalOpen}
                closeModal={() => {
                    setIsUpdateCatModalOpen(false);
                    updateParentState(isupdateTable + 1);
                }}
                machine={machine}
                facilitypanelData={facilitypanelData}
                ptcCats={ptcCats}
            />
        </>
    );
};

export default EditLocationButton;
