import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useParams, useNavigate } from "react-router-dom";

import moment from "moment-timezone";
import axiosInstance from "./../../../api/axios/axiosInstance";
import MachineAttributes from "./MachineAttributes";
import MachineCats from "./../../../utils/machineUtills/MachineCats";
import MachineDeployments from "./MachineDeployments";
// import MachineEvents from "#comps/MachineEvents";
import MachineEventTotals from "./MachineEventTotals";
import SettingsTable from "./../../../utils/common/SettingsTable";
import { useTimezone } from "./../../../utils/context/Timezone";
import MachineEvents from "../CatLitterBox/Monitors/MachineEvents";

const Machine = () => {
  const timezone = useTimezone();
  const { machineId } = useParams();
  const [newData, setNewData] = useState(null);
  const [reloading, setReloading] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .post(
        "/machine-attribute-details",
        { machine_id: machineId },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setNewData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [machineId]);

  const machineSerialNumber = newData?.data.sn;
  const machineName = newData?.data.name;
  const isFrozen = newData?.data.isFrozen;
  const frozenAt = newData?.data.mostRecentUploadAt;

  const formatDateBySelectedTimezone = (date) =>
    moment(date + "Z")
      .tz(timezone)
      .format("YYYY/MM/DD - HH:mm:ss");

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <ArrowBackIcon sx={{ marginRight: 1 }} />
        <Typography
          component={Link}
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          sx={{
            color: "black",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Go Back
        </Typography>
      </Box>

      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          Event Totals
        </Typography>
        <MachineEventTotals />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Attributes
          </Typography>
          <MachineAttributes setReloading={setReloading} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Events
          </Typography>
          <MachineEvents />
        </Grid>

        <Grid item xs={12}>
          <MachineCats machineId={machineId} />
        </Grid>

        <Grid item xs={12}>
          <MachineDeployments />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Settings
          </Typography>

          <SettingsTable
            machineId={machineId}
            testid={"machineSingleView.settingsTable"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Machine;
