import React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import PageContentBlock from "./../../utils/PageContentBlock"; // Make sure to import your custom PageContentBlock component
// import EventLabelModal from "./EventLabelModal"; // Make sure to import your custom EventLabelModal component
import DogCollarLabelsAndClassifications from "./DogCollarLabelsAndClassifications";

const DogCollarNodeLabelsAndClassifications = ({
  id,
  newData,
  refetch,
}) => {
  const [edit, setEdit] = React.useState(false);
  return (
    <PageContentBlock>
      {/* {edit && (
        <EventLabelModal
          event={id}
          eventId={id}
          machineId={machine_id}
          visible={edit}
          closeCallback={() => {
            setEdit(false);
            refetch();
          }}
        />
      )} */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button variant="contained" color="primary" onClick={() => setEdit(true)}>
          <div >
            Edit labels
          </div>
        </Button>
      </Box> */}
      
          <DogCollarLabelsAndClassifications
            eventId={id ?? {}}
            newData={newData ?? {}}
          />
        
    </PageContentBlock>
  );
};
export default DogCollarNodeLabelsAndClassifications;
