import React, { useState } from 'react';
import { Button, Popover, Select, MenuItem } from '@mui/material';
import { useTimezone, useTimezoneDispatch, useTimeFormat, useTimeFormatDispatch, timezones } from '../../utils/context/Timezone';
import moment from 'moment-timezone';

const timeToUTC = (time) => moment.utc(time)

const formatTime = (time, timezone, format) =>
    format === "ROAR"
        ? time
        : timeToUTC(time)
              .tz(timezone)
              .format(format)

export const Time = ({ children, debug }) => {
    const format = useTimeFormat()
    const timezone = useTimezone()

    // regression check for #83
    if ([null, undefined, ""].includes(children)) {
        // moment(null) => 'Invalid date', moment(undefined) => "now" datetime
        // we want more explicit string 'undefined','null'
        return `${children}`
    }

    return (
        <span data-children={children}>
            {`${debug ? "VAL: " : ""}${formatTime(children, timezone, format)}`}

            {debug ? (
                <>
                    <br />
                    {`UTC: ${timeToUTC(children).format(format)}`}
                    <br />
                    {`RAW: ${children}`}
                </>
            ) : null}
        </span>
    )
}
// TimezonePicker Component
const TimezonePicker = () => {
    const timezone = useTimezone();
    const setTimezone = useTimezoneDispatch();

    const handleChange = (event) => {
        setTimezone(event.target.value);
    };

    return (
        <Select
            value={timezone}
            onChange={handleChange}
            displayEmpty
            style={{ width: 200 }}
        >
            {timezones.map((z) => (
                <MenuItem key={z} value={z}>
                    {z}
                </MenuItem>
            ))}
        </Select>
    );
};

// TimeFormatPicker Component
const TimeFormatPicker = () => {
    const format = useTimeFormat();
    const setFormat = useTimeFormatDispatch();

    const displayFormats = [
        { label: 'Without timezone', format: 'YYYY-MM-DD HH:mm:ss' },
        { label: 'With Timezone', format: 'YYYY-MM-DDTHH:mm:ss Z z' },
        { label: 'Raw', format: 'ROAR' },
    ];

    const handleChange = (event) => {
        setFormat(event.target.value);
    };

    return (
        <Select
            value={format}
            onChange={handleChange}
            displayEmpty
            style={{ width: 200 }}
        >
            {displayFormats.map((df, i) => (
                <MenuItem key={i} value={df.format}>
                    {df.label}
                </MenuItem>
            ))}
        </Select>
    );
};

// TimezoneControls Component
const TimezoneControls = () => {
    const timezone = useTimezone();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                aria-describedby={id}
                onClick={handleClick}
                style={{
                    marginLeft: '10px',
                    marginRight: '5px',
                    height: '25px',
                    backgroundColor: 'white',
                    color: 'black',
                    border: '1px solid #ccc',
                    transition: 'background-color 0.3s ease'
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'white'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
            >
                {timezone}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClick}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: '16px' }}>
                    <TimezonePicker />
                    <br />
                    <TimeFormatPicker />
                </div>
            </Popover>
        </>
    );
};

export default TimezoneControls;
