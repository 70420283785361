import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid
} from "@mui/material";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import { useSearchParams } from "../../../utils/useSearchParams";
import CatPedtResultsTable from "../../Table/CatPedtResultsTable/CatPedtResultsTable";
import { useMassUnits } from "../../../utils/context/mass";
import { useMassFormatter } from "../../../utils/context/mass";
import moment from "moment-timezone";
import { useTimezone } from "../../../utils/context/Timezone";

const CatPedtResults = () => {
  const { catId } = useParams();
  const [newData, setNewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const { massFormatter } = useMassFormatter()
  const massUnits = useMassUnits()

  const [searchParams, setSearchParams] = useSearchParams({
    pedtResultsTablePage: 1,
    pedtResultsTablePageSize: 50,
    sort: "END_DATE_DESC",
  });

  const timezone = useTimezone()

  // const [dateRange] = useCatSingleViewDateRange()
  const [dateRange, setdateRange] = React.useState({
    fromDate: moment()
      .tz(timezone)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .subtract(29, "days"), toDate: moment()
        .tz(timezone)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
  });
  const toBoolean = (boolString) => {
    if (boolString === "true") return true
    if (boolString === "false") return false
    return undefined
  }

  const query = {
    cat_id: catId,
    page: searchParams.get("pedtResultsTablePage") ?? 1,
    perPage:
      searchParams.get("pedtResultsTablePageSize") ??
      50,
    sort: searchParams.get("sort") ?? "END_DATE_DESC",
    // from: "2024-05-28",
    // to: "2024-06-26",
    from: dateRange.fromDate.format("YYYY-MM-DD"),
    to: dateRange.toDate.format("YYYY-MM-DD"),
    onlyHasPushMessage:
      !toBoolean(searchParams.get("showAllPEDTResults")) ?? null,
    seenByUser: toBoolean(searchParams.get("seenByUser")) ?? null,
  };



  useEffect(() => {

    axiosInstance.post('/cat-pedt-results', query, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((response) => {
      if (error) {
        setError(error)
        return;
      }
      setIsLoading(false);
      setNewData(response.data);
    }).catch((error) => {
      setIsLoading(false);
      // useErrorHandler(error?.message)
    });
  }, [setNewData, searchParams]);

  const eventPagination = {
    current: searchParams.get("pedtResultsTablePage")
      ? Number(searchParams.get("pedtResultsTablePage"))
      : 0,
    pageSize: searchParams.get("pedtResultsTablePageSize")
      ? Number(searchParams.get("pedtResultsTablePageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
        // totalCount
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dateFormat = "YYYY-MM-DD"
  const timeFormat = "HH:mm:ss"
  const format = `${dateFormat} ${timeFormat}`

  const dataTransformedForTable =
    newData?.data?.map((e) => ({
      ...e,
      id: e.cat.id,
      pedtId: e?.id,
      name: e.cat.name,
      generatedAt: moment(e.generatedAt + "Z").format(format),
      startWeight: massFormatter(e.metadata.startWeight) + massUnits,
      endWeight: massFormatter(e.metadata.endWeight) + massUnits,
      percentageChange: e.metadata.percentageChange,
      hasPushMessage: e.pushMessageTotal?.totalCount > 0,
    })) ?? []


  // const tableData = responseMappedForTable(newData);
  return (
    <Box sx={{ padding: 2 }}>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <CatPedtResultsTable
            data={dataTransformedForTable}
            loading={false}
            columnsFn={null}
            pagination={eventPagination}
          // updateParentState={updateTable}
          // isupdateTable={isupdateTable}
          />
        </Grid>
      )}
    </Box>
  );
};

export default CatPedtResults;
