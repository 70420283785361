import React, { useEffect, useState } from "react";
import { useField } from "formik";
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { styled, useTheme } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Autocomplete from "@mui/material/Autocomplete";
import axiosInstance from "./../api/axios/axiosInstance";
import EventTag from "./EventTag";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const useFieldStyles = styled((theme) => ({
  fieldWrapper: { display: "flex", flexDirection: "column" },
  title: {
    marginTop: "32px",
    marginBottom: "8px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  error: {
    color: theme.palette.error.main,
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    fontSize: "12px",
    marginRight: "4px",
  },
}));

export const DateTimePickerField = ({ title, ...props }) => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, { touched, error }, { setValue }] = useField(props);
  const [value, setValueState] = useState(dayjs(field.value));

  const handleChange = (newValue) => {
    console.log("new date value", newValue)
    setValue(newValue);
    setValueState(newValue);
  };

  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        {...field}
        value={value}
        format="YYYY-MM-DD HH:mm:ss"
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={touched && Boolean(error)}
            helperText={touched && error}
            fullWidth
          />
        )}
      />
      </LocalizationProvider>
    </Box>
  );
};

export const TimePickerField = ({ title, ...props }) => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, { touched, error }, { setValue, setTouched }] = useField({
    ...props,
  });

  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <TextField
        {...field}
        {...props}
        type="time"
        step={1}
        error={touched && Boolean(error)}
        helperText={touched && error}
        onChange={(event) => {
          setValue(event.target.value);
          setTouched(true);
        }}
        fullWidth
      />
    </Box>
  );
};

export const Error = ({ ...props }) => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [, { touched, error }] = useField({ ...props });

  return touched && error ? (
    <Typography className={classes.error} >
      <span className={classes.errorIcon}>!</span>
      {error}
    </Typography>
  ) : null;
};

export const RadioField = ({ label, value, ...props }) => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, , { setValue, setTouched }] = useField({ ...props });

  const onChange = (event) => {
    setValue(event.target.value);
    setTouched(true);
  };

  return (
    <FormControlLabel
      control={<Radio {...field} value={value} checked={value === field.value} onChange={onChange} />}
      label={label}
    />
  );
};

export const WhichCatField = ({ catOptions }) => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>Which cat?</Typography>
      
        {catOptions.map((cat) => (
          <RadioField key={cat.id} value={cat.id} label={cat.name} name="catChoice" />
        ))}
        <RadioField name="catChoice" value="unknown" label="Unknown cat" />
        <RadioField name="catChoice" value="notACat" label="Not a cat" />
      
      <Error name="catChoice" />
    </Box>
  );
};

export const FalseTriggerField = () => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);

  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>Is this a false event?</Typography>
      {/* <RadioGroup> */}
        <RadioField name="falseTrigger" value="true" label="Yes" />
        <RadioField name="falseTrigger" value="false" label="No" />
      {/* </RadioGroup> */}
      <Error name="falseTrigger" />
    </Box>
  );
};

export const NotaCatField = () => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);

  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>Is this a Cat?</Typography>
      {/* <RadioGroup> */}
        <RadioField name="notCat" value="true" label="Yes" />
        <RadioField name="notCat" value="false" label="No" />
      {/* </RadioGroup> */}
      <Error name="notCat" />
    </Box>
  );
}; 

export const NotaDogField = () => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);

  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>Is this a Dog?</Typography>
      {/* <RadioGroup> */}
        <RadioField name="notDog" value="true" label="Yes" />
        <RadioField name="notDog" value="false" label="No" />
      {/* </RadioGroup> */}
      <Error name="notCat" />
    </Box>
  );
};

export const TagsField = () => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, { value }, { setValue, setTouched }] = useField({
    name: "tags",
  });
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axiosInstance.get("/event-tags");
        setTags(response.data.existingEventTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTags();
  }, []);

  const createValues = (tag) => ({ name: tag, id: tag });

  const onAddition = (event, newValue) => {
    setValue(newValue.map((val) => val.name));
    setTouched(true);
  };

  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>Labels</Typography>
      {isLoading ? (
        <Typography className={classes.labelHelpText}>Loading tags...</Typography>
      ) : (
        <Autocomplete
          multiple
          options={tags.filter((tag) => !value.includes(tag)).map(createValues)}
          getOptionLabel={(option) => option.name}
          value={value.map(createValues)}
          onChange={onAddition}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <EventTag text={option.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Add new label"
              fullWidth
            />
          )}
        />
      )}
      <Typography className={classes.labelHelpText}>
        Separate new labels with commas or the Enter key
      </Typography>
    </Box>
  );
}


  export const CatFeederTagsField = () => {
    const theme = useTheme();
    const classes = useFieldStyles(theme);
    const [field, { value }, { setValue, setTouched }] = useField({
      name: "tags",
    });
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      const fetchTags = async () => {
        try {
          const response = await axiosInstance.get("/cat-feeder-event-tags");
          setTags(response.data.existingEventTags);
        } catch (error) {
          console.error("Error fetching tags:", error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchTags();
    }, []);

  const createValues = (tag) => ({ name: tag, id: tag });

  const onAddition = (event, newValue) => {
    
    setValue(newValue.map((val) => val.name));
    setTouched(true);
  };

  return (
    <Box className={classes.fieldWrapper}>
      <Typography className={classes.title}>Labels</Typography>
      {isLoading ? (
        <Typography className={classes.labelHelpText}>Loading tags...</Typography>
      ) : (
        <Autocomplete
          multiple
          options={tags.filter((tag) => !value.includes(tag)).map(createValues)}
          getOptionLabel={(option) => option.name}
          value={value.map(createValues)}
          onChange={onAddition}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <EventTag text={option.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Add new label"
              fullWidth
            />
          )}
        />
      )}
      <Typography className={classes.labelHelpText}>
        Separate new labels with commas or the Enter key
      </Typography>
    </Box>
  );
};


export const CatFeederConsumptionField = () => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, { value }, { setValue, setTouched }] = useField({
    name: "consumptiontags",
  });
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axiosInstance.get("/cat-feeder-consumtion-tags");
        setTags(response.data.existingConsumptionTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTags();
  }, []);

const createValues = (tag) => ({ name: tag, id: tag });

const onAddition = (event, newValue) => {
  setValue(newValue.map((val) => val.name));
  setTouched(true);
};

return (
  <Box className={classes.fieldWrapper}>
    <Typography className={classes.title}>Consumption</Typography>
    {isLoading ? (
      <Typography className={classes.labelHelpText}>Loading tags...</Typography>
    ) : (
      <Autocomplete
        multiple
        options={tags.filter((tag) => !value.includes(tag)).map(createValues)}
        getOptionLabel={(option) => option.name}
        value={value.map(createValues)}
        onChange={onAddition}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <EventTag text={option.name} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Add new label"
            fullWidth
          />
        )}
      />
    )}
    <Typography className={classes.labelHelpText}>
      Separate new labels with commas or the Enter key
    </Typography>
  </Box>
);
};


export const DogBowlTagsField = () => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, { value }, { setValue, setTouched }] = useField({
    name: "tags",
  });
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axiosInstance.get("/dog-bowl-event-tags");
        setTags(response.data.existingEventTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTags();
  }, []);

const createValues = (tag) => ({ name: tag, id: tag });

const onAddition = (event, newValue) => {
  setValue(newValue.map((val) => val.name));
  setTouched(true);
};

return (
  <Box className={classes.fieldWrapper}>
    <Typography className={classes.title}>Labels</Typography>
    {isLoading ? (
      <Typography className={classes.labelHelpText}>Loading tags...</Typography>
    ) : (
      <Autocomplete
        multiple
        options={tags.filter((tag) => !value.includes(tag)).map(createValues)}
        getOptionLabel={(option) => option.name}
        value={value.map(createValues)}
        onChange={onAddition}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <EventTag text={option.name} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Add new label"
            fullWidth
          />
        )}
      />
    )}
    <Typography className={classes.labelHelpText}>
      Separate new labels with commas or the Enter key
    </Typography>
  </Box>
);
};

export const TextareaField = ({ name, title }) => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, { touched, error }, { setValue, setTouched }] = useField({
    name: name,
  });

  return (
    <Box className={classes.fieldWrapper}>
      <Typography variant="subtitle1">{title}</Typography>
      <TextField
        {...field}
        id={name}
        variant="outlined"
        multiline
        fullWidth
        rows={4}
        error={touched && Boolean(error)}
        helperText={touched && error}
        onChange={(event) => {
          setValue(event.target.value);
          setTouched(true);
        }}
      />
    </Box>
  );
};

export const InputTextField = ({ name, title }) => {
  const theme = useTheme();
  const classes = useFieldStyles(theme);
  const [field, { touched, error }, { setValue, setTouched }] = useField({
    name: name,
  });

  return (
    <Box className={classes.fieldWrapper}>
      <Typography variant="subtitle1">{title}</Typography>
      <TextField
        {...field}
        id={name}
        variant="outlined"
        multiline
        fullWidth
        rows={1}
        error={touched && Boolean(error)}
        helperText={touched && error}
        onChange={(event) => {
          setValue(event.target.value);
          setTouched(true);
        }}
        style={{width: '217px'}}
      />
    </Box>
  );
};
