
import React from "react";
import { Breadcrumbs, Typography, Link as MuiLink, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Page = ({
  title = false,
  subtitle = false,
  breadcrumbs = [],
  children,
}) => {
  const titleText = title && typeof title === "string" ? title : title.text;
  const subtitleText =
    subtitle && typeof subtitle === "string" ? subtitle : subtitle.text;

  return (
    <Box>
      <Box mb={2}>
        {/* {breadcrumbs.length > 0 && (
          <Breadcrumbs aria-label="breadcrumb">
            <MuiLink component={RouterLink} to="/">
              Home
            </MuiLink>
            {breadcrumbs.map(({ title, link = null }) => (
              <MuiLink
                key={title}
                component={RouterLink}
                to={link || "#"}
                color={link ? "inherit" : "textPrimary"}
              >
                {title}
              </MuiLink>
            ))}
          </Breadcrumbs>
        )} */}
      </Box>

      <Typography variant="h4" gutterBottom={!subtitle}>
        {title?.icon ?? undefined}
        {titleText}
      </Typography>

      {subtitle && (
        <Typography variant="subtitle1" color="textSecondary">
          {subtitle?.icon ?? undefined}
          {subtitleText}
        </Typography>
      )}
      
      {children}
    </Box>
  );
};

export default Page;
