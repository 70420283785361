import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import LoadCellChart from "./../common/LoadCellChart";

const EventLoadCellChart = ({ eventId, sessionStartTime }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Load Cell Chart
      </Typography>
      <Card>
        <CardContent>
          <Box sx={{ height: 400 }}>
            <LoadCellChart eventId={eventId} startTime={sessionStartTime} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default EventLoadCellChart;
