import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Button,
  Grid,
  Checkbox,
  Select,
  MenuItem
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import { useSearchParams } from "../../../utils/useSearchParams";
import HouseholdEventsTable from "../../Table/HouseholdEventsTable/HouseholdEventsTable";
import { useTimezone } from "../../../utils/context/Timezone";
import moment from "moment-timezone";
import HouseholdEventsChart from "./HouseholdEventsChart/component";
import { DateRangePicker } from 'rsuite';

const HouseholdEvents = () => {
  const { householdId } = useParams()
  const [newData, setNewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const timezone = useTimezone()

  const queryParams = new URLSearchParams();

  const getTwoWeeksAgo = ({ timezone }) => {
    return moment()
      .tz(timezone)
      .subtract(14, "days")
      .format("YYYY-MM-DD")
  }
  const getEndOfToday = ({ timezone }) => {
    return moment()
      .tz(timezone)
      .format("YYYY-MM-DD")
  }


  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    // machineSns: undefined,
    hideFalseEvents: true,
    fromDate: getTwoWeeksAgo({ timezone }),
    toDate: getEndOfToday({ timezone }),
  })

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [checked, setChecked] = useState(true);
  const [deviceTagData, setDeviceTagData] = useState([]);
  const [form, setForm] = useState({
    snSuffix: [],
  });

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]
    }
    setIsLoading(true)
    setSearchParams(newSearchParams);
  };

  // Set householdId as a query parameter
  queryParams.set('household_id', householdId);

  const machineSns = searchParams.get("snSuffix");
  if (machineSns) {
    queryParams.set('snSuffix', machineSns);
  }

  // Set fromDate and toDate as query parameters
  const fromDate = searchParams.get("fromDate");
  if (fromDate) {
    const formattedFromDate = fromDate + "T00:00:00" + moment.tz(timezone).format("Z");
    queryParams.set('fromDate', formattedFromDate);
  }

  const toDate = searchParams.get("toDate");
  if (toDate) {
    const formattedToDate = toDate + "T23:59:59" + moment.tz(timezone).format("Z");
    queryParams.set('toDate', formattedToDate);
  }

  // Set hideFalseEvents as a query parameter
  const hideFalseEvents = searchParams.get("hideFalseEvents");
  if (checked !== null) {
    
    queryParams.set('hideFalseEvents', checked);
  }

  // let toDate = new Date();
  // let fromDate = moment(new Date(new Date().setDate(toDate.getDate() - 30))).format('YYYY-MM-DD');
  // toDate = moment(new Date()).format('YYYY-MM-DD');

  // Set page and perPage as query parameters
  const page = Number(searchParams.get("page"));
  const perPage = Number(searchParams.get("pageSize"));
  queryParams.set('page', page.toString());
  queryParams.set('pageSize', perPage.toString());

  const dateFormat = "YYYY-MM-DD"
  const timeFormat = "HH:mm:ss"
  const format = `${dateFormat} ${timeFormat}`


  const [value, setValue] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    if (date != null && date.length > 0) {

      const start = moment(date[0]).format('YYYY-MM-DD');
      setStartDate(start);
      const end = moment(date[1]).format('YYYY-MM-DD');
      setEndDate(end);
      setValue(date)
    }
    else {
      setValue(new Date());
      setStartDate(null);
      setEndDate(null);

    }
  };



  useEffect(() => {
    fetchData(queryParams.toString())
    getDeviceTags();
  }, []);

  const fetchData = async (queryParams) => {
    setIsLoading(true)
    const url = `/household-events?${queryParams}`;

    axiosInstance.get(url)
      .then((response, error) => {
        if (error) {
          setError(error)
          return;
        }
        setIsLoading(false);
        setNewData(response.data);

      }).catch((error) => {
        setIsLoading(false);
      });
  }


  const getDeviceTags = () => {
    setIsLoading(true);
    const durl = `/devices-tags?household_id=${householdId}`;

    axiosInstance
      .get(durl)
      .then((response) => {
        setDeviceTagData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const refreshEventList = (page = 1) => {
    queryParams.set('hideFalseEvents', checked);
    const formattedFromDate = startDate + "T00:00:00" + moment.tz(timezone).format("Z");
    queryParams.set('fromDate', formattedFromDate);
    const formattedToDate = endDate + "T23:59:59" + moment.tz(timezone).format("Z");
    queryParams.set('toDate', formattedToDate);

    fetchData(queryParams.toString())
    setIsLoading(true);
  };

  const handleFormChange = (field) => (event) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: event.target.value,
    }));
    queryParams.set('machineSns', form.snSuffix);
    fetchData(queryParams.toString())
    setIsLoading(true);
  };


  const eventPagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
        // totalCount
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }


  const dataTransformedForTable = newData?.data.map((data) => {
    return {
      ...data,
      id: data.id,
      startTime: data.startTime,
      monitorSN: data.machine.sn,
      aiisCat: data.classifications?.isCat ?? "",
      aiCatName: data.classifications.cat?.name ?? "-",
      aiCatID: data.classifications.cat?.id ?? "",
      rawisCat: data.normalisedClassification.isCat ?? "",
      rawCatName: data.normalisedClassification.cat?.name ?? "-",
      rawCatID: data.normalisedClassification.cat?.id ?? "",
      labelisCat: data.labels?.isCat ?? "",
      labelCatName: data.labels?.cat?.name ?? "-",
      household_id: householdId
    }
  }) ?? []


  return (

    <Box sx={{ padding: 2 }}>

      <Select
        style={{ backgroundColor: 'white', width: '25%', height: "46px" }}
        labelId="devices-label"
        id="devices"
        name="snSuffix"
        multiple
        defaultValue={form.snSuffix}
        onChange={handleFormChange("snSuffix")}
        renderValue={(selected) => selected.join(", ")}
        required
      >
        {deviceTagData.map((data, i) => (
          <MenuItem key={i} value={data}>
            {data}
          </MenuItem>
        ))}
      </Select>
      <FormControlLabel control={<Checkbox />} checked={checked} onChange={e => [setChecked(e.target.checked)]} name="hideFalseEvents" value={hideFalseEvents} label="Hide False Events" />

      <DateRangePicker onChange={handleStartDateChange}
        defaultValue={[new Date(fromDate), new Date(toDate)]} />
      <Button key="filter" onClick={() => refreshEventList()}>Filter</Button>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (

        [<HouseholdEventsChart />,
        <Grid container>
          {/* <Grid item xs>
            <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} />
          </Grid> */}

          <HouseholdEventsTable
            data={dataTransformedForTable}
            loading={false}
            columnsFn={null}
            pagination={eventPagination}
          />
        </Grid>]
      )}
    </Box>
  );
};

export default HouseholdEvents;
