import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import shortenGraphId from "./../../../utils/shortenGraphId";

const HouseholdNotifications = () => {
  const { householdId } = useParams();
  const [newData, setNewData] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({ page: 1, pageSize: 10 });

  const getNotifications = () => {
    setisLoading(true);
    const url = `/household-notifications?page=${searchParams.page}&pageSize=${searchParams.pageSize}&household_id=${householdId}`;
    axiosInstance
      .get(url)
      .then((response) => {
        const item = response?.data;
        setNewData(item);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  if (newData?.data === null) {
    return (
      <Typography variant="h6" color="error">
        No data object
      </Typography>
    );
  }

  const columns = [
    {
      title: "Status",
      children: [
        {
          title: "Devices Reached",
          dataIndex: "devicesReached",
          key: "devicesReached",
          align: "center",
          render: ({ successful, total, complete }) => {
            const waitingMessage = `It takes a short while to receive this information - refresh the table in a few seconds to check the status`;
            const completeMessage = `The notification service has marked this notification as complete`;

            if (!complete) {
              return (
                <Tooltip title={waitingMessage}>
                  <AccessTimeIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "blue",
                    }}
                  />
                </Tooltip>
              );
            }
            return (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {`${successful} / ${total}`}
                </Typography>
                <Tooltip title={completeMessage}>
                  <CheckCircleIcon color="success" />
                </Tooltip>
              </Box>
            );
          },
          width: 110,
        },
        {
          title: "Sent At",
          dataIndex: "sentAt",
          key: "sentAt",
          render: (t) => <Typography>{new Date(t).toLocaleString()}</Typography>,
          width: 120,
        },
      ],
    },
    {
      title: "Content",
      children: [
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 100,
        },
        {
          title: "Message",
          dataIndex: "message",
          key: "message",
          ellipsis: {
            showTitle: false,
          },
          render: (message) => (
            <Tooltip placement="topLeft" title={message}>
              {message}
            </Tooltip>
          ),
          width: 200,
        },
        {
          title: "Event ID",
          dataIndex: "eventId",
          key: "eventId",
          render: function EventId(id) {
            if (!id) {
              return "-";
            }
            return (
              <Link to={`/events/${id}`}>
                <Typography.Text
                  copyable={{ text: id }}
                  style={{ color: "inherit" }}
                >
                  {shortenGraphId(id)}
                </Typography.Text>
              </Link>
            );
          },
          width: 140,
        },
        {
          title: "Cat",
          dataIndex: "cat",
          key: "cat",
          render: function CatName(cat) {
            return cat && cat.name ? (
              <Link to={`/cats/${cat.id}`}>{cat.name}</Link>
            ) : (
              <div>-</div>
            );
          },
          width: 100,
        },
      ],
    },
    {
      title: "Destination",
      children: [
        {
          title: "Destination Machines",
          dataIndex: "machineSns",
          key: "machineSns",
          render: (machineSns) =>
            machineSns.length === 0 ? "-" : <div>{machineSns.join(", ")}</div>,
          width: 200,
        },
      ],
    },
  ];

  const dataMapper = (newData) =>
    newData?.data?.map((edge) => {
      return {
        key: edge.id,
        devicesReached: {
          successful: edge.successful,
          total: edge.total,
          complete: edge.complete,
        },
        message: edge.message,
        cat: edge.cat?.name ?? null,
        mode: edge.mode,
        period: edge.period,
        action: edge.action,
        sentAt: edge.sentAt,
        sentBy: edge.sentBy,
        machineSns: edge.machineSns?.map((machine) => machine.sn) ?? [],
      };
    }) ?? [];

  const pagination = {
    position: ["topRight", "bottomRight"],
    defaultCurrent: 1,
    current: searchParams.page,
    pageSize: searchParams.pageSize,
    total: parseInt(newData?.pageInfo?.totalCount ?? 0),
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    showSizeChanger: true,
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => getNotifications()}
        >
          Reload
        </Button>
      </Box>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.title}>
                    {column.children ? (
                      column.children.map((child) => (
                        <TableCell key={child.title}>{child.title}</TableCell>
                      ))
                    ) : (
                      <TableCell>{column.title}</TableCell>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataMapper(newData).map((row) => (
                <TableRow key={row.key}>
                  {columns.map((column) => (
                    <TableCell key={column.title}>
                      {column.children
                        ? column.children.map((child) => (
                            <TableCell key={child.title}>
                              {child.render
                                ? child.render(row[child.dataIndex])
                                : row[child.dataIndex]}
                            </TableCell>
                          ))
                        : row[column.dataIndex]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default HouseholdNotifications;
