import React, { useState, useEffect } from "react";
import "./CatFeeder.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import FilterPopover from "../../../../utils/FilterPopover";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CatsRouteQueryControls from "../../../../utils/catUtills/CatsRouteQueryControls";
import { useSearchParams } from "../../../../utils/useSearchParams";
import CatFeederTable from "../../../Table/CatFeederTable/CatFeederTable";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";
import DeviceSnSuffixSearch from "../../../../utils/DeviceSnSuffixSearch";

const CatFeeder = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filters, setFilters] = useState({});
  const [newData, setNewData] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
  });
  const [isLoading, setisLoading] = React.useState(true);
  const [isupdateTable, setisUpdate] = React.useState(1);

  const updateTable = (newState) => {
    setisUpdate(newState);
};


  const getAllSearchParams = (key) => {
    const resultsArray = searchParams.getAll(key)
    if (resultsArray.length === 0) return null

    return resultsArray
}

  useEffect(() => {
    setisLoading(true);


    // Calls Cat listing API
    let url = "/get-cat-feeder-device-list?page=" + searchParams.get("page") +
            "&pageSize=" + searchParams.get("pageSize")
            + "&snSuffix=" + searchParams.get("snSuffix")

    axiosInstance.get(url)
      .then((response, error) => {
        if (error) {
          setError(error);
          return;
        }
        setNewData(response.data);
        setisLoading(false);
      }).catch((error) => {
        setError(error);
        setisLoading(false);
      })


  }, [searchParams, setNewData, isupdateTable]);

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]
    }
    setisLoading(true)
    setSearchParams(newSearchParams);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    console.log("going to apply filter", filters)
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // Add logic to apply the filters to your data fetching logic
  };


  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    // totalCount: searchParams.get("totalCount")
    //   ? Number(searchParams.get("totalCount"))
    //   : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
        // totalCount
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
    }
  }) ?? []


  const updateParentState = (value) => {
    console.log("Parent state updated with value: ", value);
  };
  
  return !isLoading ?(
    <div className="module-content">
      <h5>Cats</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
            <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} />
            </Grid>
            {/* <Grid item>
              <IconButton onClick={handleFilterClick}>
                <FilterAltIcon />
              </IconButton>
            </Grid> */}
          </Grid>
          <br></br>

          <Grid container>
            <CatFeederTable
              data={dataTransformedForTable}
              loading={false}
              columnsFn={null}
              pagination={pagination}
              updateParentState={updateTable}
              isupdateTable={isupdateTable}
            />
          </Grid>
        </CardContent>
      </Card>
      {/* <FilterPopover
        filterOptions={filterOptions}
        anchorEl={filterAnchorEl}
        handleClose={handleFilterClose}
        handleApply={handleFilterApply}
      /> */}
    </div>
  ): (
    <LoadingSpinner />
  );
};

export default CatFeeder;
