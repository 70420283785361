import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "bootstrap/dist/css/bootstrap.min.css";
import "./StatsCard.css";

const StatsCard = ({ stats }) => {
  return (
    <Card className="stats-card">
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between">
          {stats.map((stat, index) => (
            <React.Fragment key={index}>
              <Grid item className="stat-item">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      className="stat-title"
                    >
                      {stat.title}
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ marginLeft: 4 }}
                      />
                    </Typography>
                  </Box>
                  <Typography
                    variant="h5"
                    component="div"
                    className="stat-value"
                  >
                    {stat.value}
                  </Typography>
                </Box>
              </Grid>
              {index < stats.length - 1 && (
                <>
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className="vertical-divider"
                    />
                  </Grid>
                  <Grid item>
                    <Box width="45px" />
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
