import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import background from './../../assets/images/background.png';
import logo from './../../assets/images/purniaLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../api/axios/axiosInstance';
import LoadingSpinner from '../LoadingSpinner/component';


const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setisLoading] = React.useState(true);
  const [loginData, SetLoginData] = React.useState("");

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") != null && localStorage.getItem("id_token") != null
      && localStorage.getItem("refresh_token") != null && localStorage.getItem("csrf_buster") != null) {
        navigate('/home');
    }

  })
  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    axiosInstance.post('/cognito-auth', {
      username: username, password: password
    }, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((response, error) => {
      if (error) {
        setError(error)
        return;
      }
      setisLoading(false);
      if (response.data.message === "success") {
        SetLoginData(response.data.data)
        localStorage.setItem("access_token", response.data.data.access_token)
        localStorage.setItem("id_token", response.data.data.id_token)
        localStorage.setItem("refresh_token", response.data.data.refresh_token)
        localStorage.setItem("csrf_buster", response.data.data.csrf_buster)
        localStorage.setItem("name", response.data.data.name)
        localStorage.setItem("username", response.data.data.username)
        setisLoading(false)
        navigate('/home');
      }
      else {
        setError('Invalid credentials');
      }
    }).catch((error) => {
      setisLoading(false);
    });
  };

  return (

    <div className="login-container" style={{ backgroundImage: `url(${background})` }}>
      <div className="login-box">
        <img src={logo} alt="Purina Logo" className="login-logo" />
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group password-group">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span onClick={togglePasswordVisibility} className="password-toggle-icon">
              <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
            </span>
          </div>
          {error && <div className="error" style={{ color: 'red' }}>{error}</div>}
          <div className="form-group">
            <a href="/forgot-password">Forgot your password?</a>
          </div>
          <div className="form-group">
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
