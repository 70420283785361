import React, { useState, useEffect } from "react";
import "./Location.css";
import { Card, CardContent, Grid, IconButton, Button } from "@mui/material";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import DeviceSnSuffixSearch from "../../../../utils/DeviceSnSuffixSearch";

import LocationTable from "../../../Table/LocationTable/LocationTable";
import { useSearchParams } from "../../../../utils/useSearchParams";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";
import { castBooleanStringToBoolean } from "../../../../utils/castBooleanStringToBoolean";
import LocationCreateModal from "../../../../utils/locationUtils/LocationCreateModal";

const Locations = () => {
    const [newData, setNewData] = React.useState();
    const [newDataRevision, setNewDataRevision] = React.useState();
    const [error, setError] = useState('');
    const [newLocationModalIsOpen, setNewLocationModalIsOpen] = useState();
    const [searchParams, setSearchParams] = useSearchParams({
        page: 1,
        pageSize: 50,
        sort: "SN_ASC",
    })
    const [isLoading, setisLoading] = React.useState(true);
    const [facilitypanelData, setFacilityPanelData] = useState([]);
    const [ptcCats, setPtcCats] = useState([]);

    const [isupdateTable, setisupdateTable] = React.useState(2)
    const updateTable = (newState) => {
        setisupdateTable(newState);
    };

    

    const getAllSearchParams = (key) => {
        const resultsArray = searchParams.getAll(key)
        if (resultsArray.length === 0) return null

        return resultsArray
    }

      const fetchTags = async () => {
        try {
          const response = await axiosInstance.get("/get-panel-facility-list");
          setFacilityPanelData(response.data);
        } catch (error) {
          console.error("Error fetching tags:", error);
        } finally {
            setisLoading(false);
        }
      };

      const fetchCats = async () => {
        try {
          const response = await axiosInstance.get("/get-ptc-cats");
          setPtcCats(response.data);
        } catch (error) {
          console.error("Error fetching tags:", error);
        } finally {
            setisLoading(false);
        }
      };

    useEffect(() => {
        setisLoading(true);
        console.log("searchparams", searchParams.get("sort"))
        let url = "/locations?page=" + searchParams.get("page") +
            "&pageSize=" + searchParams.get("pageSize")
            + "&snSuffix=" + searchParams.get("snSuffix")
            + "&sort=" + searchParams.get("sort")
        axiosInstance.get(url)
            .then((response, error) => {
                if (error) {
                    // useErrorHandler(error)
                    setError(error)
                    return;
                }
                setisLoading(false);
                setNewData(response.data)
            }).catch((error) => {
                setisLoading(false);
            })
        
        fetchTags()
        fetchCats()

    }, [searchParams, setNewData, isupdateTable])

    
    

    const handleSearch = (newSnSuffix) => {
        let newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: 1,
            snSuffix: newSnSuffix.trim(),
        }
        if (newSnSuffix === "") {
            delete newSearchParams["snSuffix"]
        }
        setisLoading(true)
        setSearchParams(newSearchParams);
    };


    const pagination = {
        current: searchParams.get("page")
            ? Number(searchParams.get("page"))
            : 0,
        pageSize: searchParams.get("pageSize")
            ? Number(searchParams.get("pageSize"))
            : 0,
        onChange: (page, pageSize) => {
            setSearchParams({
                ...Object.fromEntries(searchParams),
                page,
                pageSize,
            })
        },
        total: parseInt(newData?.pageInfo.totalCount ?? 0),
    }

    const dataTransformedForTable = newData?.data.map((monitor) => {
        return {
            ...monitor,
            key: monitor.device_id,
        }
    }) ?? []

    const updateParentState = (value) => {
        console.log("Parent state updated with value: ", value);
    };

    const [filters, setFilters] = useState({});
    return !isLoading ? (
        <div className="module-content">
            <h5>Locations</h5>
            <Card>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} />
                        </Grid>
                        <Grid item>
{/*                             
                            <AddLocationIcon key="AddLocation"
                                onClick={() => setNewLocationModalIsOpen(true)}>
                                    Add Location
                            </AddLocationIcon> */}
                            <Button
                                key="AddLocation"
                                onClick={() => setNewLocationModalIsOpen(true)}
                            icon={<AddLocationIcon />}
                            >
                                Add Location
                            </Button>
                        </Grid>
                    </Grid>
                    <br></br>

                    <Grid container>
                        <LocationTable
                            data={dataTransformedForTable}
                            loading={false}
                            pagination={pagination}
                            updateParentState={updateTable}
                            isupdateTable={isupdateTable}
                            facilitypanelData={facilitypanelData}
                            ptcCats={ptcCats}
                        />
                    </Grid>
                    {newLocationModalIsOpen && (
                        <LocationCreateModal
                            isModalOpen={true}
                            closeModal={() => {
                                setNewLocationModalIsOpen(false);
                                updateParentState(isupdateTable + 1)
                            }}
                            facilitypanelData={facilitypanelData}
                            ptcCats={ptcCats}
                        />
                    )}
                </CardContent>
            </Card>


        </div>
    ) : (
        <LoadingSpinner />
    );
};

export default Locations;
