export const findChunksAtEndOfString = ({ searchWords, textToHighlight }) => {
    const chunks = []
    if (searchWords.length > 0) {
        searchWords.forEach((sw) => {
            if (sw === undefined || sw === null) return

            const swLow = sw.toLowerCase()
            const wholeString = textToHighlight.toLowerCase()
            const regex = new RegExp(`${swLow}$`)
            const startIndex = wholeString.match(regex)?.index
            if (startIndex === undefined) return

            chunks.push({
                start: startIndex,
                end: textToHighlight.length,
            })
        })
    }
    return chunks
}
