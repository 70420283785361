import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    Paper,
    Box,
    Select,
    MenuItem,
    Typography
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from 'moment-timezone';

const Time = ({ children }) => {
    return <span>{moment(children).format("LLL")}</span>;
};

const NoData = styled('div')({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled('ul')({
    margin: 0,
    paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const FullWidthPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const FullWidthTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};



const useStyles = styled({
    monitorNameAndEdit: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
    },
});

const CatEventTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    // updateParentState,
    // isupdateTable,
    revisionOptions: {
        existingStFirmwareRevisions = [],
        existingEspFirmwareRevisions = [],
        existingHardwareRevisions = [],
    } = {},
    ...props
}) => {
    const styles = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const timezone = 'UTC';  // Assuming a timezone value, replace it with your logic

    const formatDateBySelectedTimezone = (date) =>
        moment(date + "Z")
            .tz(timezone)
            .format("YYYY-MM-DDTHH:mm:ss Z");

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "ascend";
        if (sortSuffixesRegex.groups?.descend) return "descend";
        return null;
    };


    const forwardTableChangeToSearchParams = (pagination) => {


        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();
        setSearchParams(newSearchParams);
    };

    let columns = [
        {
            title: "Start time",
            dataIndex: "startTime",
            key: "startTime",
            displayName: "Start time",
            // render: (d) => {
            //     if (d) return {d}
            //     else return "-"
            // },
        },
        {
            title: "Mearured Weight",
            dataIndex: "weight",
            width: 200,
            key: "weight",
            fixed: "left",
            sorter: false
        },
        {
            title: "Monitor SN",
            dataIndex: "monitor_sn",
            width: 200,
            key: "monitor_sn",
            fixed: "left",
            sorter: false,
        },
        {
            title: "Action",
            dataIndex: "session_id",
            key: "session_id",
            width: 150,
            render: function renderActionButton(id) {
                return (
                    <ActionIconButton component={Link} to={`/cat-litter-box/events/${id}`}>
                        <VisibilityIcon />
                    </ActionIconButton>
                );
            },
        },
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }

    const handleChangePage = (event, newPage) => {
        forwardTableChangeToSearchParams(
            { current: newPage + 1, pageSize: pagination.pageSize },
            {},
            {}
        );
    };

    const handleChangeRowsPerPage = (event) => {
        forwardTableChangeToSearchParams(
            { current: 1, pageSize: parseInt(event.target.value, 10) },
            {},
            {}
        );
    };


    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };

    return (
        <FullWidthPaper>
            <FullWidthTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={true}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
            </FullWidthTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    // onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    // onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <WatchLaterIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
            )}
        </FullWidthPaper>
    );
};

export default CatEventTable;
