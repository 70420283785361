import React from "react"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from "@mui/material";

const TableCellHasPushMessage = ({ hasPushMessage }) => {
    const positiveText =
        "At least 1 Push Message has been sent for this PEDT result"
    const negativeText = "No Push Messages have been sent for this PEDT result"
    if (hasPushMessage == true) {
        return (
            <Tooltip title={positiveText}>
                <CheckCircleOutlineIcon
                    aria-label="At least 1 Push Message has been sent for this PEDT result"
                    twoToneColor="#52c41a"
                />
            </Tooltip>
        )
    }
    return (
        <Tooltip title={negativeText}>
            <HighlightOffIcon
                aria-label="No Push Messages have been sent for this PEDT result"
                twoToneColor="#eb2f96"
            />
        </Tooltip>
    )
}

export default TableCellHasPushMessage
