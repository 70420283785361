import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Paper,
  CircularProgress,
  styled,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios/axiosInstance";
import DogCollarMachines from "./DogCollarMachines";
import OnboardDeviceByHousehold from "../../../utils/OnboardDeviceByHousehold";
import DogCollarHouseholdCats from "./DogCollarHouseholdCats";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const InfoBox = ({ title, value }) => (
  <Box textAlign="center">
    <Typography variant="body2" color="textSecondary">
      {title}
    </Typography>
    <Typography variant="h6" component="div">
      {value}
    </Typography>
  </Box>
);

const DogCollarHouseholdSingleView = () => {
  const { householdId } = useParams();
  const [newData, setNewData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const url = `/get-dog-collar-household?household_id=${householdId}`;

  useEffect(() => {
    fetchData();
  }, [setNewData]);

  const fetchData = () => {
    axiosInstance
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!newData) {
    return (
      <Typography variant="h6" color="error">
        This Household does not exist. Please return to the Monitor list.
      </Typography>
    );
  }

  return (
    <Box p={3}>
      <Button
        startIcon={<ArrowBackIcon />}
        component={Link}
        to="/dog-collar/monitors"
      >
        Go Back
      </Button>
      <Grid container spacing={3} justifyContent="center">
        
        
        <Grid item xs={12}>
          
          <DogCollarMachines fetchData={newData} />
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h5">Cats</Typography>
          <DogCollarHouseholdCats />
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default DogCollarHouseholdSingleView;
