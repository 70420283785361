import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    CircularProgress,
    Box,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import * as yup from "yup";
import axiosInstance from "../../../api/axios/axiosInstance";
import { useForm, Controller } from "react-hook-form";

const validationSchema = yup.object({
    name: yup.string(),
});

const EditMonitorGroup = ({ machine, submitCallback, closeCallback }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState({
        device_list: [],
    });


    const device_list = machine.device_list
    const household_devices = machine.household_devices

    const handleFormChange = (field) => (event) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: event.target.value,
        }));
    };




    const { control, handleSubmit, setValue, reset } = useForm({

        defaultValues: {
            group_name: machine.group_name,
            device_list: machine.device_list
        }
    });

    const onSubmit = async (values) => {
        setIsLoading(true);
        let deviceList = []
        if (form.device_list.length > 0) {
            deviceList = form.device_list
        } else {
            deviceList = machine.device_list
        }
        const data = {
            group_name: machine.group_name,
            household_id: machine.household_id,
            device_id_list: deviceList
        }


        axiosInstance.post('/update-household-machines-groups', data).then((response, error) => {
            if (response.data.message == 'Update successful') {
                console.log('Successfully updated the Household Group')
                setIsLoading(false);
                submitCallback()
                closeCallback()

            }
        })
    };






    return (
        <Dialog open onClose={closeCallback}>
            <DialogTitle>Edit Monitor Group</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Controller
                            name="group_name"
                            control={control}
                            rules={{ required: "Please name this Group" }}
                            render={({ field }) => (
                                <TextField
                                    value={machine.group_name}
                                    {...field}
                                    label="Group Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Controller
                            name="device_list"
                            control={control}
                            rules={{ required: "Please name this Group" }}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id="device_list"
                                    style={{ width: "100%" }}
                                    multiple
                                    placeholder="Devices"
                                    defaultValue={device_list}
                                    onChange={handleFormChange("device_list")}
                                    isLoading={isLoading}
                                >
                                    {household_devices.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />




                        <DialogActions>
                            <Button onClick={closeCallback} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" disabled={isLoading}>
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditMonitorGroup;
