import * as React from "react"
import { useParams } from "react-router-dom"
import MachinesTable from "./../../Table/MachinesTable/MachinesTable"

import axiosInstance from "./../../../api/axios/axiosInstance"

const MachineAttributes = () => {
    const { machineId } = useParams()
    const [newData, setNewData] = React.useState();
    const [isLoading, setisLoading] = React.useState(true);
    const [isupdateTable, setisupdateTable] = React.useState(2)
    const updateTable = (newState) => {
        setisupdateTable(newState);
      };
    React.useEffect(() => {
        axiosInstance.post('/machine-attribute-details', { machine_id: machineId }, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response, error) => {
                if (error) {
                    return;
                }
                setisLoading(false);
                setNewData(response.data)
            }).catch((error) => {
                setisLoading(false);
            })
    }, [isupdateTable, machineId])


    const disableAllSortAndFiltering = (columns) => {
        return columns.map((col) => {
            col.sorter = undefined
            col.defaultSortOrder = undefined
            col.filters = undefined
            col.filterMode = undefined
            col.defaultFilteredValue = undefined
            return col
        })
    }

    return (
        <MachinesTable
            data={newData ? [newData.data] : []}
            loading={isLoading}
            pagination={false}
            // data-testid={tids.machineSingleView.attributesTable}
            columnsFn={disableAllSortAndFiltering}
            updateParentState={updateTable}
            isupdateTable={isupdateTable}
        />
    )
}

export default MachineAttributes
