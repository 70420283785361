import React from "react"

export const Cat = (props) => {
    return (
        <svg
            width="14px"
            height="14px"
            viewBox="0 0 12 12"
            data-testid="icon_cat"
        >
            <path
                d="M7.66666667,3.77777778 L4.33333333,3.77777778 L1,1 L1,6.83333333 C1,9.13454861 3.23854167,11 6,11 C8.76145833,11 11,9.13454861 11,6.83333333 L11,1 L7.66666667,3.77777778 Z"
                fill={props.fill}
            ></path>
        </svg>
    )
}
export const Star = (props) => {
    // tabler icon
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-testid="icon_star"
            width="14px"
            height="14px"
            viewBox="0 0 24 24"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                fill={props.fill ?? "black"}
                d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
            />
        </svg>
    )
}

export const Circle = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 32 32"
            version="1.1"
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "4px",
            }}
        >
            <path
                fill="black"
                cx="16"
                cy="16"
                transform="translate(16, 16)"
                d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
            ></path>
        </svg>
    )
}
export const OuterRing = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 40 40"
            version="1.1"
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "4px",
            }}
            pointerEvents="none"
        >
            <path
                strokeWidth="4"
                stroke={props.fill ?? "black"}
                fill="none"
                transform="translate(20, 20)"
                d="M18,0A18,18,0,1,1,-18,0A18,18,0,1,1,18,0"
            ></path>
        </svg>
    )
}

export const G = ({ x, y, children }) => (
    <g transform={`translate(${x},${y})`}>{children}</g>
)
