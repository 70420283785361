import React from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import axiosInstance from "./../../../api/axios/axiosInstance";
import HouseholdsTable from "./../../Table/HouseholdsTable/HouseholdsTable";

const HouseholdAttributes = () => {
  const { householdId } = useParams();
  const [newData, setNewData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const url = `/household-attributes?household_id=${householdId}`;

  React.useEffect(() => {
    axiosInstance
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [url]);

  const disableAllSortAndFiltering = (columns) => {
    return columns.map((col) => {
      col.sorter = undefined;
      col.defaultSortOrder = undefined;
      col.filters = undefined;
      col.filterMode = undefined;
      col.defaultFilteredValue = undefined;
      return col;
    });
  };

  const columnsToHide = ["Actions"];
  const hideColumns = (columns) =>
    columns.filter((column) => !columnsToHide.includes(column.title));

  const columnsFn = (columns) =>
    disableAllSortAndFiltering(hideColumns(columns));

  const householdNode = newData
    ? {
        ...newData,
        key: newData.id,
      }
    : null;

  return (
    <Box sx={{ padding: 2 }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <HouseholdsTable
          data={householdNode ? [householdNode] : []}
          loading={isLoading}
          pagination={false}
          data-testid={"householdSingleView.attributesTable"}
          columnsFn={columnsFn}
        />
      )}
    </Box>
  );
};

export default HouseholdAttributes;
