import React, { useState, useEffect } from "react";
import "./Monitors.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import DeviceSnSuffixSearch from "../../../../utils/DeviceSnSuffixSearch";
import FilterPopover from "../../../../utils/FilterPopover";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MachinesTable from "../../../Table/MachinesTable/MachinesTable";
import { useSearchParams } from "../../../../utils/useSearchParams";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";
import { castBooleanStringToBoolean } from "../../../../utils/castBooleanStringToBoolean";

const Monitors = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [newData, setNewData] = React.useState();
  const [newDataRevision, setNewDataRevision] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    sort: "SN_ASC",
})
const [isLoading, setisLoading] = React.useState(true);
const [isupdateTable, setisupdateTable] = React.useState(2)
const updateTable = (newState) => {
    setisupdateTable(newState);
  };

  const filterOptions = [
    {
      name: "isFrozen",
      label: "Frozen",
      values: [{"label": "Not Frozen", "value": false}, {"label": "Is Frozen", "value": true}],
    },
    {
      name: "isOrphaned",
      label: "Orphaned",
      values: [{"label": "Not Orphaned", "value": false}, {"label": "Is Orphaned", "value": true}],
      // values: ["Not Orphaned", "Is Orphaned"],
    },
    {
      name: "powerMode",
      label: "Power",
      values: [{"label": "AC", "value": "AC"}, {"label": "Battery", "value": "Battery"},
      {"label": "Low", "value": "Low (<30%)"}, {"label": "High", "value": "High (>30%)"}],
      // values: ["AC", "Battery", "Low (<30%)", "High (>30%)"],
    },
  ];

  const getAllSearchParams = (key) => {
    const resultsArray = searchParams.getAll(key)
    if (resultsArray.length === 0) return null

    return resultsArray
}

useEffect(() => {
  setisLoading(true);
  console.log("searchparams", searchParams.get("sort"))
  let url = "/machine-listing?page=" + searchParams.get("page") +
      "&pageSize=" + searchParams.get("pageSize")
      + "&snSuffix=" + searchParams.get("snSuffix")
      + "&sort=" + searchParams.get("sort") + "&isFrozen="
      + castBooleanStringToBoolean(searchParams.get("isFrozen"))
      + "&isOrphaned=" + castBooleanStringToBoolean(searchParams.get("isOrphaned"))
      + "&powerMode=" + searchParams.get("powerMode")
      + "&showBatteryWarning=" + castBooleanStringToBoolean(
          searchParams.get("showBatteryWarning"),
      )
      + "&mostRecentUploadWarning=" +
      castBooleanStringToBoolean(
          searchParams.get("mostRecentUploadWarning"),
      )
      + "&stFirmwareRevision=" + getAllSearchParams("stFirmwareRevision")
      + "&espFirmwareRevision=" +
      getAllSearchParams("espFirmwareRevision")
      + "&hardwareRevision=" + getAllSearchParams("hardwareRevision")
  axiosInstance.get(url)
      .then((response, error) => {
          if (error) {
              // useErrorHandler(error)
              setError(error)
              return;
          }
          setisLoading(false);
          setNewData(response.data)
      }).catch((error)=>{
          setisLoading(false);
      })
      axiosInstance.get('/AllRevisions').then((response,error)=>{
          if (error) {
              // useErrorHandler(error)
              setError(error)
              return;
          }
          setNewDataRevision(response.data)
      });
      
}, [isupdateTable, searchParams])

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]
    }
    setisLoading(true)
    setSearchParams(newSearchParams);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // Add logic to apply the filters to your data fetching logic
  };

  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
    }
  }) ?? []

  const [filters, setFilters] = useState({});
  return !isLoading ?(
    <div className="module-content">
      <h5>Monitors</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} />
            </Grid>
            <Grid item>
              <IconButton onClick={handleFilterClick}>
                <FilterAltIcon />
              </IconButton>
            </Grid>
          </Grid>
          <br></br>

          <Grid container>
            <MachinesTable
              data={dataTransformedForTable}
              loading={false}
              pagination={pagination}
              updateParentState={updateTable}
              isupdateTable={isupdateTable}
            />
          </Grid>
        </CardContent>
      </Card>
      <FilterPopover
        filterOptions={filterOptions}
        anchorEl={filterAnchorEl}
        handleClose={handleFilterClose}
        handleApply={handleFilterApply}
      />
    </div>
  ): (
    <LoadingSpinner />
  );
};

export default Monitors;
