import React from "react";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Button } from "@mui/material";

import CatCreateModal from "./CatCreateModal";

const EditCatButton = ({ existingCat, updateParentState, isupdateTable, ...props }) => {
    const [isUpdateCatModalOpen, setIsUpdateCatModalOpen] = React.useState(false);

    return (
        <>
            <Button
                onClick={() => setIsUpdateCatModalOpen(true)}
                startIcon={<EditOutlined />}
                // data-testid={tids.catsTable.editCatButton}
                {...props}
            >
                Edit
            </Button>
            <CatCreateModal
                isModalOpen={isUpdateCatModalOpen}
                closeModal={() => {
                    setIsUpdateCatModalOpen(false);
                    updateParentState(isupdateTable + 1);
                }}
                existingCat={existingCat}
            />
        </>
    );
};

export default EditCatButton;
