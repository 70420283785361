import React from "react";
import { IconButton, Tooltip, Button } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const TagButton = styled(Button)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.grey[700],
  borderRadius: "12px",
  margin: ".33rem",
  height: "24px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  padding: "0 8px",
  minWidth: 0,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.grey[700],
  borderRadius: "100%",
  height: "14px",
  width: "14px !important",
  marginLeft: "4px",
  padding: 0,
}));

const EventTag = ({ text, onClick = null }) => {
  const theme = useTheme();
  const isLongTag = text.length > 15;
  const tooltipId = `tooltip-${text}`;

  const tagElement = (
    <TagButton
      disableRipple
      disableFocusRipple
      aria-label={text}
      onClick={onClick}
      endIcon={onClick && <CloseIconButton size="small"><CloseIcon fontSize="small" /></CloseIconButton>}
    >
      {isLongTag ? `${text.slice(0, 15)}...` : text}
    </TagButton>
  );

  if (!isLongTag) return tagElement;
  else
    return (
      <Tooltip title={text} id={tooltipId} aria-label={text}>
        {tagElement}
      </Tooltip>
    );
};

export default EventTag;
