import React, { useState } from 'react';
import { Box, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SessionIDSearch = ({ onSearch, defaultValue, placeholder=null }) => {
    const [value, setValue] = useState(defaultValue);

    const handleSearch = () => {
        if (onSearch) {
            onSearch(value);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    let defaultPlaceholder = "Input Session ID"
    if (placeholder != null)  {
        defaultPlaceholder = placeholder
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 300 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'black', borderRadius: 1, paddingLeft: 1 }}>
                <IconButton sx={{ color: 'white' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    placeholder={defaultPlaceholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    sx={{ color: 'white', flex: 1 }}
                />
            </Box>
        </Box>
    );
};

export default SessionIDSearch;
