import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import axiosInstance from "./../../../api/axios/axiosInstance";
import CatsTable from "./../../Table/CatsTable/CatsTable";

const HouseholdCats = () => {
    const { householdId } = useParams();
    const [newData, setNewData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdateTable, setIsUpdateTable] = useState(1);

    const url = `/household-cats?household_id=` + householdId;

    const updateTable = (newState) => {
        setIsUpdateTable(newState);
    };

    useEffect(() => {
        setIsLoading(true);
        axiosInstance
            .get(url)
            .then((response) => {
                setIsLoading(false);
                setNewData(response.data.data);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching data:", error);
            });
    }, [isUpdateTable]);

    const disableAllSortAndFiltering = (columns) => {
        return columns.map((col) => {
            col.sorter = undefined;
            col.defaultSortOrder = undefined;
            col.filters = undefined;
            col.filterMode = undefined;
            col.defaultFilteredValue = undefined;
            return col;
        });
    };

    const columnsToHide = ["Household"];
    const hideColumns = (columns) => columns.filter((column) => !columnsToHide.includes(column.title));
    const columnsFn = (columns) => disableAllSortAndFiltering(hideColumns(columns));

    const catsDataTransformed = newData.map((cat) => ({
        ...cat,
        key: cat.id,
        latestEvent: cat.latestEvent,
    }));

    return (
        <Paper>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <CatsTable
                    data={catsDataTransformed}
                    loading={isLoading}
                    pagination={false}
                    columnsFn={columnsFn}
                    updateParentState={updateTable}
                    isupdateTable={isUpdateTable}
                />
            )}
        </Paper>
    );
};

export default HouseholdCats;
