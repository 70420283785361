import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress
} from "@mui/material";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import { getCatVisitsChartSpec } from "./VisitsChartSpec";
import { getCatWeightChartSpec } from "./WeightChartSpec.js"
import { useMassFormatter, useMassUnits } from "../../../utils/context/mass"
import { useCatSingleViewDateRange } from "../../../utils/useCatSingleViewDateRange";
import { useSearchParams } from "../../../utils/useSearchParams";
import { VegaLite } from "react-vega"
import moment from "moment-timezone"
import { useTimezone } from "../../../utils/context/Timezone";

const CatCharts = () => {
  const { catId } = useParams()

  // const [dateRange] = useCatSingleViewDateRange()
  const timezone = useTimezone()
  const [dateRange, setdateRange] = React.useState({
    fromDate: moment()
    .tz(timezone)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .subtract(29, "days"), toDate: moment()
    .tz(timezone)
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
  });

  const massUnit = useMassUnits()
  const { massFormatter } = useMassFormatter();

  const [newData, setNewData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState();

  const shortenRuleDescription = (rule) => {
    if (!rule) return undefined
    let shortenedRule = rule?.replace("increased", "↑")
    shortenedRule = shortenedRule?.replace("decreased", "↓")
    shortenedRule = shortenedRule?.replace("more than", ">")
    shortenedRule = shortenedRule?.replace("less than", "<")
    return shortenedRule
}

  const getWeightsFromQueryData = (data, massFormatter) => {
    if (!data?.data) return []
    const weightEvents = data?.data ?? []

    // have to clone objects because not Object.extensible, and vega wants to insert an id into each datapoint object
    const weightEventsClone =
        weightEvents.map((weightEvent) => {
            return {
                ...weightEvent,
                min: weightEvent.min ? massFormatter(weightEvent.min) : null,
                max: weightEvent.max ? massFormatter(weightEvent.max) : null,
                mean: weightEvent.mean ? massFormatter(weightEvent.mean) : null,
            }
        }) ?? []

    return weightEventsClone
}
const getWeightsPEDTFromQueryData = (data, massFormatter) => {
    if (!data?.allPedt) return []
    const allPEDT = data?.allPedt ?? []

    const weightsPEDT = allPEDT.map((pedt) => {
        const node = pedt;
        const percentageChange = node.metadata?.percentageChange

        return {
            startDate: node.startDate,
            endDate: node.endDate,
            startWeight: massFormatter(node.metadata?.startWeight),
            endWeight: massFormatter(node.metadata?.endWeight),
            description: node.rule.description,
            shortDescription: shortenRuleDescription(node.rule.description),
            priority: node.priority,
            period: node.period,
            percentageChange:
                typeof percentageChange === "number"
                    ? percentageChange.toFixed()
                    : percentageChange,
        }
    })
    return weightsPEDT
}

const getVisitsFromQueryData = (data) => {
    if (!data?.data) return []
    const weightEvents = data?.data;
    const visits = weightEvents?.map((event) => {
        // have to de-aggregate because there is no way to manually generate stacked bar charts without summing over multiple datapoints
        return [
            {
                date: event.date,
                visitType: "No Waste",
                total: event.unfilteredCounts?.nonElimination ?? 1,
            },
            {
                date: event.date,
                visitType: "Feces",
                total: event.unfilteredCounts?.defecation ?? 2,
            },
            {
                date: event.date,
                visitType: "Urine",
                total: event.unfilteredCounts?.urination ?? 3,
            },
        ]
    })
    const visitsFlattened = visits?.flat()
    return visitsFlattened
}
const getPEDTRulePriorityAndDescPairs = (data) => {
    const pedtRules = data?.pedtRules ?? []

    return pedtRules.map(({ priority, description }) => [
        priority,
        shortenRuleDescription(description),
    ])
}

  React.useEffect(() => {

      loadChartData();

  }, setNewData)

  function loadChartData() {
      setIsLoading(true);

      axiosInstance.post('/cat-weight-visits', { cat_id: catId,
          from: moment(dateRange.fromDate).format('YYYY-MM-DD'),
          to: moment(dateRange.toDate).format('YYYY-MM-DD')}, {
          headers: {
              "Content-Type": "multipart/form-data",
          }
      }).then((response, error) => {
          if (error) {
            setError(error)
              return;
          }
          setIsLoading(false);
          setNewData(response.data);
          
      }).catch((error)=>{
          setIsLoading(false);
      });
  }

  // const { data, loading, error } = useQuery(CAT_CHART_QUERY, {
  //     variables: {
  //         id: catId,
  //         from: dateRange.fromDate.format("YYYY-MM-DD"),
  //         to: dateRange.toDate.format("YYYY-MM-DD"),
  //     },
  // })
  // useErrorHandler(error)
  if (newData === null) {
      return null
  }
  const weights = getWeightsFromQueryData(newData, massFormatter)
  const weightsPEDT = getWeightsPEDTFromQueryData(newData, massFormatter);

  const visits = getVisitsFromQueryData(newData);


  const pedtRulePriorityAndDescPairs = getPEDTRulePriorityAndDescPairs(newData)
  
  const { aggregatedWeightsLine, pedtResultsLines } = getCatWeightChartSpec({
      weightsPEDT,
      dateRange,
      massUnit,
      pedtRulePriorityAndDescPairs,
  })
  const { aggregatedVisitBars } = getCatVisitsChartSpec({
      dateRange,
  })


  const spec = {
      $schema: "https://vega.github.io/schema/vega-lite/v5.2.0.json",
      padding: 40,
      width: 1200,
      vconcat: [
          {
              width: 1200,
              layer: [pedtResultsLines, aggregatedWeightsLine],
          },
          {
              width: 1200,
              layer: [aggregatedVisitBars],
          },
      ],
      config: {
          concat: {
              spacing: 60,
          },
      },
      resolve: { scale: { color: "independent" } },
  }

  return (
      <>
          {/* <PageContentBlock title="Weight & Visits"> */}
              {isLoading ? (
                  <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                  <VegaLite
                      spec={spec}
                      data={{
                          visits,
                          weights,
                          weightsPEDT,
                      }}
                      actions={false}
                      renderer="svg"
                      className="cat-charts"
                  />
              )}
          {/* </PageContentBlock> */}
      </>
  )
}

export default CatCharts;
