import * as React from "react"
import { useParams } from "react-router-dom"

import axiosInstance from "../../../api/axios/axiosInstance"
import CatFeederTable from "../../Table/CatFeederTable/CatFeederTable";
import LoadingSpinner from "../../LoadingSpinner/component";

const CatFeederAttributes = () => {
    const [newData, setNewData] = React.useState();
    const { machineId } = useParams();
    const [isLoading, setisLoading] = React.useState(true);
    const [isupdateTable, setisupdateTable] = React.useState(2)
    const updateTable = (newState) => {
        setisupdateTable(newState);
      };
    React.useEffect(() => {
        axiosInstance
      .get(
        "/get-cat-feeder-device-list?page="+ 1 +
        "&pageSize=" + 50
        + "&snSuffix=" + machineId,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setNewData(response.data);
        setisLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setisLoading(false)
      });
    }, [isupdateTable, setNewData])


    const disableAllSortAndFiltering = (columns) => {
        return columns.map((col) => {
            col.sorter = undefined
            col.defaultSortOrder = undefined
            col.filters = undefined
            col.filterMode = undefined
            col.defaultFilteredValue = undefined
            return col
        })
    }

    const dataTransformedForTable = newData?.data.map((monitor) => {
        return {
          ...monitor,
          key: monitor.id,
        }
      }) ?? []

    return !isLoading ? (
        <CatFeederTable
            data={dataTransformedForTable}
            loading={isLoading}
            pagination={false}
            // data-testid={tids.machineSingleView.attributesTable}
            columnsFn={disableAllSortAndFiltering}
            updateParentState={updateTable}
            isupdateTable={isupdateTable}
        />
    )
    : (
      <LoadingSpinner />
    );
}

export default CatFeederAttributes
