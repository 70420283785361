import React, { useEffect, useState } from "react";
import {
  Modal,
  CircularProgress,
  Typography,
  Box,
  Button,
  useMediaQuery,
  IconButton,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, useFormik } from "formik";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import axiosInstance from "./../../../../api/axios/axiosInstance";
import LoadCellChart from "./../../../../utils/common/LoadCellChart";
import {
  DateTimePickerField,
  FalseTriggerField,
  TagsField,
  TextareaField,
  WhichCatField,
  InputTextField,
} from "./../../../FormFields";
import shortenGraphId from "../../../../utils/shortenGraphId";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTheme } from "@mui/material/styles";
import { useTimezone } from "../../../../utils/context/Timezone";

const dateTimeFormatString = "YYYY-MM-DD HH:mm:ss";

const validateDate = (date) => {
  if (date && typeof date != "object") {
    date = date.split(".")[0].replace("T", " ");

    const mDate = moment(date, dateTimeFormatString, true);
    if (!mDate.isValid()) return "Date is invalid";
    if (moment().isBefore(mDate)) return "Date must not be in the future";
    return undefined;
  } else {
    date = moment(date).format(dateTimeFormatString);
  }
};

const validateEntryExitTimes = (entry, exit) => {
  if (entry && exit && moment(entry).isSameOrAfter(moment(exit))) {
    return "Entry time must be before Exit time";
  }
  return undefined;
};

const validateEliminationTimes = (start, end) => {
  if (start && end && moment(start).isSameOrAfter(moment(end))) {
    return "Elimination start time must be before Elimination end time";
  }
  return undefined;
};

const EventLabelModal = ({
  eventId,
  submitCallback,
  closeCallback,
  isModalOpen,
  closeModal,
}) => {
  // eventId = "RXZlbnRJbmZvcm1hdGlvbjpmYjBhZTg4ZS02MjM0LTQ4NzQtYTU4MS1mMDM2ZTBjYzJjNjU="
  const [newData, setNewData] = useState(null);
  const [newDataDeployment, setNewDataDeployment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const timezone = useTimezone()
  const urlForLink = `/cat-litter-box/events/${eventId}`;
  const eventIdWithMiddleStrippedOut = shortenGraphId(eventId);
  const urlShortened = `${window.location.origin}/cat-litter-box/events/${eventIdWithMiddleStrippedOut}`;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setIsLoading(true);
        const eventResponse = await axiosInstance.get(
          `/get_event_classification?page=1&session_id=${eventId}`
        );
        const catResponse = await axiosInstance.get(
          `/get_event_cats?session_id=${eventId}`
        );
        setNewData(eventResponse.data.data[0]);
        setNewDataDeployment(catResponse.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEventData();
  }, [eventId]);

  const catId = newData?.labels?.cat?.cat_id ?? "unknown";

  const isCat =
    newData?.labels && typeof newData.labels.isCat === "boolean"
      ? newData.labels.isCat
      : null;

  const currentSavedTags = newData?.labels?.tags ?? []

  const entryTime = newData?.labels?.observedEntry
    ? moment(newData.labels.observedEntry + "Z").tz(timezone).format("YYYY-MM-DD HH:mm:ss").split(" ")[1]
    : "";

  const exitTime = newData?.labels?.observedExit
    ? moment(newData.labels.observedExit + "Z").tz(timezone).format("YYYY-MM-DD HH:mm:ss").split(" ")[1]
    : "";
  const eliminationStartTime = newData?.labels?.observedEliminationStart
    ? moment(newData.labels.observedEliminationStart + "Z").tz(timezone).format("YYYY-MM-DD HH:mm:ss").split(" ")[1]
    : "";
  const eliminationEndTime = newData?.labels?.observedEliminationEnd
    ? moment(newData.labels.observedEliminationEnd + "Z").tz(timezone).format("YYYY-MM-DD HH:mm:ss").split(" ")[1]
    : "";

  const initialValues = {
    observedEntry: entryTime,
    observedExit: exitTime,
    observedEliminationStart: eliminationStartTime,
    observedEliminationEnd: eliminationEndTime,
    // catChoice: newData?.labels?.isCat ? newData.labels.cat.cat_id : "unknown",
    catChoice: isCat === null ? "unknown" : isCat === false ? "notACat" : catId,
    tags: currentSavedTags,
    description: newData?.labels?.description || "",
    falseTrigger: newData?.labels?.falseTrigger ? "true" : "false",
  };

  const onValidate = (values) => {
    const errors = {};
    const dateErrors = [
      "observedEntry",
      "observedExit",
      "observedEliminationStart",
      "observedEliminationEnd",
    ].reduce((acc, key) => {
      const error = validateDate(values[key]);
      if (error) acc[key] = error;
      return acc;
    }, {});

    const entryExitError = validateEntryExitTimes(
      values.observedEntry,
      values.observedExit
    );
    const eliminationError = validateEliminationTimes(
      values.observedEliminationStart,
      values.observedEliminationEnd
    );

    return {
      ...errors,
      ...dateErrors,
      ...(entryExitError && {
        observedEntry: entryExitError,
        observedExit: entryExitError,
      }),
      ...(eliminationError && {
        observedEliminationStart: eliminationError,
        observedEliminationEnd: eliminationError,
      }),
    };
  };

  const onSubmit = async (values) => {
    
    const createDateSubmitValue = (date) => {
      const startTime = moment(newData.session_start_date).format('YYYY-MM-DD')
      if (date !== "") {
        if (typeof date == "object") {
          return moment(startTime + " " + date.$d).tz(timezone, true).utc().format();
        } else {
          return moment(startTime + " " +date).tz(timezone, true).utc().format();
        }
      } else {
        return null;
      }
    };

    const falseTrigger = values.falseTrigger === "true";
    const isCat = values.catChoice !== "notACat";
    const catId = !isCat ? null : values.catChoice;
    const description = values.description === "" ? null : values.description;

    const tagsToAdd = values.tags.filter(
      (tag) => !currentSavedTags.includes(tag)
    );
    const tagsToRemove = currentSavedTags.filter(
      (tag) => !values.tags.includes(tag)
    );

    const mergedTags = [...tagsToAdd, ...currentSavedTags];
    const finalTags = mergedTags.filter((tag) => !tagsToRemove.includes(tag));
    const data = {
      session_id: eventId,
      isCat,
      falseTrigger,
      cat_id: catId,
      observedEntry: createDateSubmitValue(values.observedEntry),
      observedExit: createDateSubmitValue(values.observedExit),
      observedEliminationStart: createDateSubmitValue(
        values.observedEliminationStart
      ),
      observedEliminationEnd: createDateSubmitValue(
        values.observedEliminationEnd
      ),
      description,
      device_id: newData.machine_id,
      session_start_date: moment(newData.session_start_date)
        .tz(timezone, false)
        .utc()
        .format(),
      eliminationType: null,
      tags: finalTags,
    };
    // console.log("label data", data)

    try {
      setIsLoading(true);
      await axiosInstance.post("/telemetry-label", data);
      setIsLoading(false);
      closeModal();
    } catch (error) {
      console.error("Error submitting data", error);
    }
  };

  const [textToCopy, setTextToCopy] = useState(eventId); // The text you want to copy
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  return (
    <Modal open onClose={closeModal}>
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "90%" : "50%",
          maxWidth: "600px",
          margin: "auto",
          marginTop: isMobile ? "10%" : "5%",
          padding: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
         <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ wordBreak: 'break-word' }}>
        <b>Edit event</b> - <span>{eventId}</span>
          <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
            <ContentCopyIcon style={{ color: "#096dd9", cursor: "pointer" }} />
          </CopyToClipboard>
        </Typography>

        {copyStatus && <p>Text copied to clipboard!</p>}

        <div style={{ width: "2.5rem" }}></div>

        <Link to={urlForLink} target="_blank">
          {urlShortened}
        </Link>
        
        {isLoading ? (
          <Box textAlign="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            // validate={onValidate}
          >
            <Form>
              <FalseTriggerField />
              <Box display="flex" mb={2}>
                <InputTextField
                  name="observedEntry"
                  title="Entry"
                  style={{ width: "217px" }}
                />
                <Box ml={2} />
                <InputTextField
                  name="observedExit"
                  title="Exit"
                  style={{ width: "217px" }}
                />
                {/* <DateTimePickerField
                  title="Entry"
                  name="observedEntry"
                />
                <Box ml={2} />
                <DateTimePickerField
                  title="Exit"
                  name="observedExit"
                /> */}
              </Box>
              <Box display="flex" mb={2}>
                <InputTextField
                  name="observedEliminationStart"
                  title="Elimination Start"
                  style={{ width: "217px" }}
                />
                <Box ml={2} />
                <InputTextField
                  name="observedEliminationEnd"
                  title="Elimination End"
                  style={{ width: "217px" }}
                />
                {/* <DateTimePickerField
                  title="Elimination Start"
                  name="observedEliminationStart"
                />
                <Box ml={2} />
                <DateTimePickerField
                  title="Elimination End"
                  name="observedEliminationEnd"
                /> */}
              </Box>
              <WhichCatField catOptions={newDataDeployment?.data || []} />
              <TagsField />
              <TextareaField name="description" title="Extra Information" />
              <Box mt={2}>
                <Typography variant="body1">Load cell data</Typography>
                <LoadCellChart
                  eventId={eventId}
                  startTime={newData?.session_start_date}
                />
              </Box>
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                style={{ marginTop: "60px" }}
              >
                <Button variant="contained" color="primary" type="submit">
                  Save changes
                </Button>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
              </Box>
            </Form>
          </Formik>
        )}
      </Box>
    </Modal>
  );
};

export default EventLabelModal;
