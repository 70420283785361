import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from '@mui/material';
import { faArrowUp, faCheck, faXmark, faRotateRight, faQuestion } from "@fortawesome/free-solid-svg-icons";

const TrainedStateIcon = ({ trainedState, disableTooltip }) => {
    let icon = null
    if (trainedState === "trained") {
        icon = (
            <FontAwesomeIcon
                title={trainedState}
                icon={faCheck}
                size="lg"
                color="#77dd77"
            /> 
        )
    } else if (trainedState === "training") {
        icon = (
            <FontAwesomeIcon
                title={trainedState}
                icon={faArrowUp}
                size="lg"
                color="#FFA26B"
            />
        )
    } else if (trainedState === "untrained") {
        icon = (
            <FontAwesomeIcon
                title={trainedState}
                icon={faXmark}
                size="lg"
                color="#FF647C"
            />
        )
    } else if (trainedState === "retraining") {
        icon = (
            <FontAwesomeIcon
                title={trainedState}
                icon={faRotateRight}
                size="lg"
                color="#0084F4"
            />
        )
    } else if (trainedState === "unknown") {
        icon = (
            <FontAwesomeIcon
                title={trainedState}
                icon={faQuestion}
                size="lg"
                color="#ccc"
            />
        )
    } else {
        return null
    }
    const startCased = (string) => string[0].toUpperCase() + string.slice(1)

    if (disableTooltip) return <div style={{ width: "20px" }}>{icon}</div>

    return (
        <Tooltip title={startCased(trainedState)}>
            <div style={{ width: "20px" }}>{icon}</div>
        </Tooltip>
    )
}

export default TrainedStateIcon
