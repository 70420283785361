import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import axiosInstance from "../../../api/axios/axiosInstance";
import Page from "../../../utils/Page";
import CatFeederEventKeyInfo from "./CatFeederEventKeyInfo";
import CatFeederEventLoadCellChart from "../../../utils/eventUtills/CatFeederEventLoadCellChart";
import CatFeederLabelsAndClassifications from "../../../utils/eventUtills/CatFeederLabelsAndClassifications";

export const CatFeederEventView = ({ eventId }) => {
  const [newData, setNewData] = React.useState();
  
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    eventLoad();
    
  }, [eventId, setNewData]);

  const eventLoad = () => {
    let url = "/get-cat-feeder-events-home"
    
    axiosInstance.post(url, {
      count: 50,
      snSuffixSessionID: eventId,
      page: 1
    },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response, error) => {
        setIsLoading(false);
        setNewData(response.data);
        
      }).catch((error) => {
        setIsLoading(false);
        setError(error);
      })
      
  };




  return (
    <Page
      title="Event"
      breadcrumbs={[
        {
          title: "Household",
          link: `/households/${newData?.household_id}`,
        },
        {
          title: "Monitor",
          link: `/monitors/${newData?.machine_id}`,
        },
        { title: eventId },
      ]}
    >
      {!isLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CatFeederEventKeyInfo eventId={eventId} serialNumber={newData?.data[0]?.device_serial_nos} />
          </Grid>
          <Grid item xs={12}>
            <CatFeederEventLoadCellChart
              eventId={eventId}
              sessionStartTime={newData?.session_start_date}
              cloud_video_file_url = {newData?.data[0]?.cloud_video_file_url}
            />
          </Grid>
          
          <Grid item xs={12}>
            
            <CatFeederLabelsAndClassifications
        eventId={eventId ?? {}}
        startTime={newData?.data[0]?.startTime ?? {}}
      />
          </Grid>
          
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Page>
  );
};

export const CatFeederEventSingleView = () => {
  const { eventId } = useParams();
  return <CatFeederEventView eventId={eventId} />;
};

const CatFeederEventSingleViewEB = () => (
    <CatFeederEventSingleView />
);

export default CatFeederEventSingleViewEB;
