import React from "react"
import { Box, Grid } from "@mui/material"

const getStrength = (wifiRssi) => {
    if (!Number.isInteger(wifiRssi)) return null
    if (wifiRssi > -60) return "excellent"
    if (wifiRssi > -70) return "good"
    if (wifiRssi > -90) return "poor"
    return null
}

const underscoreToStartCasedWithSpaces = (underscoreString) => {
    return (
        underscoreString
            ?.split("_")
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(" ") ?? null
    )
}

const WifiIcon = ({ strength }) => {
    if (strength === null) return null

    return (
        <img
            width="24px"
            src={`/wifi_${strength}.svg`}
            alt={`${strength} wifi signal strength`}
        />
    )
}

const TableCellWifiRssi = ({ wifiRssi }) => {
    const strength = getStrength(wifiRssi)
    if (strength === null) return "None"

    const strengthReadable = underscoreToStartCasedWithSpaces(strength)

    return (
        <Box style={{ justifyContent: "space-between", width: "100%" }}>
            <span>{wifiRssi}</span>
            <span style={{ width: "32px" }}>{strengthReadable ?? "None"}</span>
            <WifiIcon strength={strength} />
        </Box>
    )
}

export default TableCellWifiRssi
