import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './Header.css';
import logo from './../../assets/images/purniaLogo.png';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const name = localStorage.getItem("name")

  const handleClick = (event) => {
    if (anchorEl!=null){
      setAnchorEl(null)
    }
    else{
    setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    // handleClose();
    setAnchorEl(null);
    localStorage.removeItem("access_token")
    localStorage.removeItem("id_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("csrf_buster")
    localStorage.removeItem("enable_read_write")
    localStorage.removeItem("frankenDevicesUsers")
    localStorage.removeItem("username")
    navigate('/');
  };

  return (
    <header className="header">
      <div className="header-logo">
        <a href="/home">
        <img src={logo} alt="Purina Logo" />
        </a>
      </div>
      <div className="header-title">
        <span>Petivity</span>
      </div>
      <div className="header-username" onClick={handleClick}>
        <span>{name}</span>
        {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
