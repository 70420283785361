import React, { useState } from "react";
import {
  Popover,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

const FilterPopover = ({ filterOptions, anchorEl, handleClose, handleApply }) => {
  const [filters, setFilters] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    // filters.set({name: value})
    // Update the URL search parameters
    searchParams.set(name, value);
    // setSearchParams(searchParams);
  };

  const handleReset = (name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: "",
    }));

    // Remove the parameter from the URL search parameters
    searchParams.delete(name);
    setSearchParams(searchParams);
    handleClose()
  };

  const renderFilterFields = () => {
    const groupedOptions = [];

    // Group the filter options in pairs
    for (let i = 0; i < filterOptions.length; i += 2) {
      groupedOptions.push(filterOptions.slice(i, i + 2));
    }

    return groupedOptions.map((optionPair, index) => (
      <React.Fragment key={index}>
        <Grid container item xs={12} spacing={2}>
          {optionPair.map((option) => (
            <Grid item xs={6} key={option.name}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">{option.label}</Typography>
                <Button onClick={() => handleReset(option.name)}>Reset</Button>
              </Grid>
              <FormControl fullWidth>
                <Select
                  value={filters[option.name] || ""}
                  onChange={handleChange}
                  name={option.name}
                >
                  {option.values.map((value) => (
                    <MenuItem key={value.value} value={value.value}>
                      {value.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
        {index < groupedOptions.length - 1 && (
          <Grid item xs={12}>
            <Divider style={{ margin: "16px 0", backgroundColor: "rgba(0, 0, 0, 0.87)" }} />
          </Grid>
        )}
      </React.Fragment>
    ));
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Grid container spacing={2} style={{ padding: 16, width: 400 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Filter by</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: "rgba(0, 0, 0, 0.87)" }} />
        </Grid>
        <br />
        {renderFilterFields()}
        <Grid item xs={12}>
          <Divider style={{ margin: "16px 0", backgroundColor: "rgba(0, 0, 0, 0.87)" }} />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            onClick={() => handleApply(filters)}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default FilterPopover;
