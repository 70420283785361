import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "bootstrap/dist/css/bootstrap.min.css";
import "./IndividualCard.css";

const IndividualCard = ({ title, value }) => {
  return (
    <Card className="individual-card">
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="subtitle2" color="textSecondary" className="stat-title">
              {title}
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 4 }} />
            </Typography>
          </Box>
          <Typography variant="h5" component="div" className="stat-value">
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default IndividualCard;
