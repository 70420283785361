import React, { useEffect, useState } from "react";
import {
  Modal,
  CircularProgress,
  Typography,
  Box,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";
import moment from "moment-timezone";
import axiosInstance from "../../../../api/axios/axiosInstance";
import DogBowlLoadCellChart from "../../../../utils/common/DogBowlLoadCellChart";
import {
  FalseTriggerField,
  DogBowlTagsField,
  TextareaField,
  NotaDogField,
  InputTextField,
} from "../../../FormFields";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const dateTimeFormatString = "YYYY-MM-DD HH:mm:ss";

const validateDate = (date) => {
  if (!date) return "Date is required";
  const mDate = moment(date, dateTimeFormatString, true);
  console.log("mDatemDatemDate", mDate);
  if (!mDate.isValid()) return "Date is invalid";
  if (moment().isBefore(mDate)) return "Date must not be in the future";
  return undefined;
};

const validateEntryExitTimes = (entry, exit) => {
  if (entry && exit && moment(entry).isSameOrAfter(moment(exit))) {
    return "Entry time must be before Exit time";
  }
  return undefined;
};

const validateEliminationTimes = (start, end) => {
  if (start && end && moment(start).isSameOrAfter(moment(end))) {
    return "Elimination start time must be before Elimination end time";
  }
  return undefined;
};

const DogBowlEventLabelModal = ({
  eventId,
  dogId,
  startTime,
  videoStartTime,
  machineId,
  petName,
  submitCallback,
  closeCallback,
  isModalOpen,
  closeModal,
}) => {
  // const startDate = startTime.split(" ")
  const [newData, setNewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setIsLoading(true);
        const eventResponse = await axiosInstance.get(
          `/get-dog-bowl-label?event_id=` +
            eventId +
            `&session_start_date=` +
            startTime
        );

        setNewData(eventResponse.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEventData();
  }, [eventId]);

  const falseTrigger =
    newData?.falseTrigger && typeof newData.falseTrigger === "boolean"
      ? newData.falseTrigger.toString()
      : "false";

  const notDog =
    newData?.notDog && typeof newData.notDog === "boolean"
      ? newData.notDog.toString()
      : "false";

  const description =
    newData?.description && typeof newData.description === "string"
      ? newData.description
      : "";

  const TimeFormatString = "HH:mm:ss";

  const startinteraction =
    (newData?.startinteraction && newData.startinteraction) ??
    // moment(newData.startinteraction + "Z")
    //     .tz(timezone)
    //     .format(TimeFormatString))
    "";
  const endinteraction =
    (newData?.endinteraction && newData.endinteraction) ??
    // moment(newData.endinteraction + "Z")
    //     .tz(timezone)
    //     .format(TimeFormatString))
    "";
  const consumption =
    newData?.consumption && typeof newData.consumption === "string"
      ? newData.consumption
      : "";

  const currentSavedTags = newData?.tags ?? [];

  const initialValues = {
    startinteraction: startinteraction,
    endinteraction: endinteraction,
    tags: currentSavedTags,
    description: description,
    falseTrigger: falseTrigger,
    consumption: consumption,
    notDog: notDog,
  };

  const onValidate = (values) => {
    const errors = {};
    const dateErrors = [
      "observedEntry",
      "observedExit",
      "observedEliminationStart",
      "observedEliminationEnd",
    ].reduce((acc, key) => {
      const error = validateDate(values[key]);
      if (error) acc[key] = error;
      return acc;
    }, {});

    const entryExitError = validateEntryExitTimes(
      values.observedEntry,
      values.observedExit
    );
    const eliminationError = validateEliminationTimes(
      values.observedEliminationStart,
      values.observedEliminationEnd
    );

    return {
      ...errors,
      ...dateErrors,
      ...(entryExitError && {
        observedEntry: entryExitError,
        observedExit: entryExitError,
      }),
      ...(eliminationError && {
        observedEliminationStart: eliminationError,
        observedEliminationEnd: eliminationError,
      }),
    };
  };

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = `${dateFormat} ${timeFormat}`;

  const onSubmit = async (values) => {
    let startinteraction = null;
    let endinteraction = null;
    if (typeof values.startinteraction === "string") {
      startinteraction = values.startinteraction;
    }

    if (typeof values.endinteraction === "string") {
      endinteraction = values.endinteraction;
    }
    const falseTrigger = values.falseTrigger === "true";
    const notDog = values.notDog === "true";

    const consumption = values.consumption === "" ? null : values.consumption;

    const description = values.description === "" ? null : values.description;

    const tagsToAdd = values.tags.filter(
      (tag) => !currentSavedTags.includes(tag)
    );
    const tagsToRemove = currentSavedTags.filter(
      (tag) => !values.tags.includes(tag)
    );

    var merged_tags = [...tagsToAdd, ...currentSavedTags];
    var final_tags = [];
    final_tags = merged_tags.filter((val) => !tagsToRemove.includes(val));

    const data = {
      session_id: eventId,
      falseTrigger: falseTrigger,
      description: description ?? null,
      startinteraction: startinteraction ?? null,
      endinteraction: endinteraction ?? null,
      consumption: consumption ?? null,
      device_id: machineId,
      session_start_date: startTime,
      dogId: dogId,
      notDog: notDog,
      tags: final_tags,
      petName: petName,
      video_start_time: videoStartTime,
    };
   
    try {
      setIsLoading(true);
      await axiosInstance.post("/dog-bowl-label", data);
      setIsLoading(false);
      closeModal();
    } catch (error) {
      console.error("Error submitting data", error);
    }
  };

  const [textToCopy, setTextToCopy] = useState(eventId); 
  const [copyStatus, setCopyStatus] = useState(false); 

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000);
  };

  return (
    <Modal open onClose={closeModal}>
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "90%" : "50%",
          maxWidth: "600px",
          margin: "auto",
          marginTop: isMobile ? "10%" : "5%",
          padding: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500",
          }}
        >
            <CloseIcon />
        </IconButton>

          <Typography variant="h6" component="div" sx={{ wordBreak: 'break-word' }}>
          <b>Edit event</b> - <span>{eventId}</span> 
          <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
            <ContentCopyIcon style={{ color: "#096dd9", cursor: "pointer" }} />
          </CopyToClipboard>
        </Typography>

        <div>{copyStatus && <p>Text copied to clipboard!</p>}</div>

        {isLoading ? (
          <Box textAlign="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <Form>
              <NotaDogField />
              <FalseTriggerField />
              <Box display="flex" mb={2}>
              
                <InputTextField
                  name="startinteraction"
                  title="Start Interaction"
                />
                <Box ml={2} />
                <InputTextField name="endinteraction" title="End Interaction" />
              </Box>

              <DogBowlTagsField />
              <TextareaField name="consumption" title="Consumption" />
              <TextareaField name="description" title="Extra Information" />
              <Box mt={2}>
                <Typography variant="body1">Load cell data</Typography>
                <DogBowlLoadCellChart
                  eventId={eventId}
                  startTime={newData?.session_start_date}
                />
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" type="submit">
                  Save changes
                </Button>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
              </Box>
            </Form>
          </Formik>
        )}
      </Box>
    </Modal>
  );
};

export default DogBowlEventLabelModal;
