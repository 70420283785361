import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import axiosInstance from "./../../../api/axios/axiosInstance";
import Page from "./../../../utils/Page";
import EventKeyInfo from "./EventKeyInfo";
import EventLoadCellChart from "../../../utils/eventUtills/EventLoadCellChart";
import ExtraInformation from "../../../utils/eventUtills/ExtraInformation";
import NodeLabelsAndClassifications from "../../../utils/eventUtills/NodeLabelsAndClassifications";
import RawClassifications from "../../../utils/eventUtills/RawClassifications";

export const EventView = ({ eventId }) => {
  const [newData, setNewData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    dataLoad();
  }, [eventId]);

  const dataLoad = () => {
    const url = `/get_event_classification?page=1&session_id=${eventId}`;
    axiosInstance
      .get(url)
      .then((response) => {
        const item = response.data.data[0];
        setNewData(item);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Page
      title="Event"
      breadcrumbs={[
        {
          title: "Household",
          link: `/households/${newData?.household_id}`,
        },
        {
          title: "Monitor",
          link: `/monitors/${newData?.machine_id}`,
        },
        { title: eventId },
      ]}
    >
      {!isLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EventKeyInfo eventId={eventId} serialNumber={newData?.machine_id} />
          </Grid>
          <Grid item xs={12}>
            <EventLoadCellChart
              eventId={eventId}
              sessionStartTime={newData?.session_start_date}
            />
          </Grid>
          <Grid item xs={12}>

            <ExtraInformation description={newData.labels?.description} />
          </Grid>
          <Grid item xs={12}>
            <NodeLabelsAndClassifications {...newData} refetch={dataLoad} />
          </Grid>
          <Grid item xs={12}>
            <RawClassifications rawClassifications={newData.rawClassifications} />
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Page>
  );
};

export const EventSingleView = () => {
  const { eventId } = useParams();
  return <EventView eventId={eventId} />;
};

const EventSingleViewEB = () => (
    <EventSingleView />
);

export default EventSingleViewEB;
