export const getCatVisitsChartSpec = ({ dateRange }) => {
    const dateDomain = dateRange
        ? [
              dateRange.fromDate.format("YYYY-MM-DD"),
              dateRange.toDate
                  .clone()
                  .add(1, "day")
                  .format("YYYY-MM-DD"),
          ]
        : null
    const lengthOfDateRangeInDays = dateRange.toDate.diff(
        dateRange.fromDate,
        "days",
    )
    const tickCountInterval = lengthOfDateRangeInDays > 60 ? "week" : "day"

    const aggregatedVisitBars = {
        mark: {
            type: "bar",
        },
        data: {
            name: "visits",
        },
        transform: [
            {
                calculate:
                    "indexof(['urination', 'defecation', 'nonElimination'], datum.visitType)",
                as: "order",
            },
        ],
        encoding: {
            x: {
                field: "date",
                timeUnit: "utcyearmonthdate",
                axis: {
                    title: "Date",
                    titleFontSize: 16,
                    labelExpr:
                        "[timeFormat(datum.value, '%e %b'), timeFormat(datum.value, '%e%m') == 101 ? timeFormat(datum.value, '%Y') : '', ]", // not great to only show year on 1st jan, but better than nothing
                    tickCount: { interval: tickCountInterval, step: 1 }, // change to month dateRange has length: days > 60 (2 months)
                    labelFlush: false,
                    labelFontSize: 12,
                },
            },
            y: {
                field: "total",
                aggregate: "sum",
                type: "quantitative",
                scale: { zero: false, domain: { unionWith: [0, 4] } },
                axis: { title: "Visits", titleFontSize: 16, tickMinStep: 1 },
            },
            color: {
                field: "visitType",
                scale: {
                    domain: ["Urine", "Feces", "No Waste"],
                    range: ["#FFCF5C", "#7E500B", "#AEB5BD"],
                },
                legend: {
                    title: "Visit Type",
                    titleFontSize: 14,
                    labelFontSize: 14,
                    symbolStrokeWidth: 3,
                },
            },
            order: { field: "order", type: "ordinal" },
            tooltip: [
                {
                    field: "date",
                    type: "temporal",
                    title: "Date",
                },
                {
                    field: "total",
                    title: "Total",
                },
                {
                    field: "visitType",
                    title: "Type",
                },
            ],
        },
    }

    if (dateDomain) {
        aggregatedVisitBars.encoding.x.scale = { domain: dateDomain }
    }

    return {
        aggregatedVisitBars,
    }
}
