import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import PageContentBlock from "./../utils/PageContentBlock";
import OnboardDevices from "./../components/Modules/HouseholdSingleView/OnboardDevices";

 const OnboardDeviceByHousehold = ({ household_id, fetchData, machine }) => {
  const [edit, setEdit] = useState(false);
  return (
    <PageContentBlock>
      {edit ? (
        <OnboardDevices
          household_id={household_id}
          submitCallback={() => {
            fetchData();
            setEdit(false);
          }}
          closeCallback={() => {
            setEdit(false);
          }}
        />
      ) : null}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" color="primary" onClick={() => setEdit(true)}>
          <div>Add Monitors</div>
        </Button>
      </Box>
    </PageContentBlock>
  );
};
export default OnboardDeviceByHousehold;