import redirectToLogin from '../../utils/redirectToLogin';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://pui-rde-petivity.com/api', // Replace with your API base URL
  // baseURL: 'http://localhost:5000/api'
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {

    // Modify the request config here (e.g., add headers, authentication tokens)
    // const accessToken = JSON.parse(localStorage.getItem("token"));

    // ** If token is present add it to request's Authorization Header
    // if (accessToken) {

    if (config.headers) config.headers.Authorization = 'csrf_buster=' + localStorage.getItem('csrf_buster') + '; access_token=' + localStorage.getItem('access_token') + '; id_token=' + localStorage.getItem('id_token') + '; refresh_token=' + localStorage.getItem('refresh_token')
    // }
    return config;
  },
  (error) => {
    // Handle request errors here
    // throw new Error(error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., parse, transform)
    if (response?.data?.message === "Authentication Failed") {
      redirectToLogin()
    } else {
      // if (Object.keys(response?.data?.cookies).length > 0) {
      //   localStorage.setItem("id_token", response?.data?.cookies.id_token);
      //   localStorage.setItem("access_token", response?.data?.cookies.access_token);
      //   localStorage.setItem("access_token", response?.data?.cookies.access_token);
      //   localStorage.setItem("refresh_token", response?.data?.cookies.refresh_token)
      //   // localStorage.setItem("csrf_buster",query.getAll("csrf_buster")[0])
      // }
      // How to check an object is empty or not?
      return response;
    }
  },
  (error) => {
    // Handle response errors here
    // throw new Error(error)
    // useErrorHandler(error.message)
    const ApiError = `[API error]: Message: ${error.message}`
    // antdMessage.config({
    //   duration: 15,
    //   top: 60,
    // })
    // antdMessage.error({
    //   content: ApiError,
    //   key: ApiError,
    //   onClick: () => antdMessage.destroy(ApiError),
    // })
    return Promise.reject(error);
  }
);

export default axiosInstance;;