import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Paper,
  CircularProgress,
  styled,
} from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import HouseholdAttributes from "./HouseholdAttributes";
import HouseholdCats from "./HouseholdCats";
// import HouseholdEvents from "./HouseholdEvents";
import HouseholdEventTotals from "./HouseholdEventTotals";
import HouseholdMachines from "./HouseholdMachines";
import HouseholdUsers from "./HouseholdUsers";
import HouseholdNotifications from "./HouseholdNotifications";
// import SettingsTable from "./SettingsTable";
import OnboardDeviceGroupByHousehold from "./OnboardDeviceGroupByHousehold";
import OnboardDeviceByHousehold from "./../../../utils/OnboardDeviceByHousehold";
import HouseholdEvents from "./HouseholdEvents";
import HouseholdMachinesGroup from "./HouseholdMachinesGroup";


import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const InfoBox = ({ title, value }) => (
  <Box textAlign="center">
    <Typography variant="body2" color="textSecondary">
      {title}
    </Typography>
    <Typography variant="h6" component="div">
      {value}
    </Typography>
  </Box>
);

const Household = () => {
  const { householdId } = useParams();
  const navigate = useNavigate();
  const [newData, setNewData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const url = `/household-attributes?household_id=${householdId}`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!newData) {
    return (
      <Typography variant="h6" color="error">
        This Pet does not exist. Please return to the Pet list.
      </Typography>
    );
  }

  return (
    <Box p={3}>
      <Button
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          sx={{
            color: "black",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
      >
        Go Back
      </Button>
      <Grid container spacing={3} justifyContent="center">
        
        <Grid item xs={12}>
          <Typography variant="h5">Attributes</Typography>
          <HouseholdAttributes />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Event Totals</Typography>
          <HouseholdEventTotals />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Users</Typography>
          <HouseholdUsers />
        </Grid>
        <Grid item xs={12}>
          <OnboardDeviceByHousehold
            household_id={householdId}
            fetchData={fetchData}
          />
          <Typography variant="h5">Monitors</Typography>
          <HouseholdMachines fetchData={fetchData} />
        </Grid>
        <Grid item xs={12}>
          <OnboardDeviceGroupByHousehold
            household_id={householdId}
            fetchData={fetchData}
          />
          <Typography variant="h5">Monitor Groups</Typography>
          
            <HouseholdMachinesGroup />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Cats</Typography>
          <HouseholdCats />
        </Grid> 
         <Grid item xs={12}>
          <Typography variant="h5">Notifications</Typography>
          <HouseholdNotifications />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Events</Typography>
          <HouseholdEvents />
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="h5">Settings</Typography> */}
          {/* <SettingsTable householdId={householdId} testId={tids.householdSingleView.settingsTable} /> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default Household;
