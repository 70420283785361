import React from "react";
import { Tooltip, Typography, Box } from "@mui/material";

const GenericCountOutOfTotal = ({ count, outOfTotal, tooltipText = "" }) => {
    if (
        count === null ||
        count === undefined ||
        outOfTotal === null ||
        outOfTotal === undefined
    ) return "-";

    return (
        <Tooltip title={tooltipText}>
            <Box display="flex">
                <Typography variant="body2">{`${count} / ${outOfTotal}`}</Typography>
            </Box>
        </Tooltip>
    );
}

export default GenericCountOutOfTotal;
