import React from "react"
import { Link } from "react-router-dom"

const TableCellCatName = ({ name, url = false }) => {
    if (!name) {
        return "-"
    }
    if (url) {
        return <Link to={url}>{name}</Link>
    }
    return <span className="ant-typography">{name}</span>
}

export default TableCellCatName
