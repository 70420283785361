import React, { useState, useEffect } from "react";
import {
  Edit as EditIcon,
  AddCircle as AddCircleIcon,
  Refresh as RefreshIcon,
  Tag,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { ErrorBoundary, useErrorHandler } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";

import DeploymentModalCreateOrUpdate from "./../../../utils/machineUtills/DeploymentModalCreateOrUpdate";
import { Time } from "./../../../utils/context/Timezone";
import {
  DeploymentModalMachineProvider,
  useDeploymentModalMachine,
} from "./../../../utils/machineUtills/deploymentModalMachine";
import LoadingSpinner from "../../LoadingSpinner/component";

import axiosInstance from "./../../../api/axios/axiosInstance";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#000",
  color: "#fff",
  borderRadius: "50px",
  padding: "5px 15px",
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "#333",
  },
  "& .MuiButton-startIcon": {
    marginRight: "5px",
  },
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  color: "#000",
  textTransform: "none",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  "& .MuiButton-startIcon": {
    marginRight: "5px",
  },
}));


const MachineDeploymentsBase = ({ machineId }) => {
  const [state, send] = useDeploymentModalMachine();
  const [newData, setNewData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [isupdateTable, setisUpdate] = React.useState(1);

  const updateTable = (newState) => {
    setisUpdate(newState);
};

  useEffect(() => {
    loadData();
  }, [isupdateTable]);

  const loadData = () => {
    setIsLoading(true);
    axiosInstance
      .post(
        "/machine-deployments",
        { machine_id: machineId },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data?.data || {});
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  
  const columns = [
    {
      title: "From",
      dataIndex: "startTime",
      render: (d) => <Time>{d}</Time>,
      width: 120,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (d) =>
        d ? (
          d
        ) : (
          <Typography variant="body2" color="textSecondary">
            No description
          </Typography>
        ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      render: (tags) =>
        !tags ? (
          <Typography variant="body2" color="textSecondary">
            No tags
          </Typography>
        ) : (
          tags.map((tag) => (
            <Box component="span" key={tag} sx={{ mr: 1 }}>
              <Tooltip title={tag}>
                <Tag label={tag} color="primary" />
              </Tooltip>
            </Box>
          ))
        ),
    },
    {
      title: "Cats",
      dataIndex: "cats",
      width: 150,
      render: (cats) =>
        !cats || cats.length === 0 ? (
          <Typography variant="body2" color="textSecondary">
            No cats
          </Typography>
        ) : (
          <Box component="ul" sx={{ pl: 2, m: 0 }}>
            {cats.map((cat) => (
              <Box component="li" key={cat.id}>
                {cat.name}
              </Box>
            ))}
          </Box>
        ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 1,
      render: (id, deployment) => (
        <IconButton onClick={() => send({"type": "UPDATE"}, { deployment })}>
          <EditIcon />
        </IconButton>
        
      ),
    },
  ];

  const updateParentState = (value) => {
    console.log("Parent state updated with value: ", value);
};

  return !loading ?(
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" fontWeight="bold">Deployments</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <HeaderButton
            onClick={loadData}
            startIcon={loading ? <CircularProgress size={20} /> : <RefreshIcon />}
            sx={{ mr: 2 }}
            disabled={loading}
          >
            Reload
          </HeaderButton>
          {/* <StyledButton
            startIcon={<AddCircleIcon />}
            onClick={() => send("CREATE")}
          >
            Add Deployment
          </StyledButton> */}
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="deployments table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.title}>
                  <Typography variant="body1" fontWeight="bold">
                    {column.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {newData ? (
              <TableRow key={newData.serial_no}>
                {columns.map((column) => (
                  <TableCell key={column.dataIndex}>
                    {column.render
                      ? column.render(newData[column.dataIndex], newData)
                      : newData[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {state.matches("opened") && (
        <DeploymentModalCreateOrUpdate
          machineId={machineId}
          catsInMostRecentDeployment={newData?.cats ?? []}
          initialValues = {newData}
          updateParentState = {setisUpdate}
          updateTable={updateTable}
        />
      )}
    </>
  ): (
    <LoadingSpinner />
)
};

const MachineDeploymentsEB = (props) => (
  <DeploymentModalMachineProvider>
    <MachineDeploymentsBase {...props} />
  </DeploymentModalMachineProvider>
);

const MachineDeployments = () => {
  const { machineId } = useParams();

  return <MachineDeploymentsEB machineId={machineId} />;
};

export default MachineDeployments;
