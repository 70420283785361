import React from "react";
import axiosInstance from "../../api/axios/axiosInstance";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Typography } from "@mui/material";

const CatActiveStateModal = ({ isModalOpen, closeModal, cat }) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const updateCatAPI = (data) => {
        axiosInstance.post('/update-cat', data)
            .then((response) => {
                setIsLoading(false);
                if (response.data.message === 'Success') {
                    closeModal();
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const catIsInactive = !cat.active;

    const updateCat = async () => {
        setIsLoading(true);
        try {
            let household_id = cat.id;
            let cat_id = cat.id;
            let dob = cat.dob;

            if (cat.household_id) {
                household_id = cat.household_id;
            }

            if (cat.cat_id) {
                cat_id = cat.cat_id;
            }

            if (dob !== null) {
                dob = dob.split("T")[0];
            }

            const bodyScore = cat.bodyConditionScore?.toString() || '0';
            const bodySScore = cat.reproductiveStatusAlteredAtBirth?.toString() || '0';
            const reproductiveStatus = cat.reproductiveStatus === "" ? null : cat.reproductiveStatus;

            const data = {
                "household_id": household_id,
                "cat_id": cat_id,
                "bodyConditionScore": bodyScore,
                "birth_date": dob,
                "gender": cat.gender,
                "name": cat.name,
                "active_ind": catIsInactive,
                "reproductiveStatus": reproductiveStatus,
                "reproductiveStatusAlteredAtBirth": bodySScore
            };
            console.log("dataaaaaaaaaaaaaaa", data)

            updateCatAPI(data);
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>Modify Cat Active State</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Typography>
                        {catIsInactive ? "Not active" : "Yes"}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeModal} color="primary">
                    {catIsInactive ? "Keep Inactive" : "Keep Active"}
                </Button>
                <Button onClick={updateCat} color="primary" disabled={isLoading}>
                    {catIsInactive ? "Reactivate" : "Inactivate"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CatActiveStateModal;
