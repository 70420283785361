import React from "react";
import Highlighter from "react-highlight-words";
import { Typography } from "@mui/material";

const CustomHighlighter = (props) => {
    return (
        <Typography component="span" style={{ color: "inherit" }}>
            <Highlighter
                {...props}
            />
        </Typography>
    );
}

export default CustomHighlighter;
