import React, { useEffect, useState } from "react";
import {
  Modal,
  CircularProgress,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DogCollarLoadCellChart from "../../../utils/common/DogCollarLoadCellChart";
import FrankenDogCollarLoadCellChart from "../../../utils/common/FrankenDogCollarLoadCellChart";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const dateTimeFormatString = "YYYY-MM-DD HH:mm:ss";

const DogCollarEventLabelModal = ({
  eventId,
  submitCallback,
  closeCallback,
  isModalOpen,
  closeModal,
  source
}) => {
  const [newData, setNewData] = useState(null);
  const [newDataDeployment, setNewDataDeployment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const timezone = "UTC";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const [textToCopy, setTextToCopy] = useState(eventId); // The text you want to copy
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  return (
    <Modal open onClose={closeModal}>
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "90%" : "100%",
          maxWidth: "2500px",
          margin: "auto",
          marginTop: isMobile ? "10%" : "5%",
          padding: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h6"
          component="div"
          sx={{ wordBreak: "break-word" }}
        >
          <b>Event: </b> <span>{eventId}</span>
          &nbsp; <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
            <ContentCopyIcon style={{ color: "#096dd9", cursor: "pointer" }} />
          </CopyToClipboard>
        </Typography>
        {copyStatus && <p>Text copied to clipboard!</p>}
        {isLoading ? (
          <Box textAlign="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <form>
            <Box mt={2}>
              <Typography variant="body1">Load cell data</Typography>
              {source == 'dcf1' ? (
              <FrankenDogCollarLoadCellChart
                eventId={eventId}
                startTime={newData?.session_start_date}
                source={source}
              />
              ):(
                <DogCollarLoadCellChart
                eventId={eventId}
                startTime={newData?.session_start_date}
                source={source}
              />
              )}
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default DogCollarEventLabelModal;
