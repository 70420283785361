import React, { useState, useEffect } from "react";
import "./Cats.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import FilterPopover from "../../../../utils/FilterPopover";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CatsRouteQueryControls from "../../../../utils/catUtills/CatsRouteQueryControls";
import { useSearchParams } from "../../../../utils/useSearchParams";
import CatsTable from "./../../../Table/CatsTable/CatsTable";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";

const Cats = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filters, setFilters] = useState({});
  const [newData, setNewData] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
  });
  const [isLoading, setisLoading] = React.useState(true);
  const [isupdateTable, setisUpdate] = React.useState(1);

  const updateTable = (newState) => {
    setisUpdate(newState);
};

  const filterOptions = [
    {
      name: "trainedState",
      label: "Trained State",
      values: [{"label": "trained", "value": "trained"}, {"label": "training", "value": "training"},
      {"label": "untrained", "value": "untrained"},{"label": "retraining", "value": "retraining"}],
    },
    {
      name: "active",
      label: "Active",
      values: [{"label": "Active", "value": true}, {"label": "Not Active", "value": false}]
    }
  ];

  const getAllSearchParams = (key) => {
    const resultsArray = searchParams.getAll(key)
    if (resultsArray.length === 0) return null

    return resultsArray
}

  useEffect(() => {
    setisLoading(true);


    // Calls Cat listing API
    let url = "/cat-listing?page=" + searchParams.get("page")
      + "&pageSize=" + searchParams.get("pageSize")
      + "&snSuffix=" + searchParams.get("snSuffix")
      + "&sort=" + searchParams.get("sort")
      + "&catName=" + searchParams.get("catName")
      + "&trainedState=" + getAllSearchParams("trainedState")
      + "&active=" + getAllSearchParams("active")

    axiosInstance.get(url)
      .then((response, error) => {
        if (error) {
          setError(error);
          return;
        }
        setNewData(response.data);
        setisLoading(false);
      }).catch((error) => {
        setError(error);
        setisLoading(false);
      })


  }, [searchParams, setNewData, isupdateTable]);

  const handleSearch = (value) => {
    console.log("Search input:", value);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    console.log("going to apply filter", filters)
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // Add logic to apply the filters to your data fetching logic
  };


  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    // totalCount: searchParams.get("totalCount")
    //   ? Number(searchParams.get("totalCount"))
    //   : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
        // totalCount
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
    }
  }) ?? []


  const updateParentState = (value) => {
    console.log("Parent state updated with value: ", value);
  };
  
  return !isLoading ?(
    <div className="module-content">
      <h5>Cats</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <CatsRouteQueryControls
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={handleFilterClick}>
                <FilterAltIcon />
              </IconButton>
            </Grid>
          </Grid>
          <br></br>

          <Grid container>
            <CatsTable
              data={dataTransformedForTable}
              loading={false}
              columnsFn={null}
              // pagination={{
              //   current: pagination.current,
              //   pageSize: pagination.pageSize,
              //   total: pagination.total,
              //   onChange: (page, pageSize) => {
              //     console.log(`Page: ${page}, PageSize: ${pageSize}`);
              //   },
              // }}
              pagination={pagination}
              updateParentState={updateTable}
              isupdateTable={isupdateTable}
            />
          </Grid>
        </CardContent>
      </Card>
      <FilterPopover
        filterOptions={filterOptions}
        anchorEl={filterAnchorEl}
        handleClose={handleFilterClose}
        handleApply={handleFilterApply}
      />
    </div>
  ): (
    <LoadingSpinner />
  );
};

export default Cats;
