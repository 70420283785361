import React, { useState, useEffect } from "react";
import "./CatFeederEvents.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import DeviceSnSuffixSearch from "../../../utils/DeviceSnSuffixSearch";
import { useSearchParams } from "../../../utils/useSearchParams";
import axiosInstance from "../../../api/axios/axiosInstance";
import LoadingSpinner from "../../LoadingSpinner/component";
import CatFeederEventTable from "../../Table/CatFeederEventsTable/CatFeederEventsTable";


const CatFeederEventsHome = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [newData, setNewData] = React.useState();
  const [newDataRevision, setNewDataRevision] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    snSuffix: "",
  })
  const [isLoading, setisLoading] = React.useState(true);

  

  const getAllSearchParams = (key) => {
    const resultsArray = searchParams.getAll(key)
    if (resultsArray.length === 0) return null

    return resultsArray
  }
  


  useEffect(() => {
    setisLoading(true);
    let withTags = ""
    let withoutTags = ""

    
    
    let url = "/get-cat-feeder-events-home"
    
    axiosInstance.post(url, {
      count: 50,
      snSuffixSessionID: searchParams.get("snSuffix"),
      page: searchParams.get("page")
    },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response, error) => {
        setisLoading(false);
        setNewData(response.data);
        setError(error);
      }).catch((error) => {
        setisLoading(false);
      })



  }, [searchParams])

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]
      
    }
    setisLoading(true)
    setSearchParams(newSearchParams);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // Add logic to apply the filters to your data fetching logic
  };

  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
      startTime: monitor.startTime ?? ""
    }
  }) ?? []


  const [filters, setFilters] = useState({});
  return !isLoading ? (
    <div className="module-content">
      <h5>Events</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} placeholder="Input Event ID" />
            </Grid>
            
          </Grid>
          <br></br>

          <Grid container>
            <CatFeederEventTable
              data={dataTransformedForTable}
              loading={false}
              pagination={pagination}
            />
          </Grid>
        </CardContent>
      </Card>
      
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default CatFeederEventsHome;
