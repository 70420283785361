import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import DogCollarLoadCellChart from "../common/DogCollarEventLoadCellChart";
import DogCollarEventLabelModal from "../../components/Modules/DogCollarSingleView/DogCollarEventLabelModal";

const DogCollarEventLoadCellChart = ({ eventId, sessionStartTime, apiUrl, source }) => {

  const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Load Cell Chart
      </Typography>
      <Button
                    variant="contained"
                    onClick={() => setEventModalIsOpen(true)}
                    sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "50px",
                        textTransform: "none",
                        marginLeft: "1350px",
                        marginTop: "-54px",
                        fontSize: "12px",
                        "&:hover": {
                            backgroundColor: "#333",
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: "5px",
                        },
                    }}
                >
                    View All
                </Button>
      <Card>
        <CardContent>
          <Box sx={{ height: 850 }}>
            <DogCollarLoadCellChart eventId={eventId} startTime={sessionStartTime} apiUrl={apiUrl} source={source}/>
          </Box>
        </CardContent>
      </Card>
      {newEventModalIsOpen && (
                <DogCollarEventLabelModal
                    isModalOpen={newEventModalIsOpen}
                    closeModal={() => setEventModalIsOpen(false)}
                    eventId={eventId}
                    source={source}
                />
            )}
    </Box>
  );
};
export default DogCollarEventLoadCellChart;
