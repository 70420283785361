import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";

const HouseholdUsers = () => {
  const { householdId } = useParams();

  const [newData, setNewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const url = `/household-users?household_id=${householdId}`;

  useEffect(() => {
    axiosInstance
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [url]);

  // if (newData === null) {
  //   throw new Error("No data object");
  // }

  const columns = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Timezone", field: "timezone" },
  ];

//   const dataTransformedForTable = newData?.data.map((household) => {
//     return {
//         ...household,
//         key: household.id,
//     }
// }) ?? []
console.log("newewwe", newData)
console.log("lebngth", newData?.data?.users?.length)

  return (
    <Box sx={{ padding: 2 }}>
      
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field}>{column.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newData?.data?.users?.length === undefined ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>No data available</TableCell>
                </TableRow>
              ) : (
                newData.data.users.map((user) => (
                  <TableRow key={user.id}>
                    {columns.map((column) => (
                      <TableCell key={column.field}>
                        {user[column.field] ?? "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default HouseholdUsers;
