import React, { useState, useEffect } from "react";
import "./DogCollarEvents.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import DeviceSnSuffixSearch from "../../../utils/DeviceSnSuffixSearch";
import { useSearchParams } from "../../../utils/useSearchParams";
import axiosInstance from "../../../api/axios/axiosInstance";
import LoadingSpinner from "../../LoadingSpinner/component";
import DogCollarEventTable from "../../Table/DogCollarEventsTable/DogCollarEventsTable";


const DogCollarEventsHome = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [newData, setNewData] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    snSuffix: "",
    // eventType: "",
    // sort: "START_TIME_DESC"
  })
  const [isLoading, setisLoading] = React.useState(true);

  const filterOptions = [

    {
      name: "eventType",
      label: "Event Type",
      values: ["ERROR", "TRIGGERED", "TARE"],
    },
    {
      name: "sort",
      label: "SORT",
      values: ["START_TIME_DESC", "START_TIME_ASC"],
    },
    {
      name: "withTags",
      label: "With Tags",
      values: ["urination", "defecation"],
    },
    {
      name: "withoutTags",
      label: "Without Tags",
      values: ["urination", "defecation"],
    },
  ];

  const getAllSearchParams = (key) => {
    const resultsArray = searchParams.getAll(key)
    if (resultsArray.length === 0) return null

    return resultsArray
  }



  useEffect(() => {
    setisLoading(true);
    let withTags = ""
    let withoutTags = ""

    
    let url = "/get-dog-collar-pets-events-home"

    

    axiosInstance.post(url, {
      count: 50,
      event_id: searchParams.get("snSuffix"),
      page: searchParams.get("page"),
      // snSuffixSessionID: snSuffixSessionID,
      // from_date: startDate, to_date: endDate
    },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response, error) => {
        setisLoading(false);
        setNewData(response.data);
        setError(error);
      }).catch((error) => {
        setisLoading(false);
      })



  }, [searchParams])

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]

    }
    setisLoading(true)
    setSearchParams(newSearchParams);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // Add logic to apply the filters to your data fetching logic
  };

  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
      startTime: monitor.startTime ?? ""
    }
  }) ?? []


  const [filters, setFilters] = useState({});
  return !isLoading ? (
    <div className="module-content">
      <h5>Events</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} placeholder="Input Event ID"/>
            </Grid>

          </Grid>
          <br></br>

          <Grid container>
            <DogCollarEventTable
              data={dataTransformedForTable}
              loading={false}
              pagination={pagination}
            />
          </Grid>
        </CardContent>
      </Card>

    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default DogCollarEventsHome;
