import React from "react";
import { Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useTimezone } from "./context/Timezone";

function convertToUTCStringIfNot(dateString) {
    if (dateString.slice(-6) === "+00:00") return dateString;
    if (dateString[dateString.length - 1] === "Z") return dateString;

    dateString = dateString + "Z";
    return dateString;
}

function rawStringToMomentInSelectedTimezone({ dateString, timezone }) {
    return moment(dateString).tz(timezone);
}

const HumanReadableDate = ({ dateString }) => {
    const timezone = useTimezone();
    if (dateString === undefined)
        return <Typography component="span" style={{ whiteSpace: "nowrap" }}>Date unknown</Typography>;

    const dateStringUTC = convertToUTCStringIfNot(dateString);

    const momentInSelectedTimezone = rawStringToMomentInSelectedTimezone({
        dateString: dateStringUTC,
        timezone,
    });

    const humanReadableDate = momentInSelectedTimezone.format("Do MMM YYYY");

    const timestampAndTimezone = momentInSelectedTimezone.format("HH:mm:ss Z");

    const timeFromNow = momentInSelectedTimezone.fromNow();

    return (
        <Tooltip title={`${timeFromNow}, ${timestampAndTimezone}`}>
            <Typography component="span" style={{ whiteSpace: "nowrap" }}>{humanReadableDate}</Typography>
        </Tooltip>
    );
};

export default HumanReadableDate;
