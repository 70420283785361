

const redirectToHome = () => {
    
    console.log(
        "[Authorisation Error]: Not Authorised to view the page",
    )

    const currentLocation = window.location.pathname + window.location.search
    window.location.assign(`/home`)
}

export default redirectToHome
