import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Button,
  IconButton,
  Paper,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableHead,
  // TableRow,
  // Chip,
  CircularProgress,
  Popover,
  styled,
} from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";
import CatAttributes from "./CatAttributes";
import CatEventTotals from "./CatEventTotals";
import CatCharts from "./CatCharts";
import CatPedtResults from "./CatPedtResults";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CatEvents from "./CatEvents";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const InfoBox = ({ title, value }) => (
  <Box textAlign="center">
    <Typography variant="body2" color="textSecondary">
      {title}
    </Typography>
    <Typography variant="h6" component="div">
      {value}
    </Typography>
  </Box>
);

const Cat = () => {
  const { catId } = useParams();
  const navigate = useNavigate();
  const [newData, setNewData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    

      axiosInstance.post('/cat-attribute-details', { cat_id: catId},{headers:{
        "Content-Type": "multipart/form-data",
    }})
        .then((response, error) => {
            if (error) {
              setError(error)
                return;
            }
            setIsLoading(false);
            setNewData(response.data)
        }).catch((error)=>{
          setIsLoading(false);
        });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!newData) {
    return (
      <Typography variant="h6" color="error">
        This Cat does not exist. Please return to the Cat list.
      </Typography>
    );
  }

  return (
    <Box p={3}>
      <Button
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          sx={{
            color: "black",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
      >
        Go Back
      </Button>
      <Grid container spacing={3} justifyContent="center">
        {/* <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs>
                  <InfoBox title="Total Events" value="11k" />
                </Grid>
                <Grid item xs>
                  <InfoBox title="False Events" value="1.5k" />
                </Grid>
                <Grid item xs>
                  <InfoBox title="Real Events" value="9.5k" />
                </Grid>
                <Grid item xs>
                  <InfoBox title="Cat Events" value="6k" />
                </Grid>
                <Grid item xs>
                  <InfoBox title="Labelled Events" value="4.5k" />
                </Grid>
                <Grid item xs>
                  <InfoBox title="Error Events" value="0" />
                </Grid>
                <Grid item xs>
                  <InfoBox title="Factory Reset Count" value="1.2k" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h5">Attributes</Typography>
          <CatAttributes 
            cat_id={catId}
            fetchData={newData}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Event Totals</Typography>
          <CatEventTotals />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Cat Weights & Visits</Typography>
          <CatCharts />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">PEDT Results</Typography>
          <CatPedtResults />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Cat Events</Typography>
          <CatEvents />
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default Cat;
