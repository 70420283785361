import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Button,
    TextField,
    CircularProgress,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axiosInstance from "./../../../api/axios/axiosInstance";
import { useFormik } from "formik";

const OnboardGroups = ({ household_id, submitCallback, closeCallback }) => {
    const theme = useTheme();
    const [deviceTagData, setDeviceTagData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getDeviceTags();
    }, []);

    const getDeviceTags = () => {
        setIsLoading(true);
        const durl = `/devices-tags?household_id=${household_id}`;

        axiosInstance
            .get(durl)
            .then((response) => {
                setDeviceTagData(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    const formik = useFormik({
        initialValues: { tags: [], name: "", devices: [] },
        onSubmit: (values) => {
            setIsLoading(true);
            const body = {
                household_id: household_id,
                device_id_list: values.devices,
                group_name: values.name,
            };
            axiosInstance
                .post("/create-household-machines-groups", body)
                .then((response) => {
                    submitCallback();
                    closeCallback()
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                });
        },
    });

    return (
        <Modal open onClose={closeCallback}>
            <Box
                sx={{
                    padding: theme.spacing(2),
                    bgcolor: 'background.paper',
                    margin: 'auto',
                    maxWidth: 400,
                    borderRadius: 1,
                }}
            >
                <Typography variant="h6">Onboard Monitor Groups</Typography>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                id="name"
                                name="name"
                                label="Group Name"
                                variant="outlined"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                required
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="devices-label">Devices</InputLabel>
                            <Select
                                labelId="devices-label"
                                id="devices"
                                name="devices"
                                multiple
                                value={formik.values.devices}
                                onChange={formik.handleChange}
                                renderValue={(selected) => selected.join(", ")}
                                required
                            >
                                {deviceTagData.map((data, i) => (
                                    <MenuItem key={i} value={data}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box mt={2}>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} /> : "Create Group"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Box>
        </Modal>
    );
};

export default OnboardGroups;
