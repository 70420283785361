import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "./../../../api/axios/axiosInstance";

const validationSchema = yup.object({
  deviceId: yup.string().required("Device ID is required"),
  name: yup.string(),
  // device_start_date: yup.date().required("Device Start Date is required"),
});

const OnboardDevices = ({ household_id, submitCallback, closeCallback }) => {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      deviceId: "",
      name: "",
      device_start_date: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
      setIsLoading(true);
      const params = {
        device_id: values.deviceId,
        name: values.name,
        // device_start_date: values.device_start_date.format("YYYY-MM-DD HH:mm:ss"),
        device_start_date: null,
        household_id: household_id,
        st_firmware_upgrade_revision: null,
        esp_firmware_upgrade_revision: null,
        st_firmware_upgrade_available: null,
        esp_firmware_upgrade_available: null,
        firmware_upgrade_available: null,
        frozen_device_ind: false,
        is_orphaned: false,
      };
      axiosInstance.post("/update-machine", params).then((response) => {
        setIsLoading(false);
        submitCallback();
      }).catch((error) => {
        setIsLoading(false);
        throw new Error(error);
      });
    },
  });

  return (
    <Dialog open onClose={closeCallback}>
      <DialogTitle>Onboard Monitors</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="deviceId"
              name="deviceId"
              label="Device ID"
              value={formik.values.deviceId}
              onChange={formik.handleChange}
              error={formik.touched.deviceId && Boolean(formik.errors.deviceId)}
              helperText={formik.touched.deviceId && formik.errors.deviceId}
              margin="normal"
            />
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Device Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              margin="normal"
            />
            {/* <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Device Start Date"
                value={formik.values.device_start_date}
                onChange={(value) => formik.setFieldValue("device_start_date", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    error={formik.touched.device_start_date && Boolean(formik.errors.device_start_date)}
                    helperText={formik.touched.device_start_date && formik.errors.device_start_date}
                  />
                )}
              />
            </LocalizationProvider> */}
            <DialogActions>
              <Button onClick={closeCallback} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isLoading}>
                Create Device
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OnboardDevices;
