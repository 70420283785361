import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    Alert
} from "@mui/material";
import moment from "moment-timezone";

import { useTimezone } from "./../context/Timezone";
import { useDeploymentModalMachine } from "./deploymentModalMachine";

import axiosInstance from "./../../api/axios/axiosInstance";
import LoadingSpinner from "../../components/LoadingSpinner/component";
import { useForm, Controller } from "react-hook-form";

const DeploymentModalCreateOrUpdate = ({
    machineId,
    catsInMostRecentDeployment,
    initialValues,
    updateParentState,
    updateTable
}) => {
    const [state, send] = useDeploymentModalMachine();
    const timezone = useTimezone();
    const [catsData, setCatsData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [loading, setLoading] = useState(true);

    

    const [form, setForm] = useState({
        startTime: moment(initialValues?.startTime?.split(".")[0]+ "Z").tz(timezone).format("YYYY-MM-DDTHH:mm:ss") ?? null,
        description: initialValues?.description ?? null,
        tags: initialValues?.tags ?? [],
        cats: initialValues?.cats?.map((cat) => cat.id) ?? [],
    });

    const url = `/CatsInHouseholdByMachine?machine_id=${machineId}`;


    useEffect(() => {
        setLoading(true)
        axiosInstance.get(url)
            .then((response) => {
                setCatsData(response.data?.data ?? []);
                return axiosInstance.get('/deployment-tags');
            })
            .then((response) => {
                setTagsData(response.data?.existingDeploymentTags ?? []);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [url]);

    useEffect(() => {
        if (state.matches("closed")) {
            setForm({
                startTime: null,
                description: "",
                tags: [],
                cats: [],
            });
        }
    }, [state]);

    


    const { control, handleSubmit, setValue, reset } = useForm({
        
        defaultValues: {
            cats: initialValues?.cats?.map((cat) => cat.id) ?? [],
            startTime: moment(initialValues?.startTime?.split(".")[0]+ "Z").tz(timezone).format("YYYY-MM-DDTHH:mm:ss") ?? null,
            description: initialValues?.description ?? null,
            tags: initialValues?.tags ?? []
        }
    });

    

    if (loading) return <CircularProgress />;

    const handleFormChange = (field) => (event) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: event.target.value,
        }));
    };

    const onSubmit = async () => {
        try {
            const values = form;

            // send("FETCH");
            send({"type": "FETCH"})
            const startTimeString = moment.tz(values.startTime, timezone).utc().format();

            const description = values.description === "" || values.description === undefined ? null : values.description;
            const tags = values.tags === undefined ? null : values.tags;

            const data = {
                cats: values.cats,
                description: description,
                device_id: machineId,
                endTime: null,
                lastEdited: null,
                session_id: null,
                startTime: startTimeString,
                tags: tags,
            };

            const endpoint = '/create-machine-deployments'
           
                        
            axiosInstance.post(endpoint, data)
                .then((response) => {
                    if (response.data.message === 'Success') {
                        send({"type": "RESOLVE"})
                        send({"type": "CLOSE"})
                        updateParentState(updateTable+1)
                    } else {
                        send({"type": "REJECT"})
                    }
                })
                .catch(() => {
                    send({"type": "REJECT"})
                });
        } catch (error) {
            console.log("Validation Failed:", error);
        }
    };



    return !loading ?(
        <Modal open={state.matches("opened")} onClose={() => send({"type":"CLOSE"})}>
            <Box sx={{ 
                position: "absolute", 
                top: "50%", 
                left: "50%", 
                transform: "translate(-50%, -50%)", 
                width: 400, 
                bgcolor: "background.paper", 
                border: "2px solid #000", 
                boxShadow: 24, 
                p: 4,
                overflowY: 'auto'
            }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h6" component="h2">
                    {state.context.mode === "update" ? "Update Deployment" : "Create Deployment"}
                </Typography>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <Alert severity="warning">Ensure you have the correct timezone set. The timezone is currently set to {timezone}.</Alert>
                </FormControl>
                

                    <Controller
                            name="startTime"
                            control={control}
                            rules={{ required: "Please add a Date" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="datetime-local"
                                    format="YYYY-MM-DD HH:mm:ss"
                                    // label="Start Time"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={form.startTime}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        "step": 1,
                                    }}
                                    onChange={handleFormChange("startTime")}
                                />
                            )}
                        />

                

                    <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    value={form.description}
                                    label="Description"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleFormChange("description")}
                                />
                            )}
                        />

                        
                    

                    <Controller
                            name="tags"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id="tags"
                                    style={{ width: "100%" }}
                                    multiple
                                    placeholder="Tags"
                                    defaultValue={form.tags}
                                    onChange={handleFormChange("tags")}
                                >
                                    {tagsData.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />


                    
                    <Controller
                            name="cats"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id="cats"
                                    style={{ width: "100%" }}
                                    multiple
                                    placeholder="Cats"
                                    defaultValue={form.cats}
                                    onChange={handleFormChange("cats")}
                                >
                                    {catsData.map((cat, i) => (
                                        <MenuItem key={cat.id} value={cat.id}>
                                            {cat.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                    
                {state.matches("opened.error") && (
                    <Alert severity="error">{state.context.message}</Alert>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                    <Button onClick={() => send({"type":"CLOSE"})} sx={{ mr: 2 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" disabled={loading} type="submit">
                        Submit
                    </Button>
                </Box>
                </form>
            </Box>
            
        </Modal>
    ) : (
        <LoadingSpinner />
    );
};

export default DeploymentModalCreateOrUpdate;
