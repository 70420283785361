import React, { useEffect, useState } from "react";
import "./Households.css";
import StatsCard from "../../../StatsCard/StatsCard";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import IndividualCard from "../../../IndividualCard/IndividualCard";
import DeviceSnSuffixSearch from "../../../../utils/DeviceSnSuffixSearch";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterPopover from "../../../../utils/FilterPopover";
import HouseholdsTable from "../../../Table/HouseholdsTable/HouseholdsTable";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";
import { useSearchParams } from "../../../../utils/useSearchParams";

const Households = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [newData, setNewData] = React.useState();
    

  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [puiStats, setPuiStats] = useState("");
  const [puiPetCount, setPuiPetsCount] = useState([
    { title: "Pets", value: 0 },
    { title: "Users", value: 0 },
    { title: "Events", value: 0 },
  ]);
  const [error, setError] = useState('');
  const [householderror, setHouseholdError] = useState('');
  const [isLoading, setisLoading] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    snSuffix: "",
})

  useEffect(() => {
    setisLoading(true);
    // fetchData();
    axiosInstance.post('/attributes-count', {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((response, error) => {
      if (error) {
        setError(error)
        setisLoading(false)
        return;
      }
      if (response.data.message === "Success") {
        
        setPuiStats(response.data.data_stats)
        setPuiPetsCount(response.data.data_pets)
        
      }
      else {
        setError('Invalid credentials');
      }
    }).catch((error) => {
      setisLoading(false);
    });

    // Calls Household listing API
    const url = `/house-hold-listing?${searchParams.toString()}`;

        axiosInstance.get(url)
            .then((response, error) => {
                if(error){
                  setHouseholdError(error);
                    return;
                }
                setNewData(response.data);
                setisLoading(false);
            }).catch((error)=>{
              setisLoading(false);
            })


  }, [sortConfig, setPuiStats, setPuiPetsCount, searchParams]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
  }
  if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]
  }
  setisLoading(true)
  setSearchParams(newSearchParams);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // handleFilterClose();
    // Add logic to apply the filters to your data fetching logic
  };
  const [filters, setFilters] = useState({});

  const filterOptions = [
    {
      name: "trainedState",
      label: "Trained State",
      // values: ["trained", "training", "untrained", "retraining"],
      values: [{"label": "trained", "value": "trained"}, {"label": "training", "value": "training"},
      {"label": "untrained", "value": "untrained"},{"label": "retraining", "value": "retraining"}],
    },
    {
      name: "isHouseholdOnboarded",
      label: "Onboarding",
      // values: ["Never"],
      values: [{"label": "Never", "value": false}]
    }
  ];

  const dataTransformedForTable = newData?.data.map((household) => {
    return {
        ...household,
        key: household.id,
    }
}) ?? []

const pagination = {
  current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
  pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
  onChange: (page, pageSize) => {
      setSearchParams({
          ...Object.fromEntries(searchParams),
          page,
          pageSize,
      })
  },
  total: parseInt(newData?.pageInfo.totalCount ?? 0),
}

  return !isLoading ? (
    <div className="module-content">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <StatsCard stats={puiStats} />
        </Grid>
        {puiPetCount.map((stat, index) => (
          <Grid item xs={6} sm={1.7} key={index} style={{ marginLeft: 30 }}>
            <IndividualCard title={stat.title} value={stat.value} />
          </Grid>
        ))}
      </Grid>
      <br></br>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} />
            </Grid>
            <Grid item>
              <IconButton onClick={handleFilterClick}>
                <FilterAltIcon />
              </IconButton>
            </Grid>
          </Grid>
          <br></br>

          <Grid container>
          <HouseholdsTable data={dataTransformedForTable} loading={false} pagination={pagination} />
          </Grid>
        </CardContent>
      </Card>
      <FilterPopover
        filterOptions={filterOptions}
        anchorEl={filterAnchorEl}
        handleClose={handleFilterClose}
        handleApply={handleFilterApply}
      />
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default Households;
