import React from "react"

import massConversion from "../massConversion"

const MassUnits = React.createContext(undefined)
const MassFormatter = React.createContext(undefined)
const MassFormatterDispatch = React.createContext(undefined)

function unitReducer(_, action) {
    switch (action) {
        case "kg": {
            return {
                converter: massConversion.gToKgs,
                units: "kg",
                decimalPoints: 2,
            }
        }
        case "g": {
            return {
                converter: massConversion.none,
                units: "g",
                decimalPoints: 0,
            }
        }
        case "lb": {
            return {
                converter: massConversion.gToLbs,
                units: "lb",
                decimalPoints: 2,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}

export const useMassFormatter = () => {
    const context = React.useContext(MassFormatter)
    if (context === undefined) {
        throw new Error(
            "useMassFormatter must be used within a MassFormatProvider",
        )
    }
    return context
}

export const useMassUnits = () => {
    const context = React.useContext(MassUnits)
    if (context === undefined) {
        throw new Error("useMassUnits must be used within a MassFormatProvider")
    }
    return context
}

export const useMassFormatDispatch = () => {
    const context = React.useContext(MassFormatterDispatch)
    // console.log("asdddddddddddddddddddddddddddd", context)
    if (context === undefined) {
        throw new Error(
            "useMassFormatDispatch must be used within a MassFormatProvider",
        )
    }
    return context
}

export const MassFormatProvider = ({ children, defaultUnit = "lb" }) => {
    const defaultState = unitReducer(null, defaultUnit)
    const [state, dispatch] = React.useReducer(unitReducer, defaultState)
    // console.log("stateeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", state, defaultState)
    return (
        <MassUnits.Provider value={state.units}>
            <MassFormatter.Provider
                value={{
                    // Converter for use when we need the numerical value in the chosen unit
                    massConverter: state.converter,
                    // Formatter for use when displaying the values in the chosen unit
                    massFormatter: (d) =>
                        state.converter(d).toFixed(state.decimalPoints),
                    // Fixed number to display values at
                    massDecimalPlaces: state.decimalPoints,
                }}
            >
                <MassFormatterDispatch.Provider value={dispatch}>
                    {children}
                </MassFormatterDispatch.Provider>
            </MassFormatter.Provider>
        </MassUnits.Provider>
    )
}
