import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../api/axios/axiosInstance";

const HouseholdEventTotals = () => {
  const { householdId } = useParams();
  const [newData, setNewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const url = `/household-event-totals?household_id=${householdId}`;

  useEffect(() => {
    axiosInstance
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [url]);

  const columns = [
    { title: "Total Events", dataIndex: "totalEvents" },
    { title: "False Events", dataIndex: "falseEvents" },
    { title: "Real Events", dataIndex: "realEvents" },
    { title: "Cat Events", dataIndex: "catEvents" },
    { title: "Labelled Events", dataIndex: "labelledEvents" },
    { title: "Error Events", dataIndex: "errorEvents" },
  ];

  const responseMappedForTable = (data) => {
    if (!data || Object.keys(data).length === 0) return [];
    return [
      {
        id: data.household_id,
        totalEvents: data.totalEvents,
        falseEvents: data.falseEvents,
        realEvents: data.realEvents,
        catEvents: data.catEvents,
        labelledEvents: data.labelledEvents,
        errorEvents: data.errorEvents,
      },
    ];
  };

  const tableData = responseMappedForTable(newData);

  return (
    <Box sx={{ padding: 2 }}>
     
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.dataIndex}>{column.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                tableData.map((row) => (
                  <TableRow key={row.id}>
                    {columns.map((column) => (
                      <TableCell key={column.dataIndex}>
                        {row[column.dataIndex]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default HouseholdEventTotals;
