import React, { useState, useEffect } from "react";
import { Info as InfoIcon } from "@mui/icons-material";
import { Tooltip, Typography, Box, CircularProgress, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axiosInstance from "./../../api/axios/axiosInstance";

const SettingsTablePres = ({ settings = [], testId, loading = false }) => {
    const columns = [
        {
            title: "Name",
            render: (name, record) => (
                <Box display="flex" alignItems="center">
                    <Typography>{name}</Typography>
                    {record.description && (
                        <Tooltip title={record.description}>
                            <InfoIcon fontSize="small" sx={{ ml: 1 }} />
                        </Tooltip>
                    )}
                </Box>
            ),
            dataIndex: "name",
        },
        {
            title: "Source",
            dataIndex: "source",
        },
        {
            title: "Value",
            dataIndex: "value",
            render: (value) => {
                if (value === null || value === undefined) {
                    return "-";
                } else if (typeof value === "object") {
                    return <pre>{JSON.stringify(value, null, 2)}</pre>;
                } else if (typeof value === "string") {
                    return value;
                } else {
                    return JSON.stringify(value);
                }
            },
        },
    ];

    return (
        <TableContainer component={Paper}>
            <Table aria-label="settings table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.title} sx={{ fontWeight: 'bold' }}>
                                {column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {settings.map((record) => (
                        <TableRow key={record.name}>
                            {columns.map((column) => (
                                <TableCell key={column.dataIndex}>
                                    {column.render
                                        ? column.render(record[column.dataIndex], record)
                                        : record[column.dataIndex]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {loading && (
                <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                    <CircularProgress />
                </Box>
            )}
        </TableContainer>
    );
};

const SettingsTable = ({ householdId, catId, machineId, testId }) => {
    const [newData, setNewData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let URL;
        let params;
        if (catId) {
            URL = '/cat-settings?';
            params = { cat_id: catId };
        } else if (machineId) {
            URL = '/machine-settings?';
            params = { machine_id: machineId };
        }
        setLoading(true);
        if (!householdId) {
            axiosInstance.post(URL, params, { headers: { "Content-Type": "multipart/form-data" } })
                .then((response) => {
                    setLoading(false);
                    setNewData(response.data?.data);
                })
                .catch((error) => {
                    setLoading(false);
                });
        } else {
            axiosInstance.get(`/household-settings?household_id=${householdId}`)
                .then((response) => {
                    setLoading(false);
                    setNewData(response.data?.data);
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    }, [catId, householdId, machineId]);

    const settings = newData ? newData : [];
    return (
        <SettingsTablePres
            loading={loading}
            settings={settings}
            testId={testId}
        />
    );
};

const SettingsTableEB = (props) => (
    <SettingsTable {...props} />
);

export default SettingsTableEB;
