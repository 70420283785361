import React from "react"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from "@mui/material"

const TableCellSeenByUser = ({ hasPushMessage, seenByUser }) => {
    if (!hasPushMessage) return "-"

    const positiveText = "Push Message has been seen by User"
    const negativeText = "Push Message has not been seen by User"

    if (seenByUser) {
        return (
            <Tooltip title={positiveText}>
                <CheckCircleOutlineIcon
                    aria-label={positiveText}
                    twoToneColor="#52c41a"
                />
            </Tooltip>
        )
    }
    return (
        <Tooltip title={negativeText}>
            <HighlightOffIcon
                aria-label={negativeText}
                twoToneColor="#eb2f96"
            />
        </Tooltip>
    )
}

export default TableCellSeenByUser
