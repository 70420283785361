import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({

    "@keyframes spinner": {
        to: {
            transform: "rotate(360deg)",
        },
    },

    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loading: {
        display: "inline-block",
        width: "80px",
        height: "80px",
        "&::after": {
            content: '""',
            display: "block",
            width: "64px",
            height: "64px",
            margin: "8px",
            borderRadius: "50%",
            border: "6px solid #be52f2",
            borderColor: "#be52f2 transparent #be52f2 transparent",
            animation: "$spinner 1.2s linear infinite",
        },
    },
})

const LoadingSpinner = () => {
    const classes = useStyles()
    return (
        <div
            className={classes.container}
        >
            <div className={classes.loading} />
        </div>
    )
}

export default LoadingSpinner
