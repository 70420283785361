

const redirectToLogin = () => {
    
    console.log(
        "[Authentication Error]: Authentication is required. Pushing to /sign_out route, which will clear any lingering cookies and redirect to login page",
    )

    const currentLocation = window.location.pathname + window.location.search
    console.log("currentLocation", currentLocation)
    // window.localStorage.setItem("redirect_route", currentLocation)
    window.location.assign(`/login`)
    // window.location.href = 'http://localhost:3000/login';
}

export default redirectToLogin
