import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useParams, useNavigate } from "react-router-dom";

import moment from "moment-timezone";
import axiosInstance from "../../../../api/axios/axiosInstance";
import { useTimezone } from "../../../../utils/context/Timezone";
import DogBowlAttributes from "./DogBowlAttributes";
import DogBowlEvents from "./DogBowlEvents";
// import DogCollarVideoEvents from "./DogCollarVideoEvents";

const DogBowl = () => {
  const timezone = useTimezone();
  const { dogId } = useParams();
  const navigate = useNavigate();
  const [newData, setNewData] = useState(null);
  const [reloading, setReloading] = useState(0);
  const [isLoading, setisLoading] = React.useState(true);
  useEffect(() => {
    setisLoading(true);
    axiosInstance
      .get(
        "/get-dog-bowl-pet-details?pet_id=" + dogId,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setNewData(response.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setisLoading(false);
      });
  }, [setNewData]);

  const machineSerialNumber = newData?.data.sn;
  const machineName = newData?.data.name;
  const isFrozen = newData?.data.isFrozen;
  const frozenAt = newData?.data.mostRecentUploadAt;

  const formatDateBySelectedTimezone = (date) =>
    moment(date + "Z")
      .tz(timezone)
      .format("YYYY/MM/DD - HH:mm:ss");

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <ArrowBackIcon sx={{ marginRight: 1 }} />
        <Typography
          component={Link}
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          sx={{
            color: "black",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Go Back
        </Typography>
      </Box>


      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Attributes
          </Typography>
          <DogBowlAttributes setReloading={setReloading} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Events
          </Typography>
          <DogBowlEvents />
        </Grid>

        {/* <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Events By Video
          </Typography>
          <DogCollarVideoEvents />
        </Grid> */}

      </Grid>
    </Box>
  );
};

export default DogBowl;
