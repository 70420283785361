import React, { useState } from "react"
import { Card } from "@mui/material"
import LoadingSpinner from "../../../LoadingSpinner/component"
import moment from "moment-timezone"
import { useParams } from "react-router"
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"

import LoadCellChart from "../../../../utils/common/LoadCellChart"
import { Time } from "../../../../utils/context/Timezone"
import { useTimezone } from "../../../../utils/context/Timezone"
import colors from "../../../../utils/colors"
import shortenGraphId from "../../../../utils/shortenGraphId"
import axiosInstance from "../../../../api/axios/axiosInstance"
import { useSearchParams } from "../../../../utils/useSearchParams"

import { Cat, Circle, G, OuterRing, Star } from "./ChartShapes"

export const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload[0]?.payload) {
        return <></>
    }
    const { event, catName, isCat } = payload[0].payload
    return (
        <Card
            size="small"
            title={shortenGraphId(event?.id)}
            style={{ width: 300, zIndex: 100 }}
            data-testid="chart-CustomTooltip"
        >
            <span data-testid="CustomTooltip-startTime">
                Start time: <Time>{event?.startTime}</Time>
            </span>
            <br />
            {isCat && catName ? (
                <span data-testid="CustomTooltip-catName">Cat: {catName}</span>
            ) : null}
        </Card>
    )
}
export const _calcTicks = (start, end, tz) => {
    const momentStart = moment.unix(start)
    const momentEnd = moment.unix(end)
    const nextMidnight = moment(momentStart)
        .tz(tz)
        .add(1, "days")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const ticks = []
    const diff = momentEnd.diff(nextMidnight, "days", true)
    for (var i = 0; i < diff; i++) {
        ticks.push(
            moment(nextMidnight)
                .add({ days: i })
                .unix(),
        )
    }
    return ticks
}

const HouseholdEventsChart = () => {
    const { householdId } = useParams()
    const timezone = useTimezone()

    const getTwoWeeksAgo = ({ timezone }) => {
        return moment()
          .tz(timezone)
          .subtract(14, "days")
          .format("YYYY-MM-DD")
      }
      const getEndOfToday = ({ timezone }) => {
        return moment()
          .tz(timezone)
          .format("YYYY-MM-DD")
      }

    const [searchParams, setSearchParams] = useSearchParams({
        eventsTablePage: 1,
        eventsTablePageSize: 50,
        // machineSns: undefined,
        hideFalseEvents: true,
        fromDate: getTwoWeeksAgo({ timezone }),
        toDate: getEndOfToday({ timezone }),
    })

    const [newData, setNewData] = React.useState();
    const [isLoading, setisLoading] = React.useState(true);
    const [error, setError] = useState('');


    // Create a new URLSearchParams object
    const queryParams = new URLSearchParams();

    // Set householdId as a query parameter
    queryParams.set('household_id', householdId);

    // Set machineSns as a query parameter if it exists
    const machineSns = searchParams.get("machineSns");
    if (machineSns) {
        queryParams.set('machineSns', machineSns);
    }

    // Set fromDate and toDate as query parameters
    const fromDate = searchParams.get("fromDate");
    if (fromDate) {
        const formattedFromDate =searchParams.get("fromDate") +"T00:00:00" +moment.tz(timezone).format("Z");
        queryParams.set('fromDate', formattedFromDate);
    }

    const toDate = searchParams.get("toDate");
    if (toDate) {
        const formattedToDate = searchParams.get("toDate") +"T23:59:59" + moment.tz(timezone).format("Z")
        queryParams.set('toDate', formattedToDate);
    }

    // Set hideFalseEvents as a query parameter
    const hideFalseEvents = searchParams.get("hideFalseEvents");
    if (hideFalseEvents !== null) {
        queryParams.set('hideFalseEvents', hideFalseEvents === "false" ? "false" : "true");
    }

    // Set page and perPage as query parameters
    queryParams.set('page', '1'); // Hardcoded to 1
    queryParams.set('perPage', '1000'); // Hardcoded to 1000

    // Create the final URL with the query parameters
    const url = `/household-events?${queryParams.toString()}`;

    React.useEffect(() => {

        axiosInstance.get(url)
            .then((response, error) => {
                if (error) {
                    setError(error)
                    return;
                }
                setisLoading(false);
                setNewData(response.data.data);

            }).catch((error) => {
                setisLoading(false);
            });
    }, [timezone])


    /*const { data, loading, error, fetchMore } = useQuery(EVENTS_FOR_CHART, {
        variables: {
            householdId: householdId,
            machineSns: searchParams.get("machineSns"),
            fromDate:
                searchParams.get("fromDate") +
                "T00:00:00" +
                moment.tz(timezone).format("Z"),
            toDate:
                searchParams.get("toDate") +
                "T23:59:59" +
                moment.tz(timezone).format("Z"),
            hideFalseEvents:
                searchParams.get("hideFalseEvents") === "false" ? false : true,
            page: 1,
            perPage: 1000, // we're trying to fetch paginated data all at once, hence setting this number very high
        },
        onCompleted: (data) => {
            if (data.node.events?.pageInfo.hasNextPage === true) {
                loadMoreEvents(fetchMore, {
                    householdId: householdId,
                    machineSns: searchParams.get("machineSns"),
                    fromDate:
                        searchParams.get("fromDate") +
                        "T00:00:00" +
                        moment.tz(timezone).format("Z"),
                    toDate:
                        searchParams.get("toDate") +
                        "T23:59:59" +
                        moment.tz(timezone).format("Z"),
                    hideFalseEvents:
                        searchParams.get("hideFalseEvents") === "false"
                            ? false
                            : true,
                    page: data.node.events?.pageInfo.currentPage + 1,
                    perPage: 1000,
                })
            }
        },
    })
    useErrorHandler(error)*/

    if (isLoading && !newData)
        // only show loading spinner on first request
        return (
            <Card
                style={{
                    width: "100%",
                    height: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <LoadingSpinner />
            </Card>
        )

    if (newData?.length === 0) {
        return null
    }

    const events = newData?.map((edge) => edge) ?? []

    return (
        <ChartOnly
            events={events}
            fromDate={searchParams.get("fromDate")}
            toDate={searchParams.get("toDate")}
        />
    )
}
const ChartOnly = ({ events, fromDate, toDate }) => {
    const timezone = useTimezone()
    const [selectedEventId, setSelectedEventId] = useState()
    const [selectedsessiondate, setSelectedSessionDate] = useState()
    
    let catColors = {}

    const chartData = events.map((event) => {
        const { id, startTime, normalisedClassification, labels } = event
        if (
            normalisedClassification?.cat?.name &&
            !catColors[normalisedClassification?.cat?.name]
        ) {
            catColors[normalisedClassification?.cat?.name] = ""
        }
        return {
            id,
            catWeight: normalisedClassification?.catWeight
                ? normalisedClassification?.catWeight
                : 0,
            unixTime: moment(startTime).unix(),
            isCat: labels?.isCat ?? normalisedClassification?.isCat,
            catName: labels?.cat?.name ?? normalisedClassification?.cat?.name,
            isLabelled: labels !== undefined && labels?.cat !== undefined,
            event,
        }
    })
    Object.keys(catColors)
        .sort()
        .forEach((key, i) => {
            catColors[key] = colors[i]
        })

    const ShapeLegend = (props) => {
        return (
            <ul {...props} style={{ display: "flex", listStyle: "none" }}>
                <li style={{ marginRight: "12px" }}>
                    <Circle /> not Cat
                </li>
                <li style={{ marginRight: "12px" }}>
                    <Cat /> Cat
                </li>
                <li>
                    <Star /> Labelled Cat
                </li>
            </ul>
        )
    }

    const legendPayload = (function () {
        const uniqueCats = Array.from(
            new Set(chartData.map((datum) => datum.catName)),
        ).filter((catName) => catName !== undefined)

        return uniqueCats
    })()

    return (
        <div
            style={{ backgroundColor: "#FFF", padding: "1rem" }}
            data-testid="household-eventschart"
        >
            <ResponsiveContainer width="100%" height={300}>
                <ScatterChart
                    margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
                >
                    <Scatter
                        data={chartData}
                        size={8}
                        shape={(datum) => {
                            const {
                                cx,
                                cy,
                                isCat,
                                catName,
                                id,
                                isLabelled,
                            } = datum
                            const size = 7

                            const scatterPointIsSelected =
                                id === selectedEventId

                            return (
                                <>
                                    <G key={id} x={cx - size} y={cy - size}>
                                        {isCat && catName && isLabelled ? (
                                            <Star fill={catColors[catName]} />
                                        ) : isLabelled && !catName ? (
                                            <Star fill={"black"} />
                                        ) : isCat && catName ? (
                                            <Cat fill={catColors[catName]} />
                                        ) : isCat && !catName ? (
                                            <Cat fill={"black"} />
                                        ) : (
                                            <circle
                                                cx={size}
                                                cy={size}
                                                r={size / 1.3}
                                                data-testid="not_cat"
                                            />
                                        )}
                                    </G>
                                    {scatterPointIsSelected ? (
                                        <G
                                            key={id}
                                            x={cx - size - 3} // offset of 3 is half the difference in size between width 14 of circle/cat/star, and width 20 of outerring
                                            y={cy - size - 3}
                                        >
                                            <OuterRing fill="red" />
                                        </G>
                                    ) : null}
                                </>
                            )
                        }}
                        onClick={(d) => {
                            setSelectedEventId(d.id);
                            setSelectedSessionDate(d.id);
                        }}
                    />
                    <Legend
                        verticalAlign="top"
                        height={36}
                        payload={legendPayload}
                        content={(props) => {
                            const { payload } = props

                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <ShapeLegend />
                                    <ul style={{ textAlign: "center" }}>
                                        {payload.map((catName, index) => {
                                            const color = catColors[catName]
                                            return (
                                                <li
                                                    key={`item-${index}`}
                                                    style={{
                                                        color: color,
                                                        display: "inline-block",
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    <svg
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 32 32"
                                                        version="1.1"
                                                        style={{
                                                            display:
                                                                "inline-block",
                                                            verticalAlign:
                                                                "middle",
                                                            marginRight: "4px",
                                                        }}
                                                    >
                                                        <rect
                                                            fill="currentColor"
                                                            width="28"
                                                            height="28"
                                                        ></rect>
                                                    </svg>
                                                    <span>{catName}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis dataKey="catWeight" name="Cat weight" unit="g" />
                    <XAxis
                        dataKey="unixTime"
                        name="Time"
                        type="number"
                        domain={[
                            moment(fromDate).unix(),
                            moment(toDate).unix(),
                        ]}
                        tickFormatter={(d) =>
                            moment
                                .unix(d)
                                .tz(timezone)
                                .format("DD MMM")
                        }
                        ticks={_calcTicks(
                            moment(fromDate).unix(),
                            moment(toDate).unix(),
                            timezone,
                        )}
                    />
                    <Tooltip content={<CustomTooltip />} />
                </ScatterChart>
            </ResponsiveContainer>
            {selectedEventId ? (
                <>
                    {/* <EventKeyInfo eventId={selectedEventId} /> */}
                    {/* <PageContentBlock> */}
                        <LoadCellChart eventId={selectedEventId} startTime={selectedsessiondate} />
                        <br />
                        <br />{" "}
                        {/* hack to stop load cell chart overlapping boundary */}
                    {/* </PageContentBlock> */}
                </>
            ) : null}
        </div>
    )
}

export default HouseholdEventsChart;

export { ChartOnly }
