import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/system";

const ExtraInfoTextWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const ExtraInfoText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}));

const LabelHelpText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

const ExtraInformation = ({ description }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Extra Information
      </Typography>
      <Card>
        <CardContent>
          <ExtraInfoTextWrapper>
            {description ? (
              <ExtraInfoText data-testid="eventView.extraInfoText">
                {description}
              </ExtraInfoText>
            ) : (
              <LabelHelpText>No extra information available.</LabelHelpText>
            )}
          </ExtraInfoTextWrapper>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ExtraInformation;
