import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import axiosInstance from "./../../../api/axios/axiosInstance";
// import HouseholdsTable from "./../../Table/HouseholdsTable/HouseholdsTable";
import CatsTable from "../../Table/CatsTable/CatsTable";

const CatAttributes = () => {
  const { catId } = useParams();
  const [newData, setNewData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = useState('');
  const [isupdateTable, setisUpdate] = React.useState(1);

  const updateTable = (newState) => {
    setisUpdate(newState);
};

  React.useEffect(() => {
    axiosInstance.post('/cat-attribute-details', { cat_id: catId }, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then((response, error) => {
        if (error) {
          setError(error)
          return;
        }
        setIsLoading(false);
        setNewData(response.data)
      }).catch((error) => {
        setIsLoading(false);
      });
  }, [setNewData, isupdateTable]);

  const disableAllSortAndFiltering = (columns) => {
    return columns.map((col) => {
      col.sorter = undefined;
      col.defaultSortOrder = undefined;
      col.filters = undefined;
      col.filterMode = undefined;
      col.defaultFilteredValue = undefined;
      return col;
    });
  };

  const columnsToHide = ["Actions"];
  const hideColumns = (columns) =>
    columns.filter((column) => !columnsToHide.includes(column.title));

  const columnsFn = (columns) =>
    disableAllSortAndFiltering(hideColumns(columns));

  const dataTransformedForTable = newData ? {

    ...newData.data,
    key: newData.data.id,

  } : []

  const updateParentState = (value) => {
    console.log("Parent state updated with value: ", value);
  };


  return (
    <Box sx={{ padding: 2 }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <CatsTable
          data={dataTransformedForTable ? [dataTransformedForTable] : []}
          loading={isLoading}
          pagination={false}
          columnsFn={columnsFn}
          updateParentState={updateTable}
          isupdateTable={isupdateTable}
        />
      )}
    </Box>
  );
};

export default CatAttributes;
