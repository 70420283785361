/* eslint-disable no-fallthrough */
// specifically using fallthrough in switch

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import c from "classnames"
import { useTheme } from "@mui/material/styles";
import { createUseStyles } from "react-jss"
import { faBatteryFull, faBatteryQuarter, faPlug} from "@fortawesome/free-solid-svg-icons";


const useStyles = createUseStyles((theme) => ({
    batteryGreen: {
        color: theme.palette.success.main,
    },
    batteryRed: {
        color: theme.palette.warning.main,
    },
}))


const PowerStatus = ({ powerMode, batteryPercentage, showBatteryWarning }) => {
    const theme = useTheme()
    const classes = useStyles({ theme })

    switch (powerMode) {
        case "AC":
            return (
                <FontAwesomeIcon
                    className={c(classes.batteryGreen)}
                    icon={faPlug}
                    title="ac power icon"
                />
            )
        case "BATTERY":
            
            if (batteryPercentage !== null) {
                // Only set the percentage if we know the value, otherwise fall through to unknown
                return (
                    <>
                        {showBatteryWarning == false ? (
                        
                    
                            [<FontAwesomeIcon
                                className={c({
                                    [classes.batteryGreen]: !showBatteryWarning,
                                    [classes.batteryRed]: showBatteryWarning,
                                })}
                                icon={faBatteryFull}
                                title={
                                    showBatteryWarning
                                        ? "low battery icon"
                                        : "full battery icon"
                                }
                            />,
                            <span>{` ${batteryPercentage}%`}</span>]
                        ):(
                            [<FontAwesomeIcon
                            className={c({
                                [classes.batteryGreen]: !showBatteryWarning,
                                [classes.batteryRed]: showBatteryWarning,
                            })}
                            icon={faBatteryQuarter}
                            title={
                                showBatteryWarning
                                    ? "low battery icon"
                                    : "full battery icon"
                            }
                        />,
                                <span>{` ${batteryPercentage}%`}</span>]
                        )}
                    </>
                )
            }
        default:
            return "Unknown"
    }
}

export default PowerStatus
