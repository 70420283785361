import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { useMassFormatDispatch, useMassUnits } from "../../utils/context/mass";
import TimezoneControls from '../Timezone/component';


const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeModule, setActiveModule] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const massUnits = useMassUnits();
  const dispatch = useMassFormatDispatch();

  const [isHome, setIsHome] = useState(true);

  let modules = {}

  let PTCLinkVisibleRoles = localStorage.getItem("enable_read_write")
  let frankenDevicesUsers = localStorage.getItem("frankenDevicesUsers")


  if (PTCLinkVisibleRoles === 'false' && frankenDevicesUsers == 'false'){
    modules = {
      "cat-litter-box": {
        displayName: "Cat Litter Box",
        submenus: {
          households: "Households",
          monitors: "Monitors",
          cats: "Cats",
          events: "Events",
          location: "Location",
        },
      }
    };

  }
  else if (PTCLinkVisibleRoles === 'true'){
    modules = {
      "cat-litter-box": {
        displayName: "Cat Litter Box",
        submenus: {
          households: "Households",
          monitors: "Monitors",
          cats: "Cats",
          events: "Events",
          location: "Location",
        },
      },
      "cat-feeder": {
        displayName: "Cat Feeder",
        submenus: {
          monitors: "Monitors",
          events: "Events",
        },
      },
      "dog-collar": {
        displayName: "Dog Collar",
        submenus: {
          // monitors: "Monitors",
          dogs: "Dogs",
          events: "Events"
        },
      },
      "franken-dog-collar": {
        displayName: "Franken Dog Collar",
        submenus: {
          dogs: "Dogs",
          events: "Events"
        },
      },
      "dog-bowl": {
        displayName: "Dog Bowl",
        submenus: {
          dogs: "Dogs",
          events: "Events"
        },
      },
    };

  }
  else if (frankenDevicesUsers === 'true'){
    modules = {
      "franken-dog-collar": {
        displayName: "Franken Dog Collar",
        submenus: {
          dogs: "Dogs",
          events: "Events"
        },
      },
    }
  }

  

  useEffect(() => {
    const path = location.pathname.split("/").filter(Boolean);
    if (path.length > 0 && path[0] !== "home") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
    if (path.length === 1) {
      const module = path[0];
      setActiveModule(module);
    } else if (path.length >= 2) {
      const module = path[0];
      setActiveModule(module);
      const subModule = path[1];
      setActiveSubMenu(subModule);
    }
  }, [location]);

  const handleModuleClick = (module) => {
    setActiveModule(module);
    setActiveSubMenu(null);
  };

  const handleSubMenuClick = (submenu) => {
    setActiveSubMenu(submenu);
    navigate(`/${activeModule}/${submenu}`);
  };

  // Handler for unit change
  const handleChange = (event) => {
    const selectedUnit = event.target.value;
    dispatch(selectedUnit); // Dispatches the action to change the unit
};

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-links">
          <div className="navbar-modules">
            {isHome ? (
              <span></span>
            ) : (
                <div>
                  {Object.keys(modules).map((module) => (
                    <div
                      key={module}
                      className={`navbar-module ${activeModule === module ? "active" : ""
                        }`}
                      onClick={() => handleModuleClick(module)}
                    >
                      {modules[module].displayName}
                    </div>
                  ))}
                </div>
              )}
          </div>
          <div className="navbar-dropdowns">
          <TimezoneControls />
            <Select
              value={massUnits}
              onChange={(e) => handleChange(e)}
              className="mass-select"
              size="small"
            >
              <MenuItem key="kg" value="kg">
                kg
              </MenuItem>
              <MenuItem key="g" value="g">
                g
              </MenuItem>
              <MenuItem key="lb" value="lb">
                lb
              </MenuItem>
            </Select>
          </div>
        </div>
      </nav>
      {!isHome && (
        <nav className="navbar_submenu">
          <div className="navbar-links-submenu">
            {activeModule && modules[activeModule]?.submenus &&
              Object.keys(modules[activeModule].submenus).map((submenu) => (
                <div
                  key={submenu}
                  className={`navbar-submenu-item ${activeSubMenu === submenu ? "active" : ""
                    }`}
                  onClick={() => handleSubMenuClick(submenu)}
                >
                  {modules[activeModule].submenus[submenu]}
                </div>
              ))}
          </div>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
