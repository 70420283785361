import React, { createContext, useContext } from "react";
import { useMachine, useSelector } from "@xstate/react";
import { createMachine } from "xstate";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

// Define the XState machine
const deploymentModalMachine = createMachine(
  {
    id: "modal",
    context: {
      deployment: null,
      mode: null,
      message: null,
    },
    initial: "closed",
    states: {
      closed: {
        entry: ["resetContext"],
        on: {
          CREATE: {
            target: "opened",
            actions: ["setModeToCreate"],
          },
          UPDATE: {
            target: "opened",
            actions: ["setModeToUpdate"],
          },
        },
      },
      opened: {
        initial: "idle",
        states: {
          idle: {
            on: {
              FETCH: "pending",
              CLOSE: "#modal.closed",
            },
          },
          pending: {
            on: {
              RESOLVE: {
                target: "success",
              },
              REJECT: {
                target: "error",
                actions: ["setErrorMessage"],
              },
            },
          },
          error: {
            on: {
              FETCH: "pending",
              CLOSE: "#modal.closed",
            },
          },
          success: { type: "final" },
        },
        onDone: "#modal.closed",
      },
    },
  },
  {
    actions: {
      setModeToCreate: (context) => {
        context.mode = "create";
      },
      setModeToUpdate: (context, event) => {
        context.mode = "update";
        context.deployment = event?.deployment ?? null;
      },
      resetContext: (context) => {
        context.deployment = null;
        context.mode = null;
        context.message = null;
      },
      setErrorMessage: (context) => {
        context.message = `There was a problem ${
          context.mode === "create" ? "creating" : "updating"
        } this deployment.`;
      },
    },
  }
);

// Create context for the modal state
const DeploymentModalMachineContext = createContext();

export const DeploymentModalMachineProvider = ({ children }) => {
  const [, , service] = useMachine(deploymentModalMachine);

  return (
    <DeploymentModalMachineContext.Provider value={service}>
      {children}
    </DeploymentModalMachineContext.Provider>
  );
};

export const useDeploymentModalMachine = () => {
  const service = useContext(DeploymentModalMachineContext);
  const state = useSelector(service, (state) => state);
  const send = service.send;

  return [state, send];
};
