const factor = 2.20462

const lbsToKg = (lbs) => lbs / factor

const kgToLbs = (kg) => kg * factor

const gToKgs = (g) => g / 1000

const gToLbs = (g) => kgToLbs(gToKgs(g))

const none = (mass) => mass

const toSF = (fn) => (d) => Number(fn(d).toFixed(massSF))

const massSF = 2

export default {
    lbsToKg: toSF(lbsToKg),
    kgToLbs: toSF(kgToLbs),
    gToLbs: toSF(gToLbs),
    gToKgs: toSF(gToKgs),
    none: toSF(none),
    massSF,
}
