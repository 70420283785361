import React, { useState } from "react";
import {
    Box,
    Button,
} from "@mui/material";
import OnboardGroups from "./OnboardGroups";  // Adjust the import path as necessary
import PageContentBlock from "./../../../utils/PageContentBlock";  // Adjust the import path as necessary

const OnboardDeviceGroupByHousehold = ({ household_id, fetchData }) => {
    const [edit, setEdit] = useState(false);

    return (
        <PageContentBlock>
            {edit && (
                <OnboardGroups
                    household_id={household_id}
                    submitCallback={() => {
                        fetchData();
                        setEdit(false);
                    }}
                    closeCallback={() => setEdit(false)}
                />
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "1rem",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEdit(true)}
                >
                    <div>
                        Add Groups
                    </div>
                </Button>
            </Box>
        </PageContentBlock>
    );
};

export default OnboardDeviceGroupByHousehold;
