import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    IconButton,
    Paper,
    TablePagination,
    Typography,
    Popover,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoCircleOutlined from "@mui/icons-material/InfoOutlined";
import moment from "moment";

import EditCatActiveStateButton from "./../../../utils/catUtills/EditCatActiveStateButton";
import EditCatButton from "./../../../utils/catUtills/EditCatButton";
import GenericCountOutOfTotal from "./../../../utils/catUtills/GenericCountOutOfTotal";
import CustomHighlighter from "./../../../utils/CustomHighlighter";
import HumanReadableDate from "./../../../utils/HumanReadableDate";
import TrainedStateIcon from "./../../../utils/TrainedStateIcon";
import calculateAge from "./../../../utils/calculateAge";
import shortenGraphId from "./../../../utils/shortenGraphId";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const CustomTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const CustomTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    latestEvent: "LATEST_EVENT",
    pedtTriggersCount: "PEDT_TRIGGERS_COUNT",
    pedtResultsNotificationsCount: "PEDT_NOTIFICATIONS_COUNT",
};

const SORT_DIRS = {
    ascend: "asc",
    descend: "desc",
};

const CatsTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    updateParentState,
    isupdateTable,
    ...props
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "asc";
        if (sortSuffixesRegex.groups?.descend) return "desc";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        const appliedSort = searchParams.get("sort") ?? "SN_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        if (!appliedSort.includes(columnSortField)) return null;
        const direction = getDirectionFromSortString(appliedSort);
        if (!direction) return null;
        return direction;
    };

    const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
        const getTrainedStateFilters = () => {
            return searchParams.getAll("trainedState");
        };
        return {
            trainedState: getTrainedStateFilters(),
        };
    };
    const defaultFilteredValuesByColumn = translateSearchParamsToDefaultFilteredValues(searchParams);
    const getDefaultFilteredValuesForColumn = (colIndex) =>
        defaultFilteredValuesByColumn[colIndex];

    const forwardTableChangeToSearchParams = (pagination, filters, sorter) => {
        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };
        const getTrainedState = (filters) => {
            return filters;
        };

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter),
            trainedState: getTrainedState(filters.trainedState ?? []),
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();

        setSearchParams(new URLSearchParams(newSearchParams));
    };

    let columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: function CatName(name, record) {
                const cat_deactivated = record.cat_deactivated;
                return (
                    <Box display="flex">
                        <Link to={`/cat-litter-box/cats/${record.cat_id ? record.cat_id : record.id}`}>
                            <CustomHighlighter
                                searchWords={[searchParams.get("catName")]}
                                textToHighlight={name ?? ""}
                                highlightStyle={{backgroundColor: "#ffe58f"}}
                            />
                        </Link>
                        {cat_deactivated && (
                            <Box ml={1}>
                                <Tooltip title="">
                                    <VisibilityIcon
                                        fontSize="small"
                                        title="Cat is Disabled - Use Deployments to Reactivate"
                                    />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                );
            },
        },
        {
            title: "Household",
            dataIndex: "household_id",
            render: function Household(id) {
                return id ? (
                    <Link to={`/cat-litter-box/households/${id}`}>{shortenGraphId(id)}</Link>
                ) : (
                    "-"
                );
            },
        },
        {
            title: "Age",
            dataIndex: "dob",
            render: (dob) => {
                const dobMoment = moment(dob);
                if (dobMoment.isValid()) {
                    const age = calculateAge(dobMoment);
                    return `${age} year${age > 1 ? "s" : ""}`;
                } else {
                    return "Unknown";
                }
            },
        },
        {
            title: "Gender",
            dataIndex: "gender",
            render: (gender) => {
                switch (gender) {
                    case "m":
                        return "Male";
                    case "f":
                        return "Female";
                    default:
                        return "Unknown";
                }
            },
        },
        {
            title: "BCS",
            dataIndex: "bodyConditionScore",
            render: (bcs) => bcs ?? "Unknown",
        },
        {
            title: "Latest Event",
            dataIndex: "latestEvent",
            render: function RenderLatestEvent(latestEvent) {
                return latestEvent ? (
                    <HumanReadableDate dateString={latestEvent} />
                ) : (
                    "-"
                );
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("latestEvent"),
        },
        {
            title: function RenderCatTrainedStateColumnHeader() {
                const tooltipContent = (
                    <div style={{ maxWidth: "500px" }}>
                        <p>
                            Each cat must have 8 recent labels to be considered “Trained”. Over time, this label count will decrease, eventually dropping below 8. This will cause a change in the trained state of the cat, prompting the user to label it again.
                        </p>
                        <p>
                            Another cause for a cat changing to “Retraining” is if the most recent label is older than 30 days (default value). In this case, if the user labels just one event for a cat, it will change it back to “Trained”.
                        </p>
                        <p>
                            Note: the amount of required labels is configurable per household, so seeing “x / 10” means the value has been changed from the default value of 8 to 10.
                        </p>
                    </div>
                );
                return (
                    <Box display="flex" alignItems="center">
                        <span>Trained State</span>
                        <Popover content={tooltipContent} title="Trained State">
                            <InfoCircleOutlined />
                        </Popover>
                    </Box>
                );
            },
            title: "Trained State",
            dataIndex: "trainedState",
            render: function RenderCatTrainedState(trainedState, record) {
                return (
                    <Box display="flex" alignItems="center">
                        <TrainedStateIcon trainedState={trainedState} />
                        <GenericCountOutOfTotal
                            count={record.trainedLabelledEvents}
                            outOfTotal={record.trainedTargetEvents}
                            tooltipText="Labelled events out of target total labelled events"
                        />
                    </Box>
                );
            },
            filters: [
                {
                    text: "Trained",
                    value: "trained",
                },
                {
                    text: "Training",
                    value: "training",
                },
                {
                    text: "Untrained",
                    value: "untrained",
                },
                {
                    text: "Retraining",
                    value: "retraining",
                },
            ],
            defaultFilteredValue: getDefaultFilteredValuesForColumn("trainedState"),
        },
        {
            title: "PEDT Triggers",
            dataIndex: "pedtTriggersCount",
            width: 100,
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("pedtTriggersCount"),
        },
        {
            title: "PEDT Notifications",
            dataIndex: "pedtResultsNotificationsCount",
            width: 100,
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("pedtResultsNotificationsCount"),
        },
        {
            title: "Active",
            dataIndex: "active",
            render: function CatActive(active, record) {
                return (
                    <Box display="flex" alignItems="center" gap={1}>
                        {active === false ? (
                            <span>Not active</span>
                        ) : (
                            <span>Yes</span>
                        )}
                        <EditCatActiveStateButton
                            cat={record}
                            updateParentState={updateParentState}
                            isupdateTable={isupdateTable}
                        />
                    </Box>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: function CatActions(_, record) {
                return (
                    <Box display="flex">
                        <EditCatButton
                            updateParentState={updateParentState}
                            isupdateTable={isupdateTable}
                            existingCat={record}
                        />
                    </Box>
                );
            },
        },
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }

    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                  ...defaultPagination,
                  ...pagination,
              };


    return (
        <CustomPaper>
            <CustomTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <CustomTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={column.key === 'latestEvent'}
                                            direction={getDefaultSortOrderForColumn(column.key) || 'asc'}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
            </CustomTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                        component="div"
                        count={data.length}
                        rowsPerPage={pagination.pageSize}
                        page={pagination.current - 1}
                        onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                        onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                        rowsPerPageOptions={[10, 25, 50]}
                        labelRowsPerPage="View"
                        labelDisplayedRows={({ from, to, count }) => (
                            <Box display="flex" alignItems="center">
                                <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                                <Box display="flex" alignItems="center" mx={2}>
                                    <VisibilityIcon fontSize="small" />
                                    {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                    <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                                </Box>
                            </Box>
                        )}
                        pagination={pagination}
                    />
                // </CustomTablePagination>
            )}
        </CustomPaper>
    );
};

export default CatsTable;
