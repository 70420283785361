import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    IconButton,
    Paper,
    Tooltip,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from 'moment-timezone';
import { findChunksAtEndOfString } from "../../../utils/findChunksAtEndOfString";
// import EditCatActiveStateButton from "../../../utils/catUtills/EditCatActiveStateButton";
import EditLocationButton from "../../../utils/locationUtils/EditLocationButton";
import axiosInstance from "../../../api/axios/axiosInstance";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const NoData = styled('div')({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled('ul')({
    margin: 0,
    paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const FullWidthPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const FullWidthTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    sn: "SN",
    name: "NAME",
    power: "BATTERY_PERCENTAGE",
    mostRecentUpload: "MOST_RECENT_UPLOAD_AT",
    stFirmwareRevision: "ST_FIRMWARE_REVISION",
    espFirmwareRevision: "ESP_FIRMWARE_REVISION",
    hardwareRevision: "HARDWARE_REVISION",
    wifiRssi: "WIFI_RSSI",
    firstConnectedAt: "FIRST_CONNECTED_AT",
    snFirstConnectedAt: "SN_FIRST_CONNECTED_AT",
    frozenAt: "FROZEN_AT",
};

const SORT_DIRS = {
    ascend: "ASC",
    descend: "DESC",
};

const useStyles = styled({
    monitorNameAndEdit: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
    },
});

const LocationTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    updateParentState,
    isupdateTable,
    facilitypanelData,
    ptcCats,
    revisionOptions: {
        existingStFirmwareRevisions = [],
        existingEspFirmwareRevisions = [],
        existingHardwareRevisions = [],
    } = {},
    ...props
}) => {
    const styles = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setisLoading] = useState(true);
    const timezone = 'UTC';  // Assuming a timezone value, replace it with your logic

    const formatDateBySelectedTimezone = (date) =>
        moment(date + "Z")
            .tz(timezone)
            .format("YYYY-MM-DDTHH:mm:ss Z");

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        // console.log("sortSuffixesRegexsortSuffixesRegex", sortSuffixesRegex)
        if (sortSuffixesRegex.groups?.ascend) return "ascend";
        if (sortSuffixesRegex.groups?.descend) return "descend";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        // console.log("colIndexcolIndexcolIndexcolIndexcolIndex", colIndex)
        // console.log("searchParams.get", searchParams.get("sort"))
        const appliedSort = searchParams.get("sort") ?? "SN_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        // console.log("columnSortFieldcolumnSortFieldcolumnSortField", columnSortField, appliedSort)
        if (!appliedSort.includes(columnSortField)) return null;
        // console.log("appliedSortappliedSortappliedSort", appliedSort)
        const direction = getDirectionFromSortString(appliedSort);
        // console.log("directiondirectiondirectiondirection", direction)
        if (!direction) return null;
        return direction;
    };

    const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
        const getPowerModeFilters = () => {
            const powerModeFilters = searchParams
                .getAll("powerMode")
                .map((filter) => "powerMode." + filter);
            const showBatteryWarningFilters = searchParams
                .getAll("showBatteryWarning")
                .map((filter) => "showBatteryWarning." + filter);

            return powerModeFilters.concat(showBatteryWarningFilters);
        };
        const getMostRecentUploadFilters = () => {
            const mostRecentUploadWarning = searchParams.get(
                "mostRecentUploadWarning"
            );
            if (mostRecentUploadWarning === "true")
                return ["mostRecentUploadWarning.true"];
            if (mostRecentUploadWarning === "false")
                return ["mostRecentUploadWarning.false"];
            return [];
        };
        const getStFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("stFirmwareRevision");
            return revisions;
        };
        const getEspFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("espFirmwareRevision");
            return revisions;
        };
        const getHardwareRevisionFilters = () => {
            return searchParams.getAll("hardwareRevision");
        };
        const getFrozenAtFilters = () => {
            const isFrozen = searchParams.get("isFrozen");
            if (isFrozen === "true") return ["isFrozen.true"];
            if (isFrozen === "false") return ["isFrozen.false"];
            return [];
        };
        const getOrphanedAtFilters = () => {
            const isOrphaned = searchParams.get("isOrphaned");
            if (isOrphaned === "true") return ["isOrphaned.true"];
            if (isOrphaned === "false") return ["isFrozen.false"];
            return [];
        };

        return {
            powerMode: getPowerModeFilters(),
            mostRecentUpload: getMostRecentUploadFilters(),
            stFirmwareRevision: getStFirmwareRevisionFilters(),
            espFirmwareRevision: getEspFirmwareRevisionFilters(),
            hardwareRevision: getHardwareRevisionFilters(),
            frozenAt: getFrozenAtFilters(),
            orphanedAt: getOrphanedAtFilters(),
        };
    };
    const defaultFilteredValuesByColumn = translateSearchParamsToDefaultFilteredValues(
        searchParams
    );
    const getDefaultFilteredValuesForColumn = (colIndex) =>
        defaultFilteredValuesByColumn[colIndex];

    const forwardTableChangeToSearchParams = (pagination, sorter) => {
        // console.log("asdasdasd", pagination, sorter)
        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };
        // const getIsFrozen = (filters) => {
        //     if (filters.includes("isFrozen.true")) return true;
        //     if (filters.includes("isFrozen.false")) return false;
        //     return undefined;
        // };
        // const getIsOrphaned = (filters) => {
        //     if (filters.includes("isOrphaned.true")) return true;
        //     if (filters.includes("isOrphaned.false")) return false;
        //     return undefined;
        // };
        // const getPowerMode = (filters) => {
        //     let powerModeSearchParams = [];
        //     if (filters.includes("powerMode.AC")) {
        //         powerModeSearchParams.push("AC");
        //     }
        //     if (filters.includes("powerMode.BATTERY")) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     if (
        //         filters.includes("showBatteryWarning.true") ||
        //         filters.includes("showBatteryWarning.false")
        //     ) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     return powerModeSearchParams;
        // };
        // const getShowBatteryWarning = (filters) => {
        //     let showBatteryWarningSearchParams = [];
        //     if (filters.includes("showBatteryWarning.true")) {
        //         showBatteryWarningSearchParams.push(true);
        //     }
        //     if (filters.includes("showBatteryWarning.false")) {
        //         showBatteryWarningSearchParams.push(false);
        //     }
        //     return showBatteryWarningSearchParams;
        // };
        // const getMostRecentUploadWarning = (filters) => {
        //     if (filters.includes("mostRecentUploadWarning.true")) return true;
        //     if (filters.includes("mostRecentUploadWarning.false")) return false;
        //     return undefined;
        // };
        // const getStFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getEspFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getHardwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter),
            // isFrozen: getIsFrozen(filters.frozenAt ?? []),
            // isOrphaned: getIsOrphaned(filters.orphanedAt ?? []),
            // powerMode: getPowerMode(filters.power ?? []),
            // showBatteryWarning: getShowBatteryWarning(filters.power ?? []),
            // mostRecentUploadWarning: getMostRecentUploadWarning(
            //     filters.mostRecentUpload ?? []
            // ),
            // stFirmwareRevision: getStFirmwareRevision(
            //     filters.stFirmwareRevision ?? []
            // ),
            // espFirmwareRevision: getEspFirmwareRevision(
            //     filters.espFirmwareRevision ?? []
            // ),
            // hardwareRevision: getHardwareRevision(
            //     filters.hardwareRevision ?? []
            // ),
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();
        setSearchParams(newSearchParams);
    };

    const updateDeviceType = (record, petId, status) => {
        const updatedPetsData = record.pets.map((obj) => {
            if (obj.cat_id === petId) {
                return { ...obj, "primary_device": status};
            } else return obj;
        });
        const data = {
            "device_id": record.device_id,
            "condo": record?.condo ?? "",
            "facility": record.facility,
            "cats": updatedPetsData,
            "active": record.active_ind,
            "panel": record.panel,
            "setDeviceType": true
        }
        updateLocation(data)
    }

    const updateLocation = (data) => {
        
        axiosInstance.post('/update-locations', data).then((response, error) => {
            setisLoading(true);
            if (response.data.message === 'success') {
                updateParentState(isupdateTable + 1)
                setisLoading(true);

            }
        })
    }

    let columns = [
        {
            title: "Serial No.",
            dataIndex: "device_id",
            width: 200,
            render: function MachineSerial(serial, record) {
                return (
                    <div style={{ display: "flex" }}>
                        <Link to={`/cat-litter-box/monitors/${record.device_id}`}>
                            <Highlighter
                                searchWords={[searchParams.get("snSuffix")]}
                                textToHighlight={serial ?? ""}
                                findChunks={findChunksAtEndOfString}
                            />
                        </Link>


                    </div>
                );
            },
            fixed: "left",
        },
        {
            title: "Cats",
            dataIndex: "pets",
            key: "pets",
            render: function renderUsersList(pets, record) {
                if (!pets || pets?.length === 0) return <NoData>No Cats</NoData>;
                return (
                    <Ul>
                        {pets.map((pet) => {
                            if (pet.cat_name) {
                                return (
                                    <li key={pet.cat_id}>
                                        {pet.cat_name}
                                        {!pet.primary_device ?
                                        <Tooltip title="Secondary">
                                            <StarBorderIcon onClick={() => updateDeviceType(record, pet.cat_id, true)} fontSize="small" />
                                            </Tooltip>
                                            :
                                            <Tooltip title="Primary">
                                            <StarIcon onClick={() => updateDeviceType(record, pet.cat_id, false)} fontSize="small" />
                                            </Tooltip>

                                        }


                                    </li>
                                );
                            }
                            return <li key={pet.cat_id}>{pet.cat_name ?? "-"}</li>;
                        })}
                    </Ul>
                );
            },
            width: 300,
        },
        {
            title: "Condo",
            dataIndex: "condo",
        },
        {
            title: "Panel",
            dataIndex: "panel",
        },
        {
            title: "Facility",
            dataIndex: "facility",

        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            width: 150,
            render: function renderActionButton(name, record) {
                return (
                    <Box display="flex" alignItems="center" gap={1}>

                        <EditLocationButton
                            machine={record}
                            updateParentState={updateParentState}
                            isupdateTable={isupdateTable}
                            facilitypanelData={facilitypanelData}
                            ptcCats={ptcCats}
                        />
                    </Box>
                );
            },
        },
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }
    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                ...defaultPagination,
                ...pagination,
            };

    return (
        <FullWidthPaper>
            <FullWidthTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={true}
                                            direction={getDefaultSortOrderForColumn(column.dataIndex) || 'ascend'}
                                            onClick={() => forwardTableChangeToSearchParams(pagination, column.sorter)}
                                        // onChange={forwardTableChangeToSearchParams}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
            </FullWidthTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <WatchLaterIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
            )}
        </FullWidthPaper>
    );
};

export default LocationTable;
