import React from "react"
import { Tooltip } from "@mui/material"
import moment from "moment-timezone"

import { useTimezone } from "../../utils/context/Timezone"

function convertToUTCStringIfNot(dateString) {

    if(dateString){
        if (dateString[dateString.length - 1] !== "Z") {
            dateString = dateString + "Z"
        }
    }
  
    return dateString
}

function rawStringToMomentInSelectedTimezone({ dateString, timezone }) {
    return moment(dateString).tz(timezone)
}

const TimeSinceDate = ({ dateString }) => {
    const timezone = useTimezone()
    if (dateString === undefined)
        return <span style={{ whiteSpace: "nowrap" }}>Date unknown</span>

    const dateStringUTC = convertToUTCStringIfNot(dateString)

    const momentInSelectedTimezone = rawStringToMomentInSelectedTimezone({
        dateString: dateStringUTC,
        timezone,
    })

    const humanReadableDate = momentInSelectedTimezone.format("Do MMM YYYY")

    const timestampAndTimezone = momentInSelectedTimezone.format("HH:mm:ss Z")

    const timeFromNow = momentInSelectedTimezone.fromNow(true) // "true" gives "2 hours" instead of "2 hours ago"

    return (
        <Tooltip title={humanReadableDate + ", " + timestampAndTimezone}>
            <span style={{ whiteSpace: "nowrap" }}>{timeFromNow}</span>
        </Tooltip>
    )
}
export default TimeSinceDate
