import React from "react";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import CatActiveStateModal from "./CatActiveStateModal";

const EditCatActiveStateButton = ({ cat, updateParentState, isupdateTable, ...props }) => {
    const [isUpdateCatModalOpen, setIsUpdateCatModalOpen] = React.useState(false);

    return (
        <>
            <IconButton
                onClick={() => setIsUpdateCatModalOpen(true)}
                // data-testid={tids.catsTable.editCatActiveStateButton}
                {...props}
            >
                <EditOutlined />
            </IconButton>
            <CatActiveStateModal
                isModalOpen={isUpdateCatModalOpen}
                closeModal={() => {
                    setIsUpdateCatModalOpen(false);
                    updateParentState(isupdateTable + 1);
                }}
                cat={cat}
            />
        </>
    );
};

export default EditCatActiveStateButton;
