import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";


const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#1a1a1a',
        color: '#fff',
        '& fieldset': {
            borderColor: '#1a1a1a',
        },
        '&:hover fieldset': {
            borderColor: '#333',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#555',
        },
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
        color: '#fff',
    },
});

const CatsRouteQueryControls = ({ searchParams, setSearchParams }) => {
    const [searchValue, setSearchValue] = React.useState(searchParams.get("catName") || "");

    const handleSearch = (newSearch) => {
        let newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: 1,
            catName: newSearch,
        };
        if (newSearch === "") {
            delete newSearchParams["catName"];
        }
        setSearchParams(new URLSearchParams(newSearchParams));
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(searchValue);
        }
    };

    return (
        <CustomTextField
            value={searchValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Search"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            sx={{ width: 300 }}
        />
    );
};

export default CatsRouteQueryControls;