import React from "react";
import { Box, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Paper, Tooltip, IconButton } from "@mui/material";
import { Time } from "./../../utils/context/Timezone";
import { styled } from "@mui/system";
import { Edit as EditIcon, Search as SearchIcon, Tag } from "@mui/icons-material";
import EventLabelModal from "../../components/Modules/CatLitterBox/Events/EventLabelModal";

const SearchBar = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
}));

const HeaderButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
        backgroundColor: theme.palette.grey[800],
    },
    borderRadius: theme.shape.borderRadius,
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[200],
}));

const CatLink = ({ name, cat_id }) => {
    return (
        <a href={`/cats/${cat_id}`} target="_blank" rel="noopener noreferrer">
            {name}
        </a>
    );
};

const LabelsAndClassifications = ({ id, labels, classifications, normalisedClassification, refetch }) => {
    const values = [
        { title: "False trigger", labelKey: "falseTrigger", classificationKey: "falseTrigger" },
        { title: "Is cat", labelKey: "isCat", classificationKey: "isCat" },
        { title: "Is partial cat", classificationKey: "isPartialCat" },
        { title: "Is weight outlier", classificationKey: "isWeightOutlier" },
        { title: "Balanced status", classificationKey: "balancedStatus" },
        { title: "Cat", labelKey: "cat", classificationKey: "cat" },
        { title: "Weight", classificationKey: "catWeight", normalisedClassificationKey: "catWeight" },
        { title: "Visit duration", labelKey: "visitDuration", classificationKey: "visitDuration" },
        { title: "Visit entries", labelKey: "visitEntries", classificationKey: "visitEntries" },
        { title: "Is elimination", classificationKey: "isElimination" },
        { title: "Entry time", labelKey: "observedEntry" },
        { title: "Exit time", labelKey: "observedExit" },
        { title: "In box duration", labelKey: "inBoxDuration", classificationKey: "inBoxDuration" },
        { title: "Elimination start time", labelKey: "eliminationStart", classificationKey: "eliminationStart" },
        { title: "Elimination end time", labelKey: "eliminationEnd", classificationKey: "eliminationEnd" },
        { title: "Elimination duration", labelKey: "eliminationDuration", classificationKey: "eliminationDuration" },
        { title: "Tags", labelKey: "tags" },
    ];

    const renderFn = (text, record, type) => {
        if (record.title.match(/time/g) && text !== undefined && text !== null) {
            return <Time>{text}</Time>;
        } else if (record.title === "Tags" && text) {
            let tags = text;
            if (!Array.isArray(tags)) tags = tags.split(",");
            return tags.map((tag, i) => <Tag key={`tag-${i}`}>{tag}</Tag>);
        } else if (record.title === "Cat" && text) {
            if (type === "classification") {
                return <CatLink {...record.classification} />;
            } else if (type === "label") {
                return <CatLink {...record.label} />;
            } else if (type === "normalised") {
                return <CatLink {...record.normalised} />;
            }
        } else if (text === undefined) {
            return "";
        } else {
            return `${text}`;
        }
    };

    const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="bold">
                    Classification and Labels
                </Typography>
                {/* <HeaderButton startIcon={<EditIcon />}>
                    Edit Labels
                </HeaderButton> */}
                <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => setEventModalIsOpen(true)}
                    sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "50px",
                        padding: "5px 15px",
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px",
                        "&:hover": {
                            backgroundColor: "#333",
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: "5px",
                        },
                    }}
                >
                    Edit Labels
                </Button>
            </Box>
            {/* <SearchBar>
                <SearchIcon sx={{ mr: 1 }} />
                <TextField variant="standard" placeholder="Search" fullWidth InputProps={{ disableUnderline: true }} />
            </SearchBar> */}
            <TableContainer component={Paper}>
                <Table aria-label="labels and classifications table">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Title</TableHeaderCell>
                            <TableHeaderCell>Classification</TableHeaderCell>
                            <TableHeaderCell>Label</TableHeaderCell>
                            <TableHeaderCell>Normalized</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.map((v, i) => (
                            <TableRow key={i}>
                                <TableCell>{v.title}</TableCell>
                                <TableCell>{renderFn(classifications[v.classificationKey], v, "classification")}</TableCell>
                                <TableCell>{renderFn(labels[v.labelKey], v, "label")}</TableCell>
                                <TableCell>{renderFn(normalisedClassification[v.normalisedClassificationKey ?? v.classificationKey], v, "normalised")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {newEventModalIsOpen && (
            <EventLabelModal
              isModalOpen={newEventModalIsOpen}
              closeModal={() => {setEventModalIsOpen(false);
                 refetch();
                }}
            //   closeModal={() => {
            //     setEventModalIsOpen(false);
            //     refreshEventList(1);
            //   }}
              eventId={id}
            />
          )}
        </Box>
    );
};

export default LabelsAndClassifications;
